import { Cell, Grid, Heading, InfoLabel, Text, VFlow } from 'bold-ui'
import { Breadcrumb } from 'components/breadcrumb'
import { PageContent } from 'components/layout/PageContent'
import { PageLoading } from 'components/loading/PageLoading'
import { LotacaoResponsavelCard } from 'components/lotacao-responsavel/LotacaoResponsavelCard'
import { TitleGroup } from 'components/TitleGroup'
import { css } from 'emotion'
import { useAtividadeColetivaVisualizarReuniaoQuery } from 'graphql/hooks.generated'
import React, { Fragment } from 'react'
import { Redirect, useParams } from 'react-router'
import { turnoRecord } from 'types/enums'
import { formatDate } from 'util/date/formatDate'

import { LotacoesEnvolvidasTable } from '../componentes/LotacoesEnvolvidasTable'
import { ObservacaoCidadaoTable } from '../componentes/observacao-cidadao/ObservacaoCidadaoTable'
import { temaReuniaoRecord, tipoAtividadeRecord } from '../model-atividadeColetiva'

interface AtividadeColetivaVisualizarReuniaoViewProps {
  atividadeColetivaId: ID
}

export const AtividadeColetivaVisualizarReuniaoView = () => {
  const { atividadeColetivaId } = useParams<AtividadeColetivaVisualizarReuniaoViewProps>()

  const {
    data: { atividadeColetiva },
    loading,
  } = useAtividadeColetivaVisualizarReuniaoQuery({
    variables: { atividadeColetivaId },
  })

  const anotacoesTextOnly = atividadeColetiva?.anotacoes?.removeTags()

  if (loading) {
    return <PageLoading message='Carregando' />
  } else if (!atividadeColetiva) {
    return <Redirect to='/404' />
  }

  return (
    <Fragment>
      <Breadcrumb title='Visualização de registro de reunião de equipe' />
      <PageContent type='filled' style={styles.pageContent}>
        <TitleGroup title='Registro de reunião' style={styles.header} />

        <Grid gap={2}>
          <Cell size={12}>
            <VFlow vSpacing={0.5}>
              <Heading level={3}>Responsabilidade de registro</Heading>
              <LotacaoResponsavelCard responsavel={atividadeColetiva.lotacaoResponsavel} />
            </VFlow>
          </Cell>

          <Cell size={3} style={styles.section}>
            <InfoLabel title='Data da atividade'>{formatDate(atividadeColetiva.dataAtividade)}</InfoLabel>
          </Cell>

          <Cell size={3} style={styles.section}>
            <InfoLabel title='Turno'>{turnoRecord[atividadeColetiva.turno]}</InfoLabel>
          </Cell>

          <Cell size={3} style={styles.section}>
            <InfoLabel title='Nº de participantes'>{atividadeColetiva.numeroParticipantes}</InfoLabel>
          </Cell>

          <Cell size={12} style={styles.wordBreakSection}>
            <InfoLabel title='Local da atividade'>
              {atividadeColetiva.unidadeSaudeLocalAtividade?.nome ?? atividadeColetiva.outraLocalidade}
            </InfoLabel>
          </Cell>

          <Cell size={12} style={styles.section}>
            <Heading level={2} style={styles.title}>
              Profissionais envolvidos
            </Heading>
          </Cell>

          <Cell size={12}>
            <LotacoesEnvolvidasTable items={atividadeColetiva.lotacoesEnvolvidas ?? []} />
          </Cell>

          <Cell size={12} style={styles.section}>
            <Heading level={2} style={styles.title}>
              Dados da atividade
            </Heading>
          </Cell>

          <Cell size={4}>
            <InfoLabel title='Tipo da atividade'>{tipoAtividadeRecord[atividadeColetiva.tipoAtividade]}</InfoLabel>
          </Cell>

          <Cell size={4}>
            <InfoLabel title='Temas da reunião'>
              {atividadeColetiva.temasReuniao.map((tema) => temaReuniaoRecord[tema]).join(', ')}
            </InfoLabel>
          </Cell>

          <Cell size={12} style={styles.wordBreakSection}>
            <InfoLabel title='Anotações da atividade'>
              {anotacoesTextOnly && <Text dangerouslySetInnerHTML={{ __html: atividadeColetiva.anotacoes }} />}
            </InfoLabel>
          </Cell>

          <Cell size={12} style={styles.section}>
            <Heading level={2} style={styles.title}>
              Observações sobre cidadão
            </Heading>
          </Cell>

          <Cell size={12}>
            <ObservacaoCidadaoTable items={atividadeColetiva.observacoes ?? []} />
          </Cell>
        </Grid>
      </PageContent>
    </Fragment>
  )
}

const styles = {
  header: css`
    margin-top: 1rem;
    margin-bottom: 2rem;
  `,
  section: css`
    margin-top: 1.5rem;
  `,
  title: css`
    margin-bottom: 0.5rem;
  `,
  pageContent: css`
    padding-bottom: 3.5rem;
  `,
  wordBreakSection: css`
    word-break: break-all;
  `,
}
