import { createValidator, required } from 'util/validation'

import { ObservacaoCidadaoEditableTableModel } from '../componentes/observacao-cidadao/ObservacaoCidadaoEditableTable'
import { AtividadeColetivaFormModel } from '../model-atividadeColetiva'
import { hasDataNascimentoAfterDataAtividade } from '../utils-atividadeColetiva'
import {
  CIDADAO_HAS_DATA_NASCIMENTO_POSTERIOR_ATIVIDADE_MSG,
  getAtividadeColetivaCommonValidationRules,
} from '../validator-atividadeColetivaCommonForm'

export const atividadeColetivaReuniaoFormValidator = (now: Date) =>
  createValidator<AtividadeColetivaFormModel>({
    ...getAtividadeColetivaCommonValidationRules(now),
    temasReuniao: [required],
  })

export const validateDataNascimentoObservacoes = (model: {
  dataAtividade: LocalDate
  observacoes?: ObservacaoCidadaoEditableTableModel[]
}) =>
  model.observacoes?.some((participante) =>
    hasDataNascimentoAfterDataAtividade(model.dataAtividade, participante.cidadao.dataNascimento)
  ) && CIDADAO_HAS_DATA_NASCIMENTO_POSTERIOR_ATIVIDADE_MSG
