import { AlertType } from 'bold-ui'
import {
  BeneficioDbEnum,
  GrupoCondicaoEnum,
  IdentidadeGeneroEnum,
  JustificativaCancelarAgendamentoEnum,
  NacionalidadeEnum,
  OrientacaoSexualEnum,
  OrigemAtendimento,
  SexoEnum,
  TipoAcesso,
  TipoAleitamentoMaternoEnum,
  TipoAtendimentoProfissional,
  TipoEdemaEnum,
  TipoEncaminhamentoOdontoEnum,
  TipoGlicemia,
  TipoGravidezEnum,
  TipoPartoEnum,
  TipoReceitaEnum,
  TipoSanguineoEnum,
  TipoServicoPadraoEnum,
  TurnoEnum,
} from 'graphql/types.generated'

export const tipoAcesso: Record<TipoAcesso, string> = {
  [TipoAcesso.ADMINISTRADOR_GERAL]: 'Instalador',
  [TipoAcesso.ADMINISTRADOR_MUNICIPAL]: 'Administrador municipal',
  [TipoAcesso.GESTOR_ESTADUAL]: 'Gestor estadual',
  [TipoAcesso.GESTOR_MUNICIPAL]: 'Gestor municipal',
  [TipoAcesso.LOTACAO]: 'Lotação',
  [TipoAcesso.ESTAGIO]: 'Estágio',
}

export const identidadeGenero: Record<IdentidadeGeneroEnum, string> = {
  [IdentidadeGeneroEnum.HOMEM_TRANS]: 'Homem transgênero',
  [IdentidadeGeneroEnum.MULHER_TRANS]: 'Mulher transgênero',
  [IdentidadeGeneroEnum.TRANSGENERO]: 'Transgênero',
  [IdentidadeGeneroEnum.TRAVESTI]: 'Travesti',
  [IdentidadeGeneroEnum.HOMEM_CIS]: 'Homem cisgênero',
  [IdentidadeGeneroEnum.MULHER_CIS]: 'Mulher cisgênero',
  [IdentidadeGeneroEnum.NAO_BINARIO]: 'Não-Binário',
  [IdentidadeGeneroEnum.OUTRO_IDENTIDADE_GENERO]: 'Outro',
}

export const sexo: Record<SexoEnum, string> = {
  [SexoEnum.MASCULINO]: 'Masculino',
  [SexoEnum.FEMININO]: 'Feminino',
  [SexoEnum.INDETERMINADO]: 'Indeterminado',
  [SexoEnum.IGNORADO]: 'Ignorado',
  [SexoEnum.AMBOS]: 'Ambos',
  [SexoEnum.NAO_INFORMADO]: 'Não informado',
}

export const tipoSanguineo: Record<TipoSanguineoEnum, string> = {
  [TipoSanguineoEnum.A_POSITIVO]: 'A+',
  [TipoSanguineoEnum.A_NEGATIVO]: 'A-',
  [TipoSanguineoEnum.B_POSITIVO]: 'B+',
  [TipoSanguineoEnum.B_NEGATIVO]: 'B-',
  [TipoSanguineoEnum.AB_POSITIVO]: 'AB+',
  [TipoSanguineoEnum.AB_NEGATIVO]: 'AB-',
  [TipoSanguineoEnum.O_POSITIVO]: 'O+',
  [TipoSanguineoEnum.O_NEGATIVO]: 'O-',
}

export const orientacaoSexual: Record<OrientacaoSexualEnum, string> = {
  [OrientacaoSexualEnum.HETEROSSEXUAL]: 'Heterossexual',
  [OrientacaoSexualEnum.HOMOSSEXUAL]: 'Homossexual (gay / lésbica)',
  [OrientacaoSexualEnum.GAY]: 'Gay',
  [OrientacaoSexualEnum.LESBICA]: 'Lésbica',
  [OrientacaoSexualEnum.ASSEXUAL]: 'Assexual',
  [OrientacaoSexualEnum.PANSEXUAL]: 'Pansexual',
  [OrientacaoSexualEnum.BISSEXUAL]: 'Bissexual',
  [OrientacaoSexualEnum.OUTRO_ORIENTACAO_SEXUAL]: 'Outro',
}

export const nacionalidade: Record<NacionalidadeEnum, string> = {
  [NacionalidadeEnum.BRASILEIRA]: 'Brasileira',
  [NacionalidadeEnum.NATURALIZADA]: 'Naturalizado',
  [NacionalidadeEnum.ESTRANGEIRA]: 'Estrangeiro',
}

export const grupoCondicao: Record<GrupoCondicaoEnum, string> = {
  [GrupoCondicaoEnum.ASMA]: 'Asma',
  [GrupoCondicaoEnum.AVC]: 'AVC',
  [GrupoCondicaoEnum.DENGUE]: 'Dengue',
  [GrupoCondicaoEnum.DESNUTRICAO]: 'Desnutrição',
  [GrupoCondicaoEnum.DIABETES]: 'Diabetes',
  [GrupoCondicaoEnum.DOENCA_CARDIACA]: 'Doença cardíaca',
  [GrupoCondicaoEnum.DPOC]: 'DPOC',
  [GrupoCondicaoEnum.DST]: 'DST',
  [GrupoCondicaoEnum.HANSENIASE]: 'Hanseníase',
  [GrupoCondicaoEnum.HIPERTENSAO_ARTERIAL]: 'Hipertensão arterial',
  [GrupoCondicaoEnum.OBESIDADE]: 'Obesidade',
  [GrupoCondicaoEnum.GRAVIDEZ]: 'Gravidez',
  [GrupoCondicaoEnum.INFARTO]: 'Infarto',
  [GrupoCondicaoEnum.REABILITACAO]: 'Reabilitação',
  [GrupoCondicaoEnum.RINS]: 'Rins',
  [GrupoCondicaoEnum.SAUDE_MENTAL]: 'Saúde mental',
  [GrupoCondicaoEnum.SAUDE_SEXUAL_E_REPRODUTIVA]: 'Saúde sexual e reprodutiva',
  [GrupoCondicaoEnum.TABAGISMO]: 'Tabagismo',
  [GrupoCondicaoEnum.TUBERCULOSE]: 'Tuberculose',
  [GrupoCondicaoEnum.USUARIO_DE_ALCOOL]: 'Usuário de álcool',
  [GrupoCondicaoEnum.USUARIO_DE_OUTRAS_DROGAS]: 'Usuário de outras drogas',
}

export const idGrupoCondicao: Record<GrupoCondicaoEnum, ID> = {
  [GrupoCondicaoEnum.ASMA]: 1,
  [GrupoCondicaoEnum.AVC]: 2,
  [GrupoCondicaoEnum.DENGUE]: 3,
  [GrupoCondicaoEnum.DESNUTRICAO]: 4,
  [GrupoCondicaoEnum.DIABETES]: 5,
  [GrupoCondicaoEnum.DOENCA_CARDIACA]: 6,
  [GrupoCondicaoEnum.DPOC]: 7,
  [GrupoCondicaoEnum.DST]: 8,
  [GrupoCondicaoEnum.HANSENIASE]: 9,
  [GrupoCondicaoEnum.HIPERTENSAO_ARTERIAL]: 10,
  [GrupoCondicaoEnum.OBESIDADE]: 11,
  [GrupoCondicaoEnum.GRAVIDEZ]: 12,
  [GrupoCondicaoEnum.INFARTO]: 13,
  [GrupoCondicaoEnum.REABILITACAO]: 14,
  [GrupoCondicaoEnum.RINS]: 15,
  [GrupoCondicaoEnum.SAUDE_MENTAL]: 16,
  [GrupoCondicaoEnum.SAUDE_SEXUAL_E_REPRODUTIVA]: 17,
  [GrupoCondicaoEnum.TABAGISMO]: 18,
  [GrupoCondicaoEnum.TUBERCULOSE]: 19,
  [GrupoCondicaoEnum.USUARIO_DE_ALCOOL]: 20,
  [GrupoCondicaoEnum.USUARIO_DE_OUTRAS_DROGAS]: 21,
}

export enum ProcedimentosAutomaticosEscutaInicial {
  MEDICAO_DE_PESO = '0101040083',
  MEDICAO_DE_ALTURA = '0101040075',
  GLICEMIA_CAPILAR = '0214010015',
  AFERICAO_DE_TEMPERATURA = '0301100250',
  AVALIACAO_ANTROPOMETRICA = '0101040024',
  AFERICAO_PRESSAO_ARTERIAL = '0301100039',
}

export enum ProcedimentosAutomaticosAtendimentoIndividual {
  MEDICAO_DE_PESO = '0101040083',
  MEDICAO_DE_ALTURA = '0101040075',
  GLICEMIA_CAPILAR = '0214010015',
  AFERICAO_DE_TEMPERATURA = '0301100250',
  AVALIACAO_ANTROPOMETRICA = '0101040024',
  AFERICAO_PRESSAO_ARTERIAL = '0301100039',
  CONSULTA_MEDICA_EM_ATENCAO_BASICA = '0301010064',
  CONSULTA_DE_PROFISSIONAIS_DE_NIVEL_SUPERIOR_NA_ATENCAO_BASICA = '0301010030',
  ATENDIMENTO_DE_URGENCIA_NA_ATENCAO_BASICA = '0301060037',
  AVALIACAO_CRESCIMENTO_PUERICULTURA = '0301010269',
  AVALIACAO_DESENVOLVIMENTO_PUERICULTURA = '0301010277',
  AVALIACAO_MULTIDIMENSIONAL_PESSOA_IDOSA = '0301090033',
}

export enum ProcedimentosAutomaticosPreNatal {
  CONSULTA_PRE_NATAL = '0301010110',
}

export enum ProcedimentosAutomaticosPuerperio {
  CONSULTA_PUERPERAL = '0301010129',
}

export enum ProcedimentosAutomaticoAtendimentoOdonto {
  PRIMEIRA_CONSULTA_ODONTOLOGICA_PROGRAMATICA = '0301010153',
  ATENDIMENTO_DE_URGENCIA_EM_ATENCAO_ESPECIALIZADA = '0301060061',
  CONSULTA_DE_PROFISSIONAIS_DE_NIVEL_SUPERIOR_NA_ATENCAO_ESPECIALIZADA = '0301010048',
}

export enum ProcedimentosAutomaticoAtendimentoPoliclinica {
  CONSULTA_MEDICA_EM_ATENCAO_ESPECIALIZADA = '0301010072',
  ATENDIMENTO_DE_URGENCIA_EM_ATENCAO_ESPECIALIZADA = '0301060061',
  CONSULTA_DE_PROFISSIONAIS_DE_NIVEL_SUPERIOR_NA_ATENCAO_ESPECIALIZADA = '0301010048',
}

export enum ProcedimentosAutomaticoAtendimentoUpa {
  ATENDIMENTO_URGENCIA_COM_OBSERVACAO_AE = '0301060029',
  ATENDIMENTO_MEDICO_UPA = '0301060096',
  ATENDIMENTO_ORTOPEDICO_COM_IMOBILIZACAO_PROVISORIA = '0301060100',
  ACOLHIMENTO_COM_CLASSIFICACAO_RISCO = '0301060118',
}

export const TipoAlerta = {
  SUCCESS: 'success' as AlertType,
  WARNING: 'warning' as AlertType,
  DANGER: 'danger' as AlertType,
}

export const EstadoNutricional = {
  MAGREZA_ACENTUADA: { message: 'Magreza acentuada', alertType: TipoAlerta.DANGER },
  MAGREZA: { message: 'Magreza', alertType: TipoAlerta.WARNING },
  BAIXO_PESO: { message: 'Baixo Peso', alertType: TipoAlerta.DANGER },
  ADEQUADO: { message: 'Adequado ou Eutrófico', alertType: TipoAlerta.SUCCESS },
  EUTROFICO: { message: 'Eutrofia', alertType: TipoAlerta.SUCCESS },
  RISCO_SOBREPESO: { message: 'Risco de sobrepeso', alertType: TipoAlerta.WARNING },
  SOBREPESO: {
    DANGER: { message: 'Sobrepeso', alertType: TipoAlerta.DANGER },
    WARNING: { message: 'Sobrepeso', alertType: TipoAlerta.WARNING },
  },
  OBESIDADE: {
    DANGER: { message: 'Obesidade', alertType: TipoAlerta.DANGER },
    WARNING: { message: 'Obesidade', alertType: TipoAlerta.WARNING },
  },
  OBESIDADE_GRAVE: { message: 'Obesidade grave', alertType: TipoAlerta.DANGER },
}

export const turnoRecord: Record<TurnoEnum, string> = {
  [TurnoEnum.MANHA]: 'Manhã',
  [TurnoEnum.TARDE]: 'Tarde',
  [TurnoEnum.NOITE]: 'Noite',
}

export const turnoByStringRecord: Record<string, TurnoEnum> = {
  manhã: TurnoEnum.MANHA,
  tarde: TurnoEnum.TARDE,
  noite: TurnoEnum.NOITE,
}

export const tipoReceitaRecord: Record<TipoReceitaEnum, string> = {
  [TipoReceitaEnum.COMUM]: 'Comum',
  [TipoReceitaEnum.ESPECIAL]: 'Especial',
  [TipoReceitaEnum.ESPECIAL_AMARELA]: 'Especial - notificação amarela',
  [TipoReceitaEnum.ESPECIAL_AZUL]: 'Especial - notificação azul',
  [TipoReceitaEnum.ESPECIAL_BRANCA]: 'Especial - notificação branca',
}

export const tipoGlicemiaRecord: Record<TipoGlicemia, string> = {
  NAO_ESPECIFICADO: 'Momento da coleta não especificado',
  JEJUM: 'Jejum',
  POSPRANDIAL: 'Pós-prandial',
  PREPRANDIAL: 'Pré-prandial',
}

export enum TermosTalidomidaImpressaoPrescricao {
  ANEXO_V_A = 'Termo de responsabilidade/esclarecimento - Masc. (qualquer idade)/Fem. (maior que 55 anos)',
  ANEXO_V_B = 'Termo de responsabilidade/esclarecimento - Fem. (menor que 55 anos)',
  ANEXO_VII = 'Termo de esclarecimento',
  ANEXO_VIII = 'Termo de responsabilidade',
}

export enum TermosOutrosMedicamentosImpressaoPrescricao {
  ANEXO_XV = 'Termo de conhecimento de risco e consentimento pós-informação - Fem. (menor que 55 anos)',
  ANEXO_XVI = 'Termo de conhecimento de risco e consentimento pós-informação - Masc. (qualquer idade)/Fem. (maior que 55 anos)',
}

export const termosPrescricaoImpressao: Record<
  TermosTalidomidaImpressaoPrescricao | TermosOutrosMedicamentosImpressaoPrescricao,
  string
> = {
  [TermosTalidomidaImpressaoPrescricao.ANEXO_V_A]: 'ANEXO_V_A',
  [TermosTalidomidaImpressaoPrescricao.ANEXO_V_B]: 'ANEXO_V_B',
  [TermosTalidomidaImpressaoPrescricao.ANEXO_VII]: 'ANEXO_VII',
  [TermosTalidomidaImpressaoPrescricao.ANEXO_VIII]: 'ANEXO_VIII',
  [TermosOutrosMedicamentosImpressaoPrescricao.ANEXO_XV]: 'ANEXO_XV',
  [TermosOutrosMedicamentosImpressaoPrescricao.ANEXO_XVI]: 'ANEXO_XVI',
}

export enum TipoAtendimentoHistorico {
  CONSULTA = 'Consulta',
  PUERICULTURA = 'Puericultura',
  PUERPERIO = 'Puerpério',
  PRE_NATAL = 'Pré-natal',
  PRE_ATENDIMENTO = 'Pré-atendimento',
  ESCUTA_INICIAL = 'Escuta inicial',
  ATENDIMENTO_ODONTOLOGICO = 'Atendimento odontológico',
  ATENDIMENTO_DOMICILIAR = 'Atendimento domiciliar',
  VISITA_DOMICILIAR_TERRITORIAL = 'Visita domiciliar e territorial',
  MARCADORES_CONSUMO_ALIMENTAR = 'Marcadores de consumo alimentar',
  AVALIACAO_ELEGIBILIDADE = 'Avaliação de elegibilidade',
  ATIVIDADE_COLETIVA = 'Atividade coletiva',
  VACINACAO = 'Vacinação',
  SINDOME_NEUROLOGICA_ZIKA_MICROCEFALIA = 'Síndrome neurológica por zika / microcefalia',
  ATENDIMENTO_PROCEDIMENTOS = 'Procedimento',
  OBSERVACAO = 'Observação',
  CUIDADO_COMPARTILHADO = 'Cuidado compartilhado',
}

export enum ExibirPorHistoricoAtendimentoFilterEnum {
  TODOS_REGISTROS = 'TODOS_REGISTROS',
  ULTIMOS_TRINTA_DIAS = 'ULTIMOS_TRINTA_DIAS',
  ULTIMOS_SESSENTA_DIAS = 'ULTIMOS_SESSENTA_DIAS',
  ULTIMOS_NOVENTA_DIAS = 'ULTIMOS_NOVENTA_DIAS',
  ULTIMO_ANO = 'ULTIMO_ANO',
  PERSONALIZADO = 'PERSONALIZADO',
}

export const OrigemAtendimentoRecord: Record<OrigemAtendimento, string> = {
  [OrigemAtendimento.PEC]: 'PEC',
  [OrigemAtendimento.CDS]: 'CDS',
  [OrigemAtendimento.RAS_PEC]: 'RAS PEC',
  [OrigemAtendimento.RES]: 'RES',
  [OrigemAtendimento.AC]: 'Aplicativo e-SUS Atividade Coletiva',
  [OrigemAtendimento.AD]: 'Aplicativo e-SUS Atenção Domiciliar',
  [OrigemAtendimento.TERRITORIO]: 'Aplicativo e-SUS Território',
  [OrigemAtendimento.SISTEMA_TERCEIRO]: 'Sistema 3º',
  [OrigemAtendimento.APP_VACINACAO]: 'Aplicativo e-SUS Vacinação',
}

export const TipoServicoPadraoRecord: Record<TipoServicoPadraoEnum, string> = {
  [TipoServicoPadraoEnum.DEMANDA_ESPONTANEA]: '1',
  [TipoServicoPadraoEnum.NEBULIZACAO]: '2',
  [TipoServicoPadraoEnum.VACINA]: '3',
  [TipoServicoPadraoEnum.PROCEDIMENTOS]: '4',
  [TipoServicoPadraoEnum.EXAMES]: '5',
  [TipoServicoPadraoEnum.AGENDADO]: '6',
  [TipoServicoPadraoEnum.ESCUTA_INICIAL]: '7',
  [TipoServicoPadraoEnum.CURATIVO]: '8',
  [TipoServicoPadraoEnum.ADM_MEDICAMENTO]: '9',
  [TipoServicoPadraoEnum.ODONTOLOGIA]: '10',
}

export const tipoAtendimentoToTipoServicoMap: Record<
  Extract<
    TipoAtendimentoProfissional,
    | TipoAtendimentoProfissional.ATENDIMENTO_ODONTOLOGICO
    | TipoAtendimentoProfissional.ATENDIMENTO_PROCEDIMENTOS
    | TipoAtendimentoProfissional.VACINACAO
    | TipoAtendimentoProfissional.CONSULTA
    | TipoAtendimentoProfissional.OBSERVACAO
  >,
  string[]
> = {
  [TipoAtendimentoProfissional.ATENDIMENTO_ODONTOLOGICO]: [
    TipoServicoPadraoRecord[TipoServicoPadraoEnum.ODONTOLOGIA],
    TipoServicoPadraoRecord[TipoServicoPadraoEnum.DEMANDA_ESPONTANEA],
  ],
  [TipoAtendimentoProfissional.ATENDIMENTO_PROCEDIMENTOS]: [
    TipoServicoPadraoRecord[TipoServicoPadraoEnum.PROCEDIMENTOS],
    TipoServicoPadraoRecord[TipoServicoPadraoEnum.DEMANDA_ESPONTANEA],
  ],
  [TipoAtendimentoProfissional.VACINACAO]: [
    TipoServicoPadraoRecord[TipoServicoPadraoEnum.VACINA],
    TipoServicoPadraoRecord[TipoServicoPadraoEnum.DEMANDA_ESPONTANEA],
  ],
  [TipoAtendimentoProfissional.CONSULTA]: [TipoServicoPadraoRecord[TipoServicoPadraoEnum.DEMANDA_ESPONTANEA]],
  [TipoAtendimentoProfissional.OBSERVACAO]: [
    TipoServicoPadraoRecord[TipoServicoPadraoEnum.PROCEDIMENTOS],
    TipoServicoPadraoRecord[TipoServicoPadraoEnum.DEMANDA_ESPONTANEA],
  ],
}

export enum JustificativaCancelamentoAgendaComboEnum {
  LICENCA_MEDICA = 'LICENCA_MEDICA',
  REUNIAO_NAO_PROGRAMADA = 'REUNIAO_NAO_PROGRAMADA',
  CIDADAO_CANCELOU = 'CIDADAO_CANCELOU',
  JUSTIFICATIVA_AUTOMATICA_PROFISSIONAL = 'JUSTIFICATIVA_AUTOMATICA_PROFISSIONAL',
  OUTRA = 'OUTRA',
}

export const JustificativaCancelamentoAgendaRecord: Record<
  JustificativaCancelamentoAgendaComboEnum,
  { descricao?: string; justificativaCancelarAgendamentoEnum: JustificativaCancelarAgendamentoEnum }
> = {
  LICENCA_MEDICA: {
    descricao: 'Licença médica',
    justificativaCancelarAgendamentoEnum: JustificativaCancelarAgendamentoEnum.LICENCA_MEDICA,
  },
  REUNIAO_NAO_PROGRAMADA: {
    descricao: 'Reunião/capacitação não programada',
    justificativaCancelarAgendamentoEnum: JustificativaCancelarAgendamentoEnum.REUNIAO_NAO_PROGRAMADA,
  },
  CIDADAO_CANCELOU: {
    descricao: 'Cidadão solicitou o cancelamento da consulta agendada',
    justificativaCancelarAgendamentoEnum: JustificativaCancelarAgendamentoEnum.CIDADAO_CANCELOU,
  },
  OUTRA: { descricao: 'Outra', justificativaCancelarAgendamentoEnum: JustificativaCancelarAgendamentoEnum.OUTRA },
  JUSTIFICATIVA_AUTOMATICA_PROFISSIONAL: {
    justificativaCancelarAgendamentoEnum: JustificativaCancelarAgendamentoEnum.JUSTIFICATIVA_AUTOMATICA_PROFISSIONAL,
  },
}

export const booleanToString: Record<string, string> = {
  true: 'Sim',
  false: 'Não',
}

export const tipoAleitamentoRecord: Record<TipoAleitamentoMaternoEnum, string> = {
  EXCLUSIVO: 'Exclusivo',
  PREDOMINANTE: 'Predominante',
  COMPLEMENTADO: 'Complementado',
  INEXISTENTE: 'Inexistente',
}

export const tipoGravidezRecord: Record<TipoGravidezEnum, string> = {
  UNICA: 'Única',
  DUPLA_GEMELAR: 'Dupla / Gemelar',
  TRIPLA_OU_MAIS: 'Tripla ou mais',
  IGNORADA: 'Ignorada',
}

export const tipoPartoRecord: Record<TipoPartoEnum, string> = {
  NORMAL: 'Normal',
  FORCEPS: 'Fórceps',
  CESAREO: 'Cesáreo',
}

export const tipoEdemaRecord: Record<TipoEdemaEnum, string> = {
  NEGATIVO: '-',
  POSITIVO: '+',
  DOIS_POSITIVOS: '++',
  TRES_POSITIVOS: '+++',
}

export const condutaOdontoRecord: Partial<Record<TipoEncaminhamentoOdontoEnum, string>> = {
  TRATAMENTO_CONCLUIDO: 'Tratamento concluído',
  AGENDAMENTO_P_GRUPOS: 'Agendamento para grupos',
  AGENDAMENTO_P_NASF: 'Agendamento para NASF',
  AGENDAMENTO_P_EMULTI: 'Agendamento para eMulti',
  ALTA_DO_EPISODIO: 'Alta do episódio',
  RETORNO_PARA_CONSULTA_AGENDADA: 'Retorno para consulta agendada',
  AGENDAMENTO_P_OUTROS_PROFISSIONAIS_AB: 'Agendamento para outros profissionais ab',
}

export const beneficioRecord: Record<BeneficioDbEnum, string> = {
  [BeneficioDbEnum.BOLSA_FAMILIA]: 'Bolsa família',
  [BeneficioDbEnum.CESTA_DE_ALIMENTO]: 'Cesta de alimento',
  [BeneficioDbEnum.LEITE_NAO_HUMANO]: 'Leite não humano',
  [BeneficioDbEnum.NAO_RECEBE_BENEFICIO_SOCIAL]: 'Não recebe benefício social',
  [BeneficioDbEnum.OUTROS_BENEFICIOS]: 'Outros benefícios',
  [BeneficioDbEnum.APOSENTADO]: 'Aposentado',
  [BeneficioDbEnum.BENEFICIO_DE_PRESTACAO_CONTINUADA]: 'Benefício de prestação continuada (BPC)',
}
