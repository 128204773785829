import { createValidator, ErrorObject, required, requiredRichText, richTextMaxLength } from 'util/validation'
import { ProfissionalCpfCnsIdModel } from 'view/atividade-coletiva/model-atividadeColetiva'
import {
  getCidadaoProfExistsMsg,
  hasSameCpfCns,
  hasSameId,
} from 'view/atividade-coletiva/saude/utils-atividadeColetivaSaude'
import { hasDataNascimentoAfterDataAtividade } from 'view/atividade-coletiva/utils-atividadeColetiva'
import { CIDADAO_HAS_DATA_NASCIMENTO_POSTERIOR_ATIVIDADE_MSG } from 'view/atividade-coletiva/validator-atividadeColetivaCommonForm'

import { ObservacaoCidadaoEditableTableModel } from './ObservacaoCidadaoEditableTable'

export const observacaoCidadaoEditableTableValidator = (
  allItems: ObservacaoCidadaoEditableTableModel[],
  profissionalResponsavel: ProfissionalCpfCnsIdModel,
  profissionaisEnvolvidos: ProfissionalCpfCnsIdModel[],
  dataAtividade: LocalDate
) =>
  createValidator<ObservacaoCidadaoEditableTableModel>(
    {
      cidadao: [required],
      observacao: [requiredRichText, richTextMaxLength(4000)],
    },
    (model: ObservacaoCidadaoEditableTableModel, errors: ErrorObject<ObservacaoCidadaoEditableTableModel>) => {
      const hasSameCidadao = (observacao: ObservacaoCidadaoEditableTableModel) =>
        observacao?._id !== model?._id &&
        (hasSameId(observacao?.cidadao, model?.cidadao) || hasSameCpfCns(observacao?.cidadao, model?.cidadao))

      if (allItems?.some(hasSameCidadao)) {
        errors.cidadao = 'Cidadão selecionado já possui observações informadas.'
      } else if (profissionaisEnvolvidos?.some((profissional) => hasSameCpfCns(profissional, model?.cidadao))) {
        errors.cidadao = getCidadaoProfExistsMsg('envolvido')
      } else if (hasSameCpfCns(profissionalResponsavel, model?.cidadao)) {
        errors.cidadao = getCidadaoProfExistsMsg('responsável')
      } else if (hasDataNascimentoAfterDataAtividade(dataAtividade, model?.cidadao?.dataNascimento)) {
        errors.cidadao = CIDADAO_HAS_DATA_NASCIMENTO_POSTERIOR_ATIVIDADE_MSG
      }

      return errors
    }
  )
