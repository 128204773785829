import { Select, SelectProps } from 'bold-ui'
import { CategoriaArquivoAtendProfEnum } from 'graphql/types.generated'
import React from 'react'

import { categoriaAnexoLabelRecord, CategoriaArquivoSelectModel } from '../../model-anexoArquivos'

interface CategoriaArquivoFilterSelectProps
  extends Omit<SelectProps<CategoriaArquivoSelectModel>, 'items' | 'itemToString'> {}

const items = Object.values(CategoriaArquivoAtendProfEnum)

const itemToString = (item: CategoriaArquivoSelectModel) => item && categoriaAnexoLabelRecord[item]

export function CategoriaArquivoFilterSelect(props: CategoriaArquivoFilterSelectProps) {
  return <Select<CategoriaArquivoSelectModel> itemToString={itemToString} items={items} {...props} />
}
