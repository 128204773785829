/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Link, Text, Theme, useTheme } from 'bold-ui'
import { Ellipsis } from 'components/Ellipsis'
import { ReactComponent as IconMediaOpen } from 'images/links-uteis/icon-media-open.svg'
import { PecIcon } from 'view/atendimentos/list/PecIcon'

interface LinksUteisCardProps {
  title: string
  description: string
  link: string
}

export const LinksUteisCard = ({ title, description, link }: LinksUteisCardProps) => {
  const theme = useTheme()
  const styles = createStyles(theme)

  return (
    <div css={styles.card}>
      <Text component='p' fontWeight='bold'>
        {title}
      </Text>
      <Text component='p'>
        <Ellipsis maxLines={2}>{description}</Ellipsis>
      </Text>
      <Link target='_blank' style={styles.link} href={link}>
        <PecIcon icon={IconMediaOpen} style={styles.icon} aria-label='media-open-icon' />
        <Text fontSize={0.8125} color='primary'>
          Acessar
        </Text>
      </Link>
    </div>
  )
}

const createStyles = (theme: Theme) => ({
  card: css`
    height: 7.5rem;
    border: 0.0625rem solid ${theme.pallete.gray.c80};
    border-radius: 0.125rem;
    padding: 1rem;
    display: flex;
    flex-direction: column;
  `,
  link: css`
    width: min-content;
    display: flex;
    align-self: flex-end;
    margin-top: auto;
  `,
  icon: css`
    width: 1rem;
    height: auto;
    margin-right: 0.125rem;
  `,
})
