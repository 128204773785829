import api from 'api'
import { defaultHandleError } from 'util/downloadFile'
import { previewFile } from 'util/previewFile'

export const downloadRelatorioAd = (formData: FormData) => {
  return api.atencaoDomiciliar
    .gerarRelatorioAd(formData)
    .then((response) => {
      previewFile(new Blob([response.data], { type: 'application/pdf' }), 'Relatório de Atenção domiciliar')
      return response
    })
    .catch(defaultHandleError)
}
