import { VFlow } from 'bold-ui'
import { LoadingIndicator } from 'components/loading'
import { useCidadaoFciVisualizacaoQuery } from 'graphql/hooks.generated'
import React from 'react'

import { CidadaoFciDadosPessoaisSection } from './components/CidadaoFciDadosPessoaisSection'
import { CidadaoFciInformacoesSociodemograficasSection } from './components/CidadaoFciInformacoesSociodemograficasSection'

interface CidadaoFciInformacoesViewProps {
  cidadaoId: ID
}

export function CidadaoFciInformacoesView(props: CidadaoFciInformacoesViewProps) {
  const { cidadaoId } = props

  const {
    data: { cidadao },
    loading,
  } = useCidadaoFciVisualizacaoQuery({
    variables: {
      id: cidadaoId,
    },
    fetchPolicy: 'network-only',
  })

  if (loading) return <LoadingIndicator />

  return (
    <VFlow vSpacing={2}>
      <CidadaoFciDadosPessoaisSection cidadao={cidadao} />
      <CidadaoFciInformacoesSociodemograficasSection
        informacoesSociodemograficas={cidadao.informacoesSociodemograficas}
        escolaridade={cidadao.escolaridade}
        cbo={cidadao.cbo}
      />
    </VFlow>
  )
}
