import { SelectField, SelectFieldProps } from 'components/form/final-form'
import { CategoriaArquivoAtendProfEnum } from 'graphql/types.generated'
import React from 'react'

import { categoriaAnexoLabelRecord, CategoriaArquivoSelectModel } from '../model-anexoArquivos'

interface CategoriaArquivoSelectFieldProps
  extends Omit<SelectFieldProps<CategoriaArquivoSelectModel>, 'items' | 'itemToString'> {
  valuesToOmit: CategoriaArquivoAtendProfEnum[]
}

export function CategoriaArquivoSelectField(props: CategoriaArquivoSelectFieldProps) {
  const { valuesToOmit = [], ...rest } = props
  return (
    <SelectField<CategoriaArquivoSelectModel>
      itemToString={itemToString}
      items={items.filter((item) => !valuesToOmit.includes(item))}
      {...rest}
    />
  )
}

const items = Object.values(CategoriaArquivoAtendProfEnum)
const itemToString = (item: CategoriaArquivoSelectModel) => item && categoriaAnexoLabelRecord[item]
