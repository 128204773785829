import { TableFooter, Text, VFlow } from 'bold-ui'
import { AccordionDataTable } from 'components/accordion/accordion-data-table/AccordionDataTable'
import { Ellipsis } from 'components/Ellipsis'
import { CidadaoSelectFieldModel } from 'components/form'
import { Cns, Cpf } from 'components/label'
import { TableBox } from 'components/table'
import { usePagination } from 'components/table/usePagination'
import theme from 'config/theme'
import { css } from 'emotion'
import React from 'react'
import { ObservacaoCidadaoModel } from 'view/atividade-coletiva/model-atividadeColetiva'

import { getNomeCidadao, sortByNomeCidadao } from '../../utils-atividadeColetiva'

type ObservacaoCidadaoTableProps = {
  items: ObservacaoCidadaoModel[]
}

export function ObservacaoCidadaoTable(props: ObservacaoCidadaoTableProps) {
  const { items } = props
  const { paginatedItems, tableProps } = usePagination({ items: items.slice().sort(sortByNomeCidadao) })

  const infoCidadao = (cidadao: CidadaoSelectFieldModel) => {
    return (
      <VFlow vSpacing={0.1}>
        <Text fontWeight='bold'>{getNomeCidadao(cidadao).titleCase()}</Text>
        {(cidadao.cpf && <Cpf value={cidadao.cpf} />) || (cidadao.cns && <Cns value={cidadao.cns} />)}
      </VFlow>
    )
  }

  return (
    <TableBox>
      <AccordionDataTable<ObservacaoCidadaoModel>
        rows={paginatedItems}
        columns={[
          {
            name: 'nome',
            header: 'Nome',
            render: (row) => infoCidadao(row.cidadao),
            size: 2,
          },
          {
            name: 'observacao',
            header: 'Observações sobre cidadão',
            render: (row) => (
              <Ellipsis maxLines={2}>
                <Text dangerouslySetInnerHTML={{ __html: row.observacao }} />
              </Ellipsis>
            ),
            size: 8,
          },
        ]}
        components={{
          AccordionPanel: ({ row }) => (
            <VFlow style={styles.accordion} vSpacing={0.5}>
              <Text fontWeight='bold'>Observações sobre cidadão</Text>
              <Text style={styles.rowObservacao} dangerouslySetInnerHTML={{ __html: row.observacao }} />
            </VFlow>
          ),
        }}
      />
      <TableFooter style={styles.tableFooter} {...tableProps} />
    </TableBox>
  )
}

const styles = {
  accordion: css`
    background: ${theme.pallete.gray.c90};
    padding: 1rem;
  `,
  rowObservacao: css`
    word-break: break-word;
  `,
  tableFooter: css`
    border-top: 0;
  `,
}
