import { booleanToSimNaoEnum, simNaoEnumRecord } from 'components/form'

export const formatNomeCidadao = (cidadao: { nome: string; nomeSocial?: string }) =>
  (cidadao.nomeSocial ?? cidadao.nome).titleCase()

export const formatSimNaoJoinRespostas = (status: boolean | null, respostas: string | string[] | null) => {
  const formattedRespostas = (() => {
    if (!respostas) return null

    if (Array.isArray(respostas)) {
      if (respostas.length === 0) return null
      return (
        respostas
          .map((resposta) => `"${resposta}"`)
          .sort()
          .join(', ') + '.'
      )
    }

    return `"${respostas}".`
  })()

  const formattedStatus = simNaoEnumRecord[booleanToSimNaoEnum(status)]
  return [formattedStatus, formattedRespostas].filter(Boolean).join(': ')
}
