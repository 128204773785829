import { FieldStatusEnum } from '../../components/FieldStatus/model-fieldStatus'

export const getFieldStatus = <T>(
  initialValue: T,
  currentValue: T,
  isEmptyFn: (value: T) => boolean,
  dirty?: boolean
): FieldStatusEnum => {
  const initialHasContent = !isEmptyFn(initialValue)
  const currentHasContent = !isEmptyFn(currentValue)

  if (!initialHasContent && currentHasContent) {
    return FieldStatusEnum.ADICIONADO
  }

  if (initialHasContent && !currentHasContent) {
    return FieldStatusEnum.REMOVIDO
  }

  if (initialHasContent && currentHasContent && dirty) {
    return FieldStatusEnum.EDITADO
  }

  return FieldStatusEnum.NAO_MODIFICADO
}
