import { FieldStatusEnum } from 'components/form/final-form/components/FieldStatus/model-fieldStatus'
import { isEqual } from 'lodash'
import { useMemo } from 'react'
import { MetaArray } from 'util/metaPath'
import { isEmpty } from 'util/validation/Util'
import { RowType } from 'view/atendimentos/detail/components/EditableList'

import { usePecField } from '../useField'
import { getFieldStatus } from './getFieldStatus'

interface ListFieldStatus<T> {
  fieldStatus: Map<T, FieldStatusEnum>
  disabledValues: T[]
}

interface UseListFieldStatusParams<T> {
  name: MetaArray<T>
  showModifiedStatus?: boolean
}

export function useListFieldStatus<T extends RowType>(params: UseListFieldStatusParams<T>): ListFieldStatus<T> {
  const { name, showModifiedStatus = false } = params

  const { input, meta } = usePecField({ name })

  const initialValues = meta.initial
  const currentValues = input.value

  return useMemo(() => {
    if (!showModifiedStatus || !Array.isArray(currentValues)) return { fieldStatus: new Map(), disabledValues: [] }

    const newStatusMap = new Map<T, FieldStatusEnum>(
      initialValues?.map((initialValueItem) => {
        const currentValueItem = currentValues.find((item) => initialValueItem._id === item._id)

        const dirty = !isEqual(initialValueItem, currentValueItem)
        const status = getFieldStatus(initialValueItem, currentValueItem, isEmpty, dirty)

        return [!currentValueItem ? initialValueItem : currentValueItem, status]
      })
    )

    currentValues?.forEach((currentValueItem) => {
      const initialValueItem = initialValues?.find((initialValueItem) => currentValueItem._id === initialValueItem._id)

      if (!initialValueItem)
        newStatusMap.set(currentValueItem, getFieldStatus(initialValueItem, currentValueItem, isEmpty))
    })

    return { fieldStatus: newStatusMap, disabledValues: Array.from(newStatusMap.keys()) }
  }, [initialValues, currentValues, showModifiedStatus])
}
