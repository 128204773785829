/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Alert, Text, VFlow } from 'bold-ui'
import { gray } from 'bold-ui/lib/styles/colors'
import { useFlags } from 'config/useFlagsContext'
import { ReactComponent as WeightIcon } from 'images/medical/weight.svg'
import { ReactComponent as AtividadeColetivaIcon } from 'images/side-menu/atividade-coletiva.svg'

import { NewsGif } from './NewsGif'
import { NewsHeadingSection } from './NewsHeadingSection'
import { NewsImg } from './NewsImg'

export function NewsInternalPanelCincoQuatro() {
  const { ATIVIDADE_COLETIVA_ENABLED } = useFlags()

  if (!process.env.REACT_APP_VERSION) {
    return (
      <Alert type='danger' inline>
        Nenhuma informação sobre versões anteriores está disponível.
      </Alert>
    )
  }

  return (
    <VFlow vSpacing={1}>
      <Text fontSize={0.875} fontWeight='bold'>
        Novidades:
      </Text>

      <NewsHeadingSection
        title='Gráficos de ganho de peso gestacional'
        icon={<WeightIcon css={css(styles.iconSVG, styles.fillableIconSVG)} />}
        id='ganho-peso-gestacional-5-4'
      >
        <VFlow vSpacing={0.5}>
          <Text fontSize={0.875} component='li'>
            Agora o sistema conta com gráficos de ganho de peso específicos para acompanhar a evolução de acordo com o
            IMC de referência da pessoa gestante. Essa funcionalidade estará disponível dentro da aba "Gestação", no
            card de "Medições", para gestantes que iniciaram o pré-natal no sistema.
          </Text>
          <NewsGif
            src='/static/novidades/5-4_acesso_grafico_ganho_peso.gif'
            pausedSrc='/static/novidades/5-4_acesso_grafico_ganho_peso_paused.png'
            alt='Gif mostrando como acessar o gráfico de ganho de peso gestacional'
          />
          <Text fontSize={0.875} component='li'>
            Com essa atualização, o sistema exibirá um dos gráficos a seguir, conforme o IMC de referência da gestante:
          </Text>
          <ul>
            <VFlow vSpacing={0.5}>
              <Text fontSize={0.875} component='li'>
                <Text fontSize={0.875} fontWeight='bold'>
                  Baixo Peso:
                </Text>{' '}
                IMC menor que 18,5 kg/m²
              </Text>
              <NewsImg
                src='/static/novidades/5-4_grafico_ganho_peso_baixopeso.png'
                alt='Imagem exibindo gráfico de ganho de peso para gestantes com baixo peso'
              />
              <Text fontSize={0.875} component='li'>
                <Text fontSize={0.875} fontWeight='bold'>
                  Eutrofia:
                </Text>{' '}
                IMC maior ou igual a 18,5 kg/m² e menor que 25 kg/m²
              </Text>
              <NewsImg
                src='/static/novidades/5-4_grafico_ganho_peso_eutrofia.png'
                alt='Imagem exibindo gráfico de ganho de peso para gestantes com eutrofia'
              />
              <Text fontSize={0.875} component='li'>
                <Text fontSize={0.875} fontWeight='bold'>
                  Sobrepeso:
                </Text>{' '}
                IMC maior ou igual a 25 kg/m² e menor que 30 kg/m²
              </Text>
              <NewsImg
                src='/static/novidades/5-4_grafico_ganho_peso_sobrepeso.png'
                alt='Imagem exibindo gráfico de ganho de peso para gestantes com sobrepeso'
              />
              <Text fontSize={0.875} component='li'>
                <Text fontSize={0.875} fontWeight='bold'>
                  Obesidade:
                </Text>{' '}
                IMC maior ou igual a 30 kg/m²
              </Text>
              <NewsImg
                src='/static/novidades/5-4_grafico_ganho_peso_obesidade.png'
                alt='Imagem exibindo gráfico de ganho de peso para gestantes com obesidade'
              />
            </VFlow>
          </ul>
          <Text fontSize={0.875} component='li'>
            Para o cálculo do IMC de referência, o sistema considera a medição de peso e altura mais recente realizada
            até 2 meses antes do início da gestação. Caso não exista essa informação, considera-se a primeira medição de
            peso e altura realizada até a oitava semana após o início da gestação.
          </Text>
          <Text fontSize={0.875} component='li'>
            Essa nova funcionalidade visa oferecer um acompanhamento mais detalhado e específico, conforme as diretrizes
            de ganho de peso saudável durante a gestação.
          </Text>
        </VFlow>
      </NewsHeadingSection>

      {ATIVIDADE_COLETIVA_ENABLED && (
        <NewsHeadingSection
          title='Novo módulo de Atividade Coletiva no PEC'
          icon={<AtividadeColetivaIcon css={css(styles.iconSVG, styles.fillableIconSVG)} />}
          id='atividade-coletiva-5-4'
        >
          <VFlow vSpacing={0.5}>
            <Text fontSize={0.875}>
              Agora, o PEC passa a contar com o novo módulo de Atividade Coletiva! Com o objetivo de otimizar o
              preenchimento existente na ficha e trazer evoluções ao processo de registro de atividades coletivas nas
              unidades, o novo módulo conta com:
            </Text>
            <ul>
              <VFlow vSpacing={0.5}>
                <Text fontSize={0.875} component='li'>
                  Separação dos registros em Reuniões e Atividade em saúde;
                </Text>
                <Text fontSize={0.875} component='li'>
                  Visualização de registros de atividade coletiva de toda localidade do profissional;
                </Text>
                <Text fontSize={0.875} component='li'>
                  Gestão da listagem de registros através dos filtros de profissional responsável, registros
                  processados, período, tipo de atividade e equipe;
                </Text>
                <Text fontSize={0.875} component='li'>
                  Campo aberto para inclusão de anotações da atividade em saúde ou reunião;
                </Text>
                <Text fontSize={0.875} component='li'>
                  Preenchimento automático de dados do cidadão e do profissional;
                </Text>
                <Text fontSize={0.875} component='li'>
                  Cálculo e classificação automática de IMC;
                </Text>
                <Text fontSize={0.875} component='li'>
                  Inclusão de observações sobre cidadãos em registros de reunião.
                </Text>
              </VFlow>
            </ul>
            <Text fontSize={0.875}>Confira a nova visualização e gestão da listagem dos registros:</Text>
            <NewsGif
              src='/static/novidades/5-4_listagem_atividade_coletiva.gif'
              pausedSrc='/static/novidades/5-4_listagem_atividade_coletiva_paused.png'
              alt='Gif mostrando a visualização e gestão da listagem de registros de atividade coletiva no novo módulo do PEC'
            />
            <Text fontSize={0.875}>
              Agora, o registro das atividades está otimizado através do preenchimento automático de dados, além de
              contar com novo campo para detalhamento da atividade:
            </Text>
            <NewsGif
              src='/static/novidades/5-4_registro_atividade_coletiva.webp'
              pausedSrc='/static/novidades/5-4_registro_atividade_coletiva_paused.png'
              alt='Gif mostrando o registro de atividade coletiva no novo módulo do PEC'
            />
            <Text fontSize={0.875}>
              Além disso, os registros de reunião contam com uma nova seção para inclusão de observações sobre cidadãos
              que foram discutidas durante a reunião de equipe:
            </Text>
            <NewsGif
              src='/static/novidades/5-4_observacoes_atividade_coletiva.webp'
              pausedSrc='/static/novidades/5-4_observacoes_atividade_coletiva_paused.png'
              alt='Gif mostrando o registro de obsevações sobre cidadãos em registros de reunião de equipe'
            />
          </VFlow>
        </NewsHeadingSection>
      )}
    </VFlow>
  )
}

const styles = {
  iconSVG: css`
    margin-right: 0.25rem;
    width: 32px;
    height: 32px;
  `,
  fillableIconSVG: css`
    fill: ${gray.c60};
  `,
}
