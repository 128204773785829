import styled from '@emotion/styled'
import { Theme } from 'bold-ui'
import { UseFieldProps, usePecField } from 'components/form/final-form/hooks/useField'
import { FormApi } from 'final-form'
import React, { PropsWithChildren, useCallback } from 'react'
import { v4 as uuidv4 } from 'uuid'

export interface RowType {
  _id: ID
}

export interface EditableFormListOptions<T extends RowType> extends UseFieldProps<T> {}

export function useEditableListField<T extends RowType>(options: EditableFormListOptions<T>) {
  const fieldProps = usePecField(options)
  const { input, meta } = fieldProps

  const handleSubmit = (formValues: T, formApi: FormApi) =>
    new Promise((resolve) => {
      const value = { ...formValues, _id: uuidv4() }

      input.onChange([...input.value, value])
      input.onBlur() // usado pra marcar o field como touched no form externo
      formApi.getRegisteredFields().forEach((field) => formApi.resetFieldState(field))

      resolve(true)
    }).then(() => setTimeout(formApi.reset))

  const handleSubmitWithoutReset = (formValues: T) =>
    new Promise((resolve) => {
      const value = { ...formValues, _id: uuidv4() }

      input.onChange([...input.value, value])
      input.onBlur() // usado pra marcar o field como touched no form externo

      resolve(true)
    })

  const handleSubmitMultiple = useCallback(
    (formValues: T[], formApi: FormApi) => {
      const values = formValues.map((formValue) => ({ ...formValue, _id: uuidv4() }))
      input.onChange([...input.value, ...values])
      input.onBlur() // usado pra marcar o field como touched no form externo
      formApi.getRegisteredFields().forEach((field) => formApi.resetFieldState(field))
      setTimeout(formApi.reset)
    },
    [input]
  )

  const handleRowChanged = useCallback(
    (formValues: T) => {
      input.onChange(input.value.map((item) => (item._id === formValues._id ? formValues : item)))
      input.onBlur() // usado pra marcar o field como touched no form externo
    },
    [input]
  )

  const removeItem = useCallback(
    (itemToRemove: T) => {
      input.onChange(input.value.filter((item) => item._id !== itemToRemove._id))
      input.onBlur() // usado pra marcar o field como touched no form externo
    },
    [input]
  )

  const resetItemToInitialValue = useCallback(
    ({ _id }: T) => {
      const initialIndex = meta.initial.findIndex((item) => item._id === _id)
      const copyCurrentValues = input.value.slice()
      copyCurrentValues.splice(initialIndex, 0, meta.initial[initialIndex])
      input.onChange(copyCurrentValues)
      input.onBlur() // usado pra marcar o field como touched no form externo
    },
    [meta.initial, input]
  )

  return {
    handleSubmit,
    handleSubmitMultiple,
    handleRowChanged,
    removeItem,
    resetItemToInitialValue,
    handleSubmitWithoutReset,
    ...fieldProps,
  }
}

interface EditableRowProps {
  editing: boolean
}

export const EditableRow = (props: PropsWithChildren<EditableRowProps>) => {
  const { children, editing } = props

  return (
    <EditableRowWrapper editing={editing}>
      <div>{children}</div>
    </EditableRowWrapper>
  )
}

const EditableRowWrapper = styled.div<EditableRowProps, Theme>`
  background-color: ${(props) => props.editing && props.theme.pallete.primary.c90};
  padding: 0 1rem;
  overflow: hidden;
  transition: background-color 0.4s ease-in-out;
`

export const EditableList = styled.div<{}, Theme>`
  border: 1px solid ${(props) => props.theme.pallete.divider};
  border-radius: ${(props) => props.theme.radius.input}px;
  > div:not(:last-of-type) {
    border-bottom: 1px solid ${(props) => props.theme.pallete.divider};
  }
`
