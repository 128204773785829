import React from 'react'

import { AtividadeColetivaFilterForm } from '../componentes/AtividadeColetivaFilterForm'
import { AtividadeColetivaListing } from '../componentes/AtividadeColetivaListing'
import { AtividadeColetivaTable } from '../componentes/AtividadeColetivaTable'
import { useAtividadeColetivaFilter } from '../hooks/useAtividadeColetivaFilter'

export function AtividadeColetivaSaudeListaView() {
  const { filter, setFilter, convertedFilter, filterTags } = useAtividadeColetivaFilter(false)

  return (
    <AtividadeColetivaListing
      title='Atividade coletiva em saúde'
      registerText='Registrar atividade em saúde'
      filter={<AtividadeColetivaFilterForm filter={filter} onChangeFilter={setFilter} />}
      filterTags={filterTags}
      children={<AtividadeColetivaTable filter={convertedFilter} onChangeFilter={setFilter} />}
    />
  )
}
