import { Cell, Grid, Theme, VFlow } from 'bold-ui'
import { useScrollPosition } from 'bold-ui/lib/hooks'
import CheckPermission from 'components/auth/CheckPermission'
import { useFlags } from 'config/useFlagsContext'
import { IdadeGestacionalAcompanhamentoPreNatal } from 'graphql/types.generated'
import { useCabecalhoFixo } from 'hooks/useCabecalhoFixo'
import React, { CSSProperties, forwardRef, useMemo } from 'react'
import { isCidadaoIdoso } from 'util/isCidadaoIdoso'
import { useLocalStyles } from 'view/atendimentos/atendimento-individual/AtendimentoIndividualView'
import { CiapCidPreNatal, meta } from 'view/atendimentos/atendimento-individual/model'
import { MedicoesPanelModel } from 'view/atendimentos/components/MedicoesPanel/MedicoesPanel'
import { LotacaoAtendimento } from 'view/atendimentos/types/AtendimentoProfissionalModel'
import { CidadaoAtendimento } from 'view/atendimentos/types/CidadaoAtendimento'

import { LembreteFormModel } from '../../components/modals/lembretes/components/LembreteForm'
import { grupoCboListaProblemasCondicoes } from '../../components/modals/lista-problemas/acessos'
import { PeriodoGestacaoModel } from '../../components/modals/types/PeriodoGestacaoModel'
import { grupoCboAlergias } from '../avaliacao/acessos'
import { AlergiaReacaoModel } from '../avaliacao/components/alergias-reacoes/model'
import { ProblemaCondicaoModel } from '../avaliacao/components/problemas-condicoes/model-problemasCondicoes'
import { grupoCboMedicoes, grupoCboResultadosExames } from '../objetivo/acessos'
import { IvcfFieldModel } from '../objetivo/ivcf/model-ivcf'
import { PuericulturaModel } from '../objetivo/puericultura/model'
import { ResultadosExamesModel } from '../objetivo/resultados-exames/model-resultadosExames'
import { grupoCboLembretes } from '../plano/acessos'
import { grupoCboAcompanhamentoPreNatal } from '../pre-natal/acessos'
import { PreNatalFormModel } from '../pre-natal/model-preNatal'
import { grupoCboAcompanhamentoIdoso } from './acompanhamento-idoso/acessos'
import { AcompanhamentoIdosoSection } from './acompanhamento-idoso/AcompanhamentoIdosoSection'
import { AcompanhamentoPuericulturaSection } from './acompanhamento-puericultura/AcompanhamentoPuericulturaSection'
import { AcompanhamentoVulnerabilidadeSection } from './acompanhamento-vulnerabilidade/AcompanhamentoVulnerabilidadeSection'
import { AlergiasSection } from './alergias/AlergiasSection'
import { CondicoesAutorreferidasSection } from './condicoes-autorreferidas/CondicoesAutorreferidasSection'
import { LembretesSection } from './lembretes/LembretesSection'
import { ProblemasSection } from './lista-problemas/ProblemasSection'
import { MedicamentosSection } from './medicamentos/MedicamentosSection'
import { MedicoesSection } from './medicoes/MedicoesSection'
import { AsideViewAtendimentoStatuses } from './model-aside'
import { PreNatalSection } from './pre-natal/components/PreNatalSection'
import { ResultadosExamesSection } from './resultados-exames/ResultadosExamesSection'

interface AsideViewProps {
  lotacao?: LotacaoAtendimento
  prontuarioId: ID
  atendimentoProfissionalId?: ID
  atendimentoId?: ID
  dataAtendimento: Instant
  cidadao: CidadaoAtendimento
  isGestante: boolean
  gestacoes: PeriodoGestacaoModel[]
  ciapCidPreNatal?: CiapCidPreNatal
  somenteCiap?: boolean
  problemasAvaliacao?: ProblemaCondicaoModel[]
  problemasLPC?: ProblemaCondicaoModel[]
  alergiasAtendimentoAtual?: AlergiaReacaoModel[]
  medicoesAtendimentoAtual?: MedicoesPanelModel
  lembretesCache?: LembreteFormModel[]
  vacinacaoEmDia?: boolean
  puericultura?: PuericulturaModel
  preNatal?: PreNatalFormModel
  dum?: string
  resultadosExames?: ResultadosExamesModel
  readOnlyMedicoesSection?: boolean
  isGravidezAltoRisco?: boolean
  width: number
  headerHeight: number
  idadeGestacional?: IdadeGestacionalAcompanhamentoPreNatal
  hasPermissionPreNatal?: boolean
  atendimentoStatuses: AsideViewAtendimentoStatuses
  readOnly?: boolean
  isRegistroTardio?: boolean
  ivcfAtendimentoAtual: IvcfFieldModel
}

export const AsideView = forwardRef<HTMLDivElement, AsideViewProps>((props, ref) => {
  const {
    prontuarioId,
    atendimentoId,
    atendimentoProfissionalId,
    dataAtendimento,
    cidadao,
    isGestante,
    gestacoes,
    problemasAvaliacao,
    problemasLPC,
    alergiasAtendimentoAtual,
    medicoesAtendimentoAtual,
    lembretesCache,
    vacinacaoEmDia,
    puericultura,
    preNatal,
    dum,
    resultadosExames,
    readOnlyMedicoesSection,
    isGravidezAltoRisco,
    ciapCidPreNatal,
    somenteCiap,
    lotacao,
    width,
    headerHeight,
    idadeGestacional,
    hasPermissionPreNatal = false,
    atendimentoStatuses,
    readOnly = false,
    isRegistroTardio = false,
    ivcfAtendimentoAtual,
  } = props

  const { scrollY } = useScrollPosition()
  const isCabecalhoFixo = useCabecalhoFixo()
  const { classes } = useLocalStyles(createStyles, isCabecalhoFixo, scrollY, headerHeight, width)
  const { INFORMATIVO_TRIA_ENABLED } = useFlags()

  const accessAcompanhamentoPuericultura = cidadao.idadeEmAnos < 19

  const isAtendimentoObservacao = atendimentoStatuses.isAtendimentoObservacao
  const isRetificacao = atendimentoStatuses.isRetificacao

  const renderSections = useMemo(() => {
    return (
      <div ref={ref}>
        <VFlow>
          <Grid>
            <Cell size={12}>
              {!isRetificacao && (
                <>
                  {accessAcompanhamentoPuericultura && (
                    <AcompanhamentoPuericulturaSection
                      prontuarioId={prontuarioId}
                      vacinacaoEmDia={vacinacaoEmDia}
                      puericultura={puericultura}
                      cidadao={cidadao}
                      dataReferencia={dataAtendimento}
                      atendimentoId={atendimentoId}
                    />
                  )}
                  {isCidadaoIdoso(cidadao.idadeEmAnos) && (
                    <CheckPermission permission={grupoCboAcompanhamentoIdoso}>
                      <AcompanhamentoIdosoSection
                        prontuarioId={prontuarioId}
                        cidadaoId={cidadao.id}
                        ivcfAtendimentoAtual={ivcfAtendimentoAtual}
                      />
                    </CheckPermission>
                  )}
                  {isGestante && (
                    <CheckPermission permission={grupoCboAcompanhamentoPreNatal}>
                      <PreNatalSection
                        prontuarioId={prontuarioId}
                        cidadaoId={cidadao.id}
                        isAltoRiscoAtendimentoAtual={isGravidezAltoRisco}
                        preNatalAtendimentoAtual={preNatal}
                        dumAtendimentoAtual={dum}
                        resultadosExamesAtendimentoAtual={resultadosExames}
                        dataAtendimento={dataAtendimento}
                        atendimentoId={atendimentoId}
                        readOnly={readOnly || !hasPermissionPreNatal}
                        idadeGestacional={idadeGestacional}
                      />
                    </CheckPermission>
                  )}
                  <CheckPermission permission={grupoCboAlergias.visualizar}>
                    <AlergiasSection
                      prontuarioId={prontuarioId}
                      alergiasAtendimentoAtual={alergiasAtendimentoAtual ?? []}
                      fieldName={meta.avaliacao.alergias}
                    />
                  </CheckPermission>
                  <CheckPermission permission={grupoCboListaProblemasCondicoes.visualizar}>
                    <ProblemasSection
                      cidadao={cidadao}
                      ciapCidPreNatal={ciapCidPreNatal}
                      problemasAvaliacao={problemasAvaliacao}
                      problemasLPC={problemasLPC}
                      dataReferencia={dataAtendimento}
                      prontuarioId={prontuarioId}
                      somenteCiap={somenteCiap}
                      isAtendimentoObservacao={isAtendimentoObservacao}
                      readOnly={readOnly || atendimentoStatuses.isAtendimentoProcedimentos}
                    />
                  </CheckPermission>
                </>
              )}
              {INFORMATIVO_TRIA_ENABLED && (
                <AcompanhamentoVulnerabilidadeSection cidadaoId={cidadao.id} dataReferencia={dataAtendimento} />
              )}
              <CheckPermission permission={grupoCboMedicoes.visualizar}>
                <MedicoesSection
                  prontuarioId={prontuarioId}
                  fieldName={meta.medicoesAnteriores}
                  medicoesAtendimentoAtual={medicoesAtendimentoAtual}
                  cidadao={{
                    id: cidadao.id,
                    dataNascimento: cidadao.dataNascimento,
                    sexo: cidadao.sexo,
                    identidadeGeneroDbEnum: cidadao?.identidadeGeneroDbEnum,
                  }}
                  isGestante={isGestante}
                  gestacoes={gestacoes}
                  preNatalAtendimentoAtual={preNatal}
                  dumAtendimentoAtual={dum}
                  resultadosExamesAtendimentoAtual={resultadosExames}
                  dataAtendimento={dataAtendimento}
                  readOnly={readOnly || readOnlyMedicoesSection}
                  isAtendimentoObservacao={isAtendimentoObservacao}
                  tipoPreNatal={atendimentoStatuses.tipoPreNatal}
                  isRetificacao={isRetificacao}
                />
              </CheckPermission>
              {!isRetificacao && (
                <>
                  <MedicamentosSection
                    editable={!readOnly && !atendimentoStatuses.isObservacaoAndAuxiliar}
                    prontuarioId={prontuarioId}
                    dataReferencia={dataAtendimento}
                    isCidadaoIdoso={isCidadaoIdoso(cidadao.idadeEmAnos)}
                    atendimentoProfissionalId={atendimentoProfissionalId}
                    atendimentoId={atendimentoId}
                    isAtendimentoObservacao={isAtendimentoObservacao}
                    isRegistroTardio={isRegistroTardio}
                  />
                  <CondicoesAutorreferidasSection cidadaoId={cidadao.id} />
                  <CheckPermission permission={grupoCboLembretes.visualizar}>
                    <LembretesSection
                      lotacao={lotacao}
                      prontuarioId={prontuarioId}
                      lembretesCache={lembretesCache}
                      meta={meta.lembretes}
                      isEditable={!readOnly}
                      isAtendimentoObservacao={isAtendimentoObservacao}
                    />
                  </CheckPermission>
                  <CheckPermission permission={grupoCboResultadosExames.visualizar}>
                    <ResultadosExamesSection
                      prontuarioId={prontuarioId}
                      atendimentoProfissionalId={atendimentoProfissionalId}
                      isAtendimentoObservacao={isAtendimentoObservacao}
                    />
                  </CheckPermission>
                </>
              )}
            </Cell>
          </Grid>
        </VFlow>
      </div>
    )
  }, [
    ref,
    isRetificacao,
    accessAcompanhamentoPuericultura,
    prontuarioId,
    vacinacaoEmDia,
    puericultura,
    cidadao,
    dataAtendimento,
    atendimentoId,
    ivcfAtendimentoAtual,
    isGestante,
    isGravidezAltoRisco,
    preNatal,
    dum,
    resultadosExames,
    readOnly,
    hasPermissionPreNatal,
    idadeGestacional,
    alergiasAtendimentoAtual,
    ciapCidPreNatal,
    problemasAvaliacao,
    problemasLPC,
    somenteCiap,
    isAtendimentoObservacao,
    atendimentoStatuses.isAtendimentoProcedimentos,
    atendimentoStatuses.tipoPreNatal,
    atendimentoStatuses.isObservacaoAndAuxiliar,
    INFORMATIVO_TRIA_ENABLED,
    medicoesAtendimentoAtual,
    gestacoes,
    readOnlyMedicoesSection,
    atendimentoProfissionalId,
    lotacao,
    lembretesCache,
    isRegistroTardio,
  ])

  return <aside className={classes.aside}>{renderSections}</aside>
})

const createStyles = (
  theme: Theme,
  isCabecalhoFixo: boolean,
  scrollY: number,
  headerHeight: number,
  width: number
) => ({
  aside: {
    width: width ?? '100%',
    paddingRight: '1rem',
    position: isCabecalhoFixo ? 'fixed' : 'static',
    top: isCabecalhoFixo && headerHeight,
    marginTop: isCabecalhoFixo && '1rem',
    height: `calc(100% - ${isCabecalhoFixo ? calcAsideHeightDiff(scrollY) + 'rem' : '0px'})`,
    overflow: isCabecalhoFixo ? 'hidden auto' : 'hidden',
    '::-webkit-scrollbar': {
      width: '0.3rem',
      height: '0.3rem',
    },
    '::-webkit-scrollbar-thumb': {
      background: theme.pallete.gray.c40,
      borderRadius: theme.radius.tag,
    },
    '::-webkit-scrollbar-thumb:hover': {
      background: theme.pallete.gray.c60,
    },
    '::-webkit-scrollbar-track': {
      background: theme.pallete.surface.main,
      borderRadius: theme.radius.tag,
      boxShadow: `inset 0.4rem 0.625rem 0.75rem ${theme.pallete.divider}`,
    },
  } as CSSProperties,
})

function calcAsideHeightDiff(scrollY: number): number {
  const distBottom = window.document.body.offsetHeight - (scrollY + window.innerHeight)
  return Math.max(0, 6.1 - distBottom / 16) + 9
}
