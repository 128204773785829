/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Theme, useTheme } from 'bold-ui'
import { TipoMedicaoEnum } from 'graphql/types.generated'
import { dateAsDdMmYyyy } from 'util/date/formatDate'
import { IconTextCard } from 'view/atendimentos/detail/soap/aside/components/IconTextCard'
import { tipoMedicaoRecord } from 'view/atendimentos/detail/soap/aside/types/MedicaoModel'

import { MedicaoAntropometricaPreNatal } from '../../../pre-natal/model-medicoesPreNatal'

interface MedicoesReferenciaGanhoPesoProps {
  medicaoReferencia: MedicaoAntropometricaPreNatal
  imcReferencia: number
}
export function MedicoesReferenciaGanhoPeso(props: MedicoesReferenciaGanhoPesoProps) {
  const { medicaoReferencia, imcReferencia } = props
  const theme = useTheme()
  const styles = createStyles(theme)

  const dataReferencia = dateAsDdMmYyyy(new Date(medicaoReferencia.dataMedicao))
  const pesoConstants = tipoMedicaoRecord[TipoMedicaoEnum.PESO]
  const alturaConstants = tipoMedicaoRecord[TipoMedicaoEnum.ALTURA]
  const imcConstants = tipoMedicaoRecord[TipoMedicaoEnum.IMC]

  return (
    <div css={styles.grid}>
      <div css={styles.referenceCell}>
        <IconTextCard
          icon={pesoConstants.icon}
          iconColor={pesoConstants.colorScale}
          label={`${pesoConstants.label} de referência`}
          value={`${medicaoReferencia.peso} kg - ${dataReferencia}`}
          labelValueDirection='row'
          alignItems='center'
        />
      </div>
      <div css={styles.referenceCell}>
        <IconTextCard
          icon={alturaConstants.icon}
          iconColor={alturaConstants.colorScale}
          label={`${alturaConstants.label} de referência`}
          value={`${medicaoReferencia.altura} cm - ${dataReferencia}`}
          labelValueDirection='row'
          alignItems='center'
        />
      </div>
      <div css={styles.referenceCell}>
        <IconTextCard
          icon={imcConstants.icon}
          iconColor={imcConstants.colorScale}
          label={`${imcConstants.label} de referência`}
          value={`${imcReferencia} kg/m² - ${dataReferencia}`}
          labelValueDirection='row'
          alignItems='center'
        />
      </div>
    </div>
  )
}

const createStyles = (theme: Theme) => ({
  referenceCell: css`
    background-color: ${theme.pallete.gray.c90};
    padding: 4px 8px;
  `,

  grid: css`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 16px;
    margin: 0.7rem 0px;
  `,
})
