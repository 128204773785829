/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { HFlow, Tag, Text, Theme, useTheme } from 'bold-ui'
import { isUndefinedOrNull } from 'util/checks'

import { CategoriaImcReferenciaEnum, categoriaImcReferenciaRecord } from './model-graficoGanhoPeso'

interface TagIMCReferenciaProps {
  categoriaImcReferencia: CategoriaImcReferenciaEnum
}

export function TagIMCReferencia(props: TagIMCReferenciaProps) {
  const { categoriaImcReferencia } = props
  const theme = useTheme()

  const tagColor = categoriaImcReferenciaRecord[categoriaImcReferencia].colors.strokeAndTick
  const imcMin = categoriaImcReferenciaRecord[categoriaImcReferencia].imcMin
  const imcMax = categoriaImcReferenciaRecord[categoriaImcReferencia].imcMax

  const styles = createStyles(theme, tagColor)

  return (
    <HFlow alignItems='center' style={styles.container}>
      <Text fontWeight='bold'>Ganho de peso (kg)</Text>
      <Tag style={styles.tag}>
        {categoriaImcReferencia} ({formatImcRange(imcMin, imcMax)})
      </Tag>
    </HFlow>
  )
}

function formatImcRange(imcMin: number, imcMax: number): string {
  if (!isUndefinedOrNull(imcMin) && !isUndefinedOrNull(imcMax)) {
    return `IMC ≥ ${imcMin}kg/m² e < ${imcMax}kg/m²`
  } else if (!!imcMin) {
    return `IMC ≥ ${imcMin} kg/m²`
  } else if (!!imcMax) {
    return `IMC < ${imcMax} kg/m²`
  }
}

const createStyles = (theme: Theme, tagColor: string) => ({
  tag: css`
    color: ${theme.pallete.surface.main};
    background-color: ${tagColor};
  `,
  container: css`
    margin-top: 1rem;
    margin-left: 3rem;
  `,
})
