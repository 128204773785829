import { PecSwitch } from 'components/route'
import React from 'react'
import { match, Redirect, Route } from 'react-router'

import { HistoricoPreNatalModel } from '../../types/model-historicoPreNatal'
import { GraficoAlturaUterinaView } from '../graficos/pre-natal/altura-uterina/GraficoAlturaUterinaView'
import { GraficoGanhoPesoView } from '../graficos/pre-natal/ganho-peso/GraficoGanhoPesoView'
import { MedicaoAntropometricaPreNatal } from './model-medicoesPreNatal'

export interface HistoricoMedicoesPreNatalTabRoutesProps {
  match: match
  dataConvertedPreNatal: HistoricoPreNatalModel
  medicaoForImcReferencia: MedicaoAntropometricaPreNatal
  medicoesPeso10SemanasAposInicioGestacao: MedicaoAntropometricaPreNatal[]
}

export const HistoricoMedicoesPreNatalTabRoutes = (props: HistoricoMedicoesPreNatalTabRoutesProps) => {
  const { match, dataConvertedPreNatal, medicaoForImcReferencia, medicoesPeso10SemanasAposInicioGestacao } = props

  return (
    <PecSwitch>
      <Redirect exact path={match.url} to={`${match.url}/ganho-de-peso`} />
      <Route
        path={`${match.url}/ganho-de-peso`}
        render={() => (
          <GraficoGanhoPesoView
            medicaoForImcReferencia={medicaoForImcReferencia}
            medicoesPeso10SemanasAposInicioGestacao={medicoesPeso10SemanasAposInicioGestacao}
            dataInicioGestacao={dataConvertedPreNatal.dataInicioGestacao}
          />
        )}
      />
      <Route
        path={`${match.url}/altura-uterina`}
        render={() => <GraficoAlturaUterinaView dataConvertedPreNatal={dataConvertedPreNatal} />}
      />
    </PecSwitch>
  )
}
