import { CategoriaArquivoAtendProfEnum } from 'graphql/types.generated'
import { SoapEditableItem } from 'view/atendimentos/atendimento-individual/model'

export const categoriaAnexoLabelRecord: Record<CategoriaArquivoAtendProfEnum, string> = {
  IMAGEM_DE_LESAO: 'Imagem de lesão',
  EXAME_DE_IMAGEM: 'Exame de imagem',
  EXAME_DE_IMAGEM_ODONTOLOGICO: 'Exame de imagem odontológico',
  PRONTUARIO_EXTERNO: 'Prontuário externo',
  PRESCRICAO_DE_MEDICAMENTOS: 'Prescrição de medicamentos',
  GENOGRAMA_ECOMAPA: 'Genograma/Ecomapa',
  RELATORIO: 'Relatório',
  ENCAMINHAMENTO_EXTERNO: 'Encaminhamento externo',
}

export type CategoriaArquivoSelectModel = CategoriaArquivoAtendProfEnum

export interface AnexoArquivoRowModel extends SoapEditableItem {
  id?: ID
  cacheId?: ID
  categoria?: CategoriaArquivoAtendProfEnum
  titulo: string
  observacao?: string
  atendimentoProfissional: {
    id: ID
    iniciadoEm: Instant
    lotacao: {
      id: ID
      cbo: {
        id: ID
        nome: string
      }
      profissional: {
        id: ID
        nome: string
        cpf?: string
        cns?: string
      }
    }
  }
}

export interface AnexoArquivoFormModel extends SoapEditableItem {
  arquivoId: ID
  titulo: string
  categoria: CategoriaArquivoAtendProfEnum
  observacoes: string
}

export interface AnexoArquivoFilterModel {
  categoria: CategoriaArquivoAtendProfEnum
  somenteMeus: boolean
}
