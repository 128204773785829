import { Maybe, PeriodoErrosRndsDto, Scalars, TipoRegistroRndsEnum } from 'graphql/types.generated'

export enum StatusEnvioRegistroRndsEnum {
  ENVIADOS_SUCESSO = 'ENVIADOS_SUCESSO',
  TENTATIVAS_ERROS = 'TENTATIVAS_ERROS',
}

export interface RndsRelatorioImpressaoModel {
  periodo: PeriodoErrosRndsDto
  statusEnvioRegistro: StatusEnvioRegistroRndsEnum
  tipoRegistro: TipoRegistroRndsEnum[]
  //TODO CODEBENDERS: Na issue #23034, remover os campos abaixo.
  riacERiar: boolean
  rac: boolean
  rpm: boolean
}

export interface RelatorioRndsInput
  extends Omit<RndsRelatorioImpressaoModel, 'statusEnvioRegistro' | 'tipoRegistro' | 'periodo'> {
  startDate: Maybe<Scalars['Instant']>
  endDate: Maybe<Scalars['Instant']>
}
