import { Text } from 'bold-ui'
import { SelectField } from 'components/form'
import {
  AsyncSelectFieldProps,
  DEFAULT_SELECT_PAGE_PARAM,
  useAsyncQuerySelect,
} from 'components/form/field/select/useAsyncQuerySelect'
import { FichaNotificacaoCasoSuspeitoSelectFieldDocument } from 'graphql/hooks.generated'
import {
  FichaNotificacaoCasoSuspeitoSelectFieldQuery,
  FichaNotificacaoCasoSuspeitoSelectFieldQueryVariables,
} from 'graphql/types.generated'
import React from 'react'
import { CidadaoNotificacaoCasoSuspeito } from 'view/atendimentos/detail/soap/finalizacao/components/NotificacaoCasoSuspeitoField'

export type FichaNotificacaoSelectModel = FichaNotificacaoCasoSuspeitoSelectFieldQuery['fichasNotificacao']['content'][0]

export interface FichaNotificacaoSelectFieldProps extends AsyncSelectFieldProps<FichaNotificacaoSelectModel> {
  dataAtendimento: Instant
  cidadaoData: CidadaoNotificacaoCasoSuspeito
}

export function FichaNotificacaoCasoSuspeitoSelectField(props: FichaNotificacaoSelectFieldProps) {
  const { dataAtendimento, cidadaoData, ...rest } = props

  const { selectProps } = useAsyncQuerySelect<
    FichaNotificacaoSelectModel,
    FichaNotificacaoCasoSuspeitoSelectFieldQuery,
    FichaNotificacaoCasoSuspeitoSelectFieldQueryVariables
  >({
    query: FichaNotificacaoCasoSuspeitoSelectFieldDocument,
    extractItems: (data) => data?.fichasNotificacao?.content,
    variables: (inputString) => ({
      input: {
        cidadaoId: cidadaoData.id,
        dataAtendimento: dataAtendimento,
        isIdentidadeGeneroInformada: cidadaoData.isIdentidadeGeneroInformada,
        cidadaoSexo: cidadaoData.sexo,
        cidadaoDataNascimento: cidadaoData.dataNascimento,
        query: inputString,
        pageParams: DEFAULT_SELECT_PAGE_PARAM,
      },
    }),
  })

  const itemToString = (item: FichaNotificacaoSelectModel) => item && item.nomeNotificacao

  const renderItem = (item: FichaNotificacaoSelectModel) => {
    return <Text>{item.nomeNotificacao}</Text>
  }

  return (
    <SelectField<FichaNotificacaoSelectModel>
      itemToString={itemToString}
      renderItem={renderItem}
      {...selectProps}
      {...rest}
    />
  )
}
