import api from 'api'
import { ImpressaoAtendimentoInput } from 'api/ImpressaoAtendimentoIndividual'
import { defaultHandleError } from 'util/downloadFile'
import { previewFile } from 'util/previewFile'

export const downloadEscutaInicial = (value: ImpressaoAtendimentoInput) => {
  return api.escutaInicial
    .imprimirEscutaInicial(value)
    .then((response) => {
      previewFile(new Blob([response.data], { type: 'application/pdf' }), 'Imprimir escuta inicial')
      return response
    })
    .catch(defaultHandleError)
}
