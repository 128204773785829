import React from 'react'
import { useFormState } from 'react-final-form'
import { Prompt } from 'react-router'

interface FormPromptProps {
  condition?: boolean
  ignorePromptWhenSubmitting?: boolean
}

export function FormPrompt(props: FormPromptProps) {
  const { condition, ignorePromptWhenSubmitting } = props
  const { pristine, submitSucceeded, submitting, dirtySinceLastSubmit } = useFormState({
    subscription: {
      pristine: true,
      submitSucceeded: true,
      dirtySinceLastSubmit: true,
      submitting: true,
    },
  })

  if (ignorePromptWhenSubmitting && submitting) return null

  return <Prompt when={(!pristine && (!submitSucceeded || dirtySinceLastSubmit)) || condition} message='' />
}

FormPrompt.defaultProps = {
  condition: false,
} as FormPromptProps
