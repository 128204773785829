import api from 'api'
import { ImpressaoAcompanhamentoPuericulturaInput } from 'api/impressaoAcompanhamentoPuericultura'
import { defaultHandleError } from 'util/downloadFile'
import { previewFile } from 'util/previewFile'

export const downloadAcompanhamentoPuericultura = async (input: ImpressaoAcompanhamentoPuericulturaInput) => {
  return await api.acompanhamentoPuericultura
    .imprimirAcompanhamentoPuericultura(input)
    .then((response) => {
      previewFile(
        new Blob([response.data], { type: 'application/pdf' }),
        `AcompanhamentoPuericultura_${new Date().getTime()}.pdf`
      )
      return response
    })
    .catch(defaultHandleError)
}
