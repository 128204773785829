import React from 'react'

import { AtividadeColetivaFilterForm } from '../componentes/AtividadeColetivaFilterForm'
import { AtividadeColetivaListing } from '../componentes/AtividadeColetivaListing'
import { AtividadeColetivaTable } from '../componentes/AtividadeColetivaTable'
import { useAtividadeColetivaFilter } from '../hooks/useAtividadeColetivaFilter'

export function AtividadeColetivaReuniaoListaView() {
  const { filter, setFilter, convertedFilter, filterTags } = useAtividadeColetivaFilter(true)

  return (
    <AtividadeColetivaListing
      title='Atividade em reunião'
      registerText='Registrar atividade em reunião'
      filter={<AtividadeColetivaFilterForm filter={filter} onChangeFilter={setFilter} />}
      filterTags={filterTags}
      children={<AtividadeColetivaTable filter={convertedFilter} onChangeFilter={setFilter} />}
    />
  )
}
