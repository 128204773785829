import { Text, Tooltip, VFlow } from 'bold-ui'
import { Cns, Cpf } from 'components/label'
import { css } from 'emotion'
import React from 'react'
import { formatDate } from 'util/date/formatDate'
import { humanizeAge } from 'util/date/humanize-age'
import { ParticipanteModel } from 'view/atividade-coletiva/model-atividadeColetiva'
import { getNomeCidadao, hasDataNascimentoAfterDataAtividade } from 'view/atividade-coletiva/utils-atividadeColetiva'

interface ParticipanteCidadaoInfoProps {
  participante: ParticipanteModel
  dataAtividade: LocalDate
}

export const ParticipanteCidadaoInfo = (props: ParticipanteCidadaoInfoProps) => {
  const { dataAtividade, participante } = props
  const cidadao = participante.cidadao ?? participante.cidadaoParticipante
  const hintIdadeCidadao = 'Idade no dia desta atividade'

  return (
    <VFlow vSpacing={0.1} style={styles.container}>
      <Text fontWeight='bold'>{getNomeCidadao(cidadao)?.titleCase()}</Text>
      {(cidadao?.cpf && <Cpf value={cidadao.cpf} />) || (cidadao?.cns && <Cns value={cidadao.cns} />)}
      <Text>
        <Tooltip text={dataAtividade && hintIdadeCidadao} placement='bottom-start'>
          {hasDataNascimentoAfterDataAtividade(dataAtividade, cidadao.dataNascimento) ? (
            <Text color='danger'>{formatDate(cidadao.dataNascimento)}</Text>
          ) : (
            <Text>{humanizeAge(cidadao.dataNascimento, dataAtividade)}</Text>
          )}
        </Tooltip>
        {` | ${cidadao.sexo.capitalize()}`}
      </Text>
    </VFlow>
  )
}

const styles = {
  container: css`
    margin: 1rem 0;
  `,
}
