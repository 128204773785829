import { Button, HFlow, Icon, Text, Theme, Tooltip } from 'bold-ui'
import theme from 'config/theme'
import React from 'react'

import { FieldStatusEnum, fieldStatusRecord } from './model-fieldStatus'

interface FieldStatusProps {
  status: FieldStatusEnum
  handleReset: () => void
}

export const FieldStatus = (props: FieldStatusProps) => {
  const { status, handleReset } = props
  const styles = createStyles(theme)
  return (
    <HFlow alignItems='center' hSpacing={0.5} justifyContent='flex-end'>
      <HFlow style={styles.adicionadoAgora} hSpacing={0.5}>
        <Icon icon={fieldStatusRecord[status].icon} size={1} />
        <Text data-testid='FieldStatus.Text' style={styles.adicionadoAgora}>
          {fieldStatusRecord[status].value}
        </Text>
      </HFlow>
      <Tooltip text='Reverter mudanças'>
        <Button skin='ghost' size='small' kind='normal' onClick={handleReset}>
          <Icon icon='undo' />
        </Button>
      </Tooltip>
    </HFlow>
  )
}

const createStyles = (theme: Theme) => ({
  adicionadoAgora: {
    color: theme.pallete.primary.main,
    display: 'flex',
    alignItems: 'center',
  },
})
