import { css } from '@emotion/core'
import { InfoLabel, Theme, useTheme, VFlow } from 'bold-ui'
import { AccordionDataTableRowDefaultProps } from 'components/accordion/accordion-data-table/AccordionDataTableRowDefault'
import { HLabel } from 'components/HLabel'
import { Cns, Cpf } from 'components/label'
import React from 'react'

import { AnexoArquivoRowModel } from '../../model-anexoArquivos'

export function AnexoArquivoAccordionPanel(props: AccordionDataTableRowDefaultProps<AnexoArquivoRowModel>) {
  const { row } = props
  const { nome, cpf, cns } = row.atendimentoProfissional.lotacao.profissional
  const cbo = row.atendimentoProfissional.lotacao.cbo.nome

  const theme = useTheme()
  const styles = createStyles(theme)

  return (
    <VFlow style={styles.container} vSpacing={1}>
      <VFlow vSpacing={0.5}>
        <InfoLabel title='Observações' placeholder='-'>
          {row.observacao}
        </InfoLabel>
      </VFlow>
      <HLabel title='Anexado por:'>
        {nome.titleCase()} - {cpf ? <Cpf value={cpf} /> : <Cns value={cns} />} | {cbo.toUpperCase()}
      </HLabel>
    </VFlow>
  )
}

const createStyles = (theme: Theme) => ({
  container: css`
    background: ${theme.pallete.gray.c90};
    padding: 1rem;
  `,
})
