import { Breadcrumb } from 'components/breadcrumb'
import { ATIVIDADE_COLETIVA_ATIVIDADE_SAUDE_PATH } from 'components/layout/SideMenu/SideMenuItems'
import { PecSwitch, PrivateRoute } from 'components/route'
import React, { Fragment } from 'react'
import Permissions from 'types/Permissions'

import { AtividadeColetivaEditarSaudeView } from './AtividadeColetivaEditarSaudeView'
import { AtividadeColetivaRegistrarSaudeView } from './AtividadeColetivaRegistrarSaudeView'
import { AtividadeColetivaSaudeListaView } from './AtividadeColetivaSaudeListaView'
import { AtividadeColetivaVisualizarSaudeView } from './AtividadeColetivaVisualizarSaudeView'

export function AtividadeColetivaSaudeRootView() {
  return (
    <Fragment>
      <Breadcrumb title='Atividade coletiva em saúde' />

      <PecSwitch>
        <PrivateRoute
          path={`${ATIVIDADE_COLETIVA_ATIVIDADE_SAUDE_PATH}/registrar`}
          component={AtividadeColetivaRegistrarSaudeView}
          permission={Permissions.cds.fichaDeAtividadeColetiva.cadastrarEditarEExcluir}
        />
        <PrivateRoute
          path={`${ATIVIDADE_COLETIVA_ATIVIDADE_SAUDE_PATH}/editar/:atividadeColetivaId`}
          component={AtividadeColetivaEditarSaudeView}
          permission={Permissions.cds.fichaDeAtividadeColetiva.cadastrarEditarEExcluir}
        />
        <PrivateRoute
          path={`${ATIVIDADE_COLETIVA_ATIVIDADE_SAUDE_PATH}/visualizar/:atividadeColetivaId`}
          component={AtividadeColetivaVisualizarSaudeView}
          permission={Permissions.cds.fichaDeAtividadeColetiva}
        />
        <PrivateRoute
          exact
          path={ATIVIDADE_COLETIVA_ATIVIDADE_SAUDE_PATH}
          component={AtividadeColetivaSaudeListaView}
          permission={Permissions.cds.fichaDeAtividadeColetiva}
        />
      </PecSwitch>
    </Fragment>
  )
}
