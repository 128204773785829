import { catmatTalidomida } from 'api/ImpressaoMedicamentos'
import { Checkbox, HFlow, Tag, Text, Tooltip, VFlow } from 'bold-ui'
import { useFlags } from 'config/useFlagsContext'
import { css } from 'emotion'
import { TipoReceitaEnum } from 'graphql/types.generated'
import React from 'react'
import { tipoReceitaRecord } from 'types/enums'

import { MedicamentoFormModel } from '../../model-prescricao'
import { resolveMedicamento } from '../../utils/converter-prescricao'
import { hasIsotretinoinaTretinoinaAcitretina, isOnPrescricaoDigitalAtiva } from '../../utils/utils-prescricao'
import { PrescricaoDigitalFormModel } from '../prescricao-digital/model-prescricaoDigital'
import { MedicamentoImpressoInfoLabel } from './MedicamentoImpressoInfoLabel'
import { MedicamentoOnPrescricaoDigitalInfoLabel } from './MedicamentoOnPrescricaoDigitalInfoLabel'
import { PrescricaoBoxItem } from './PrescricaoBoxItem'

interface MedicamentoItemProps {
  medicamento: MedicamentoFormModel
  prescricaoDigital: PrescricaoDigitalFormModel
  index: number
  isPrescricaoDigital: boolean
  disableReceitaControlada: boolean
  isPrinted(medicamentoId: ID): boolean
  onChange(checked: boolean, medicamento: MedicamentoFormModel): void
  checked(medicamentoId: ID): boolean
  tipoReceitaSelecionado: TipoReceitaEnum
}

export const MedicamentoItem = (props: MedicamentoItemProps) => {
  const {
    medicamento,
    prescricaoDigital,
    index,
    isPrescricaoDigital,
    disableReceitaControlada,
    isPrinted,
    onChange,
    checked,
    tipoReceitaSelecionado,
  } = props

  const { principioAtivo, concentracao, tipoReceita } = resolveMedicamento(medicamento)

  const { PRESCRICAO_CONTROLADA } = useFlags()

  const isMedicamentoAlreadyOnPrescricaoDigital =
    isPrescricaoDigital && isOnPrescricaoDigitalAtiva(prescricaoDigital, medicamento)

  const isDisabledMedicamentoReceitaControlada =
    !PRESCRICAO_CONTROLADA && disableReceitaControlada && tipoReceita !== TipoReceitaEnum.COMUM

  const isDisabledByTipoReceitaSelecionado = tipoReceitaSelecionado && tipoReceita !== tipoReceitaSelecionado

  const isMedicamentoDisabled =
    isDisabledMedicamentoReceitaControlada ||
    isMedicamentoAlreadyOnPrescricaoDigital ||
    isDisabledByTipoReceitaSelecionado

  const isTalidomida = medicamento.principioAtivoCombo?.codigoCatMat === catmatTalidomida

  const hasTags = medicamento.usoContinuo || tipoReceita !== TipoReceitaEnum.COMUM

  const tooltipText = isDisabledMedicamentoReceitaControlada
    ? 'Não é possível incluir medicamentos de uso controlado na prescrição digital'
    : isMedicamentoAlreadyOnPrescricaoDigital
    ? 'Medicamento já incluído em uma prescrição digital'
    : isDisabledByTipoReceitaSelecionado
    ? 'Não é possível selecionar medicamentos com tipos de receita diferentes'
    : null

  return (
    <PrescricaoBoxItem key={medicamento.id} data-cy={`Medicamento${index}`}>
      <HFlow justifyContent='space-between'>
        <HFlow>
          <Tooltip text={tooltipText}>
            <span
              css={css`
                margin: auto;
              `}
            >
              <Checkbox
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange(e.target.checked, medicamento)}
                onKeyDown={(e) => e.stopPropagation()}
                checked={!isMedicamentoDisabled && checked(medicamento.id)}
                disabled={isMedicamentoDisabled}
                data-cy={`Medicamento${index}Checkbox`}
              />
            </span>
          </Tooltip>

          <VFlow vSpacing={0.25}>
            <Text fontWeight='bold' data-cy={`PrincipioAtivo${index}`}>{`${principioAtivo} ${concentracao}`}</Text>
            {hasTags && (
              <HFlow>
                {medicamento.usoContinuo && (
                  <Tag
                    type='normal'
                    style={css`
                      align-self: center;
                      max-height: 1.5rem;
                    `}
                  >
                    Uso contínuo
                  </Tag>
                )}
                {tipoReceita !== TipoReceitaEnum.COMUM && (
                  <Tag
                    type='info'
                    style={css`
                      align-self: center;
                      max-height: 1.5rem;
                    `}
                  >
                    {tipoReceitaRecord[tipoReceita]}
                  </Tag>
                )}
              </HFlow>
            )}
            {(isTalidomida || hasIsotretinoinaTretinoinaAcitretina([medicamento])) && (
              <Tag
                type='normal'
                style={css`
                  align-self: center;
                  max-height: 1.5rem;
                `}
              >
                Medicamento com termo de uso e consentimento
              </Tag>
            )}
            <Text data-cy={`Posologia${index}`}>{medicamento.posologia}</Text>
          </VFlow>
        </HFlow>
        <VFlow
          vSpacing={0.25}
          style={css`
            align-items: end;
            justify-content: center;
          `}
        >
          {isOnPrescricaoDigitalAtiva(prescricaoDigital, medicamento) && <MedicamentoOnPrescricaoDigitalInfoLabel />}
          {isPrinted(medicamento.id) && <MedicamentoImpressoInfoLabel />}
        </VFlow>
      </HFlow>
    </PrescricaoBoxItem>
  )
}
