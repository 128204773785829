import { isPromise } from 'bold-ui'
import { FormApi, FormState } from 'final-form'
import createFocusOnErrorDecorator from 'final-form-focus'
import React, { useCallback, useRef } from 'react'
import {
  AnyObject,
  Form as FinalForm,
  FormProps as FinalFormProps,
  FormRenderProps as FinalFormRenderProps,
} from 'react-final-form'
import { isObjectDeepEmpty, isObjectDeepEqualById } from 'util/object'
import { isEmpty } from 'util/validation/Util'

export type FormRenderProps<FormValues = AnyObject> = FinalFormRenderProps<FormValues>

export type ResultType = object | Promise<object | undefined> | undefined | void

export interface FormProps<FormValues> extends FinalFormProps<FormValues> {
  focusOnError?: boolean
  dirtyInitialValues?: FormValues
  transformResult?(
    result: ResultType,
    supressNotificationError?: boolean,
    suppressValidationError?: boolean,
    supressFieldValidationNotificationError?: boolean
  ): ResultType
  onSubmitSucceeded?(formState: FormState<FormValues>): void
  onSubmitFailed?(formState: FormState<FormValues>, supressNotificationError?: boolean): void
  resetFormToInitialValues?: boolean
  suppressNotificationError?: boolean
  suppressValidationError?: boolean
  supressFieldValidationNotificationError?: boolean
}

const focusOnErrorDecorator = createFocusOnErrorDecorator()

export function Form<FormValues extends object = any>(props: FormProps<FormValues>) {
  const {
    focusOnError,
    initialValues,
    dirtyInitialValues,
    transformResult,
    onSubmitFailed,
    onSubmitSucceeded,
    onSubmit,
    render,
    resetFormToInitialValues = false,
    suppressNotificationError = false,
    suppressValidationError = false,
    supressFieldValidationNotificationError = true,
    ...rest
  } = props

  const resetForm = useRef(false)
  resetForm.current = resetFormToInitialValues

  const shouldApplyDirtyInitialValues = useRef(!!dirtyInitialValues)

  const renderForm = useCallback(
    (formRenderProps: FormRenderProps<FormValues>) => {
      const { form, handleSubmit } = formRenderProps

      if (shouldApplyDirtyInitialValues.current) {
        form.batch(() => {
          Object.keys(dirtyInitialValues).forEach((field) => {
            if (!areFormValuesEqual(initialValues?.[field], dirtyInitialValues[field])) {
              form.change(field, dirtyInitialValues[field])
            }
          })
        })
        shouldApplyDirtyInitialValues.current = false
      }

      if (resetForm.current) {
        setTimeout(() => form.getRegisteredFields().forEach((field) => form.resetFieldState(field)))
        setTimeout(form.reset)
        resetForm.current = false
      }

      const handleSubmitWrapper = (event) => {
        if (onSubmitFailed && !isObjectDeepEmpty(form.getState().errors)) {
          setTimeout(() => onSubmitFailed(form.getState(), suppressNotificationError))
        }
        return handleSubmit(event)
      }

      return (
        <>
          {render({
            ...formRenderProps,
            handleSubmit: handleSubmitWrapper,
          })}
        </>
      )
    },
    [dirtyInitialValues, initialValues, onSubmitFailed, render, suppressNotificationError]
  )

  const formOnSubmit = useCallback(
    (values: FormValues, form: FormApi<FormValues>) => {
      const emitSubmitEvents = (submitResult: ResultType, form: FormApi<FormValues>) => {
        if (!submitResult && onSubmitSucceeded) {
          setTimeout(() => onSubmitSucceeded(form.getState()))
        }

        if (submitResult && onSubmitFailed) {
          setTimeout(() => onSubmitFailed(form.getState(), suppressNotificationError))
        }
      }

      const result = onSubmit(values, form)
      let ret = transformResult(
        result,
        suppressNotificationError,
        suppressValidationError,
        supressFieldValidationNotificationError
      )

      if (isPromise(ret)) {
        ret = ret.then((res) => {
          emitSubmitEvents(res, form)
          return res
        })
      } else {
        emitSubmitEvents(ret, form)
      }

      return ret
    },
    [
      onSubmit,
      onSubmitFailed,
      onSubmitSucceeded,
      suppressNotificationError,
      suppressValidationError,
      transformResult,
      supressFieldValidationNotificationError,
    ]
  )

  const decorators = props.decorators ?? []
  if (focusOnError) {
    decorators.push(focusOnErrorDecorator)
  }

  return (
    <FinalForm<FormValues>
      {...rest}
      onSubmit={formOnSubmit}
      render={renderForm}
      decorators={decorators}
      initialValues={initialValues}
    />
  )
}

Form.defaultProps = {
  focusOnError: true,
  decorators: [],
  transformResult: (result) => result,
} as Partial<FormProps<any>>

const normalizeEmptyValue = (value: any) => (!isEmpty(value) ? value : undefined)
const areFormValuesEqual = (oldValue: any, newValue: any) => {
  const a = normalizeEmptyValue(oldValue)
  const b = normalizeEmptyValue(newValue)

  return typeof b === 'object' ? isObjectDeepEqualById(a, b) : '' + a === '' + b
}
