import api from 'api'
import { defaultHandleError } from 'util/downloadFile'
import { previewFile } from 'util/previewFile'

import { ImpressaoEncaminhamentoExternoInput } from './ImpressaoEncaminhamentoExternoInput'

export const downloadEncaminhamentoExterno = async (value: ImpressaoEncaminhamentoExternoInput) => {
  return await api.encaminhamentoExterno
    .imprimirEncaminhamentoExterno(value)
    .then((response) => {
      previewFile(new Blob([response.data], { type: 'application/pdf' }), 'Imprimir encaminhamento externo')
      return response
    })
    .catch(defaultHandleError)
}
