import { FieldStatusEnum } from 'components/form/final-form/components/FieldStatus/model-fieldStatus'
import { useEffect, useState } from 'react'
import { isEmpty } from 'util/validation/Util'

import { getFieldStatus } from './getFieldStatus'

interface UseFieldStatusParams {
  initialValue: string
  currentValue: string
  dirty: boolean
  showModifiedStatus?: boolean
  isValueEmpty?(value: string): boolean
}

export function useFieldStatus(params: UseFieldStatusParams): { status: FieldStatusEnum; showChangeStatus: boolean } {
  const { initialValue, currentValue, dirty, showModifiedStatus = false, isValueEmpty = isEmpty } = params

  const [status, setStatus] = useState<FieldStatusEnum>(FieldStatusEnum.NAO_MODIFICADO)

  useEffect(() => {
    if (!showModifiedStatus) return

    setStatus(getFieldStatus(initialValue, currentValue, isValueEmpty, dirty))
  }, [initialValue, dirty, currentValue, showModifiedStatus, isValueEmpty])

  return {
    status,
    showChangeStatus: status !== FieldStatusEnum.NAO_MODIFICADO,
  }
}
