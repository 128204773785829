/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { VFlow } from 'bold-ui'
import { resolveName, usePecField } from 'components/form/final-form/hooks/useField'
import { TipoReceitaEnum } from 'graphql/types.generated'
import { useCallback } from 'react'
import { metaPath } from 'util/metaPath'

import { MedicamentoFormModel } from '../../model-prescricao'
import { PrescricaoMedicamentoPlanoModel } from '../../PrescricaoMedicamentoView'
import { PrescricaoDigitalFormModel } from '../prescricao-digital/model-prescricaoDigital'
import { MedicamentoItem } from './MedicamentoItem'

const path = metaPath<PrescricaoMedicamentoPlanoModel>()

interface EncaminharPrescricaoMedicamentoItemProps {
  prescricaoDigital: PrescricaoDigitalFormModel
  medicamentosToPrint: MedicamentoFormModel[]
  medicamentoIdsSelecionado: ID[]
  onChange(checked: boolean, medicamento: MedicamentoFormModel): void
  disableReceitaControlada?: boolean
  isPrescricaoDigital?: boolean
  tipoReceitaSelecionado?: TipoReceitaEnum
}

export function EncaminharPrescricaoMedicamentoItem(props: EncaminharPrescricaoMedicamentoItemProps) {
  const {
    prescricaoDigital,
    medicamentosToPrint,
    medicamentoIdsSelecionado,
    disableReceitaControlada = false,
    onChange,
    isPrescricaoDigital,
    tipoReceitaSelecionado,
  } = props

  const {
    input: { value: alreadyPrintedValue },
  } = usePecField({ name: resolveName(path.alreadyPrinted) })

  const isPrinted = useCallback((medicamentoId: ID) => !!alreadyPrintedValue?.includes(medicamentoId), [
    alreadyPrintedValue,
  ])

  const isSelecionado = useCallback((medicamentoId: ID) => medicamentoIdsSelecionado.includes(medicamentoId), [
    medicamentoIdsSelecionado,
  ])

  return (
    <VFlow
      vSpacing={0.5}
      style={css`
        margin-top: 0.5rem;
        margin-bottom: 1rem;
      `}
    >
      {medicamentosToPrint.map((medicamento, index) => (
        <MedicamentoItem
          index={index}
          medicamento={medicamento}
          prescricaoDigital={prescricaoDigital}
          isPrescricaoDigital={isPrescricaoDigital}
          disableReceitaControlada={disableReceitaControlada}
          isPrinted={isPrinted}
          onChange={onChange}
          checked={isSelecionado}
          tipoReceitaSelecionado={tipoReceitaSelecionado}
          key={medicamento.id}
        />
      ))}
    </VFlow>
  )
}
