/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Button, Cell, DropdownItem, Grid, HFlow, Icon, Tooltip } from 'bold-ui'
import { FieldStatusEnum } from 'components/form/final-form/components/FieldStatus/model-fieldStatus'
import { ListFieldStatus } from 'components/form/final-form/components/ListFieldStatus/ListFieldStatus'
import { confirm } from 'components/modals/confirm'
import { TextStatus } from 'components/text/TextStatus'
import { Fragment } from 'react'
import { getEditableItemRemoveTooltipText } from 'view/atendimentos/atendimento-individual/atendimento-observacao/util-atendObservacao'
import { EditableRow } from 'view/atendimentos/detail/components/EditableList'

import { NotificacaoCasoSuspeitoFieldModel } from './NotificacaoCasoSuspeitoField'

interface NotificacaoRowProps {
  model: NotificacaoCasoSuspeitoFieldModel
  onRemove(itemToRemove: NotificacaoCasoSuspeitoFieldModel): void
  onPrint(itemToPrint: NotificacaoCasoSuspeitoFieldModel): void
  onReset?: (itemToReset: NotificacaoCasoSuspeitoFieldModel) => void
  status?: FieldStatusEnum
  showModifiedStatus?: boolean
}

export function NotificacaoRow(props: NotificacaoRowProps) {
  const { model, onRemove, onPrint, onReset, status, showModifiedStatus } = props

  const { isEditable, tooltipText } = getEditableItemRemoveTooltipText(model)

  const handleRemove = async () => {
    const confirmed =
      !showModifiedStatus ||
      (await confirm({
        title: `Deseja excluir o campo Ficha de notificação de caso suspeito?`,
        type: 'danger',
        confirmLabel: 'Excluir',
      })())
    if (confirmed) onRemove(model)
  }
  const handleImprimir = () => onPrint(model)
  const handleReset = () => onReset(model)

  return (
    <EditableRow editing={false}>
      <Grid style={styles.grid}>
        <Cell size={9} alignSelf='center'>
          <TextStatus status={status}>{`${model.fichaNotificacao.nomeNotificacao}`}</TextStatus>
        </Cell>
        <Cell size={3} alignSelf='center' style={styles.cell}>
          {showModifiedStatus ? (
            <ListFieldStatus status={status} onReset={handleReset} onRemove={handleRemove}>
              <DropdownItem onClick={handleImprimir}>
                <HFlow alignItems='center' hSpacing={0.5}>
                  <Icon icon='printerOutline' />
                  Imprimir
                </HFlow>
              </DropdownItem>
            </ListFieldStatus>
          ) : (
            <Fragment>
              <Tooltip text='Imprimir'>
                <Button size='small' skin='ghost' onClick={handleImprimir}>
                  <Icon icon='printerOutline' />
                </Button>
              </Tooltip>
              <Tooltip text={tooltipText}>
                <Button size='small' skin='ghost' onClick={handleRemove} disabled={isEditable}>
                  <Icon icon='trashOutline' />
                </Button>
              </Tooltip>
            </Fragment>
          )}
        </Cell>
      </Grid>
    </EditableRow>
  )
}

const styles = {
  grid: css`
    margin: 0;
  `,
  cell: css`
    padding: 0.25rem 0;
    text-align: right;
  `,
}
