import { SimNaoEnum } from 'components/form'
import { tipoEdemaRecord } from 'types/enums'
import { dateAsYyyyMmDd } from 'util/date/formatDate'
import { MedicoesPreNatal } from 'view/atendimentos/detail/components/modals/types/model-historicoPreNatal'

import { ResultadoExameEspecificoModel } from '../../objetivo/resultados-exames/model-resultadosExames'
import { PreNatalFormModel } from '../../pre-natal/model-preNatal'
import { ResultadoExamePreNatal } from './model-acompanhamentoPreNatal'

export function convertResultadoExameEspecificoModelToResultadoExamePreNatal(
  resultadoExameEspecifico: ResultadoExameEspecificoModel
): ResultadoExamePreNatal {
  if (!resultadoExameEspecifico) {
    return null
  }
  const { dataRealizado, resultado } = resultadoExameEspecifico

  return {
    dataRealizacao: dataRealizado,
    especifico: {
      dpp: resultado.dpp,
      igDias: resultado.idadeGestacional?.dias && Number(resultado.idadeGestacional.dias),
      igSemanas: resultado.idadeGestacional?.semanas && Number(resultado.idadeGestacional.semanas),
    },
  }
}

export function convertMedicoesAtendimentoAtual(
  dataAtendimento: number,
  preNatalAtendimentoAtual: PreNatalFormModel,
  pesoAtendimentoAtual: number,
  quantidadeMedicoesAtendimentosAnteriores: number
): MedicoesPreNatal {
  return {
    numeroConsulta: quantidadeMedicoesAtendimentosAnteriores + 1,
    dataDaMedicao: dateAsYyyyMmDd(dataAtendimento),
    movimentacaoFetal:
      preNatalAtendimentoAtual?.movimentacaoFetal && preNatalAtendimentoAtual.movimentacaoFetal === SimNaoEnum.SIM,
    edema: tipoEdemaRecord[preNatalAtendimentoAtual?.edema],
    batimentoCardiacoFetal: preNatalAtendimentoAtual?.batimentoCardiacoFetal?.toString(),
    alturaUterina: preNatalAtendimentoAtual?.alturaUterina?.toString(),
    peso: pesoAtendimentoAtual,
    isRegistradoAgora: true,
  }
}
