import { Button, Cell, Grid, Heading, HFlow, Modal, ModalBody, ModalFooter } from 'bold-ui'
import { CidadaoSelectField, RichTextField } from 'components/form'
import React, { MouseEventHandler } from 'react'
import { MetaPath } from 'util/metaPath'

import { ObservacaoCidadaoEditableTableModel } from './ObservacaoCidadaoEditableTable'

interface ObservacaoCidadaoEditableTableModalFormProps {
  name: MetaPath<ObservacaoCidadaoEditableTableModel>
  title: string
  isOpen: boolean
  onClose: () => void
  onSubmit: MouseEventHandler<HTMLButtonElement>
}

export const ObservacaoCidadaoEditableTableModalForm = (props: ObservacaoCidadaoEditableTableModalFormProps) => {
  const { name, title, isOpen, onSubmit, onClose } = props

  return (
    <Modal open={isOpen} onClose={onClose} size='large'>
      <ModalBody>
        <Grid gap={2}>
          <Cell size={12}>
            <Heading level={1}>{title}</Heading>
          </Cell>
          <Cell size={6}>
            <CidadaoSelectField
              name={name.cidadao}
              placeholder='Pesquise por nome, CPF, CNS ou data de nascimento'
              label='Cidadão'
              ativo
              required
            />
          </Cell>
          <Cell size={12}>
            <RichTextField
              name={name.observacao}
              label='Observações sobre cidadão'
              placeholder='Insira observações sobre cidadão'
              removeNonBreakingSpaces
              maxLength={4000}
              height={6}
              required
            />
          </Cell>
        </Grid>
      </ModalBody>
      <ModalFooter>
        <HFlow justifyContent='flex-end'>
          <Button onClick={onClose} title='Cancelar' size='medium'>
            Cancelar
          </Button>
          <Button kind='primary' onClick={onSubmit} size='medium'>
            Salvar
          </Button>
        </HFlow>
      </ModalFooter>
    </Modal>
  )
}
