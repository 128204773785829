import { Button, Cell, DataTable, FormControl, Grid, Icon, TableFooter, Tooltip, VFlow } from 'bold-ui'
import { useAcessoLotacaoOrEstagio } from 'components/auth/useAcessoLotacao'
import {
  LotacaoAndEstagioSelectField,
  LotacaoAndEstagioSelectFieldModel,
  LotacaoAndEstagioSelectFieldProps,
} from 'components/form/field/select/LotacaoAndEstagioSelectField/LotacaoAndEstagioSelectField'
import { TableBox } from 'components/table/TableBox'
import { usePagination } from 'components/table/usePagination'
import { css } from 'emotion'
import React from 'react'
import { MetaArray } from 'util/metaPath'
import { required } from 'util/validation/rules/required'
import { createValidator, ErrorObject } from 'util/validation/validator'
import { useEditableListField } from 'view/atendimentos/detail/components/EditableList'
import { EditableListForm, EditableListFormRenderProps } from 'view/atendimentos/detail/components/EditableListForm'
import { formatNomeProfissional } from 'view/profissional/utils/utils-profissional'

export interface LotacaoEditableTableModel {
  _id: ID
  lotacao: LotacaoAndEstagioSelectFieldModel
}

type LotacaoEditableTableProps = Pick<LotacaoAndEstagioSelectFieldProps, 'required' | 'includeEstagios'> & {
  name: MetaArray<LotacaoEditableTableModel>
}

const lotacaoEditableTableValidator = (allItems: LotacaoEditableTableModel[]) =>
  createValidator<LotacaoEditableTableModel>(
    {
      lotacao: [required],
    },
    (model: LotacaoEditableTableModel, errors: ErrorObject<LotacaoEditableTableModel>) => {
      if (model?.lotacao) {
        const isDuplicate = allItems.some((item) => item.lotacao.profissional.id === model.lotacao.profissional.id)

        errors.lotacao = isDuplicate && 'Profissional já adicionado'
      }

      return errors
    }
  )

export function LotacaoEditableTable(props: LotacaoEditableTableProps) {
  const { name, ...rest } = props
  const { acesso } = useAcessoLotacaoOrEstagio()
  const {
    handleSubmit,
    removeItem,
    input: { value },
  } = useEditableListField({ name })

  const allItems: LotacaoEditableTableModel[] = (value || []).slice().reverse()
  const { paginatedItems, tableProps } = usePagination({ items: allItems })

  const renderForm = (renderProps: EditableListFormRenderProps<LotacaoEditableTableModel>) => {
    const { name, handleSubmit } = renderProps

    return (
      <Grid gap={2} style={styles.tableHeader}>
        <Cell size={6}>
          <VFlow>
            <LotacaoAndEstagioSelectField
              name={name.lotacao}
              label='Profissional'
              placeholder='Pesquise por nome, CPF ou CNS'
              municipioId={acesso.municipio.id}
              showNomeSocialLabel={true}
              // TODO Foundation #21877 - Atividade Coletiva será reestruturada posteriormente para inclusão de estagiários
              canWriteAtendimento={false}
              {...rest}
            />
          </VFlow>
        </Cell>

        <Cell size={2}>
          <FormControl label={<span>&nbsp;</span>}>
            <Button kind='primary' onClick={handleSubmit} size='small'>
              Adicionar
            </Button>
          </FormControl>
        </Cell>
      </Grid>
    )
  }

  const renderButtons = (row: LotacaoEditableTableModel) => (
    <Tooltip text='Excluir'>
      <Button type='button' kind='normal' skin='ghost' size='small' onClick={() => removeItem(row)}>
        <Icon icon='trashOutline' />
      </Button>
    </Tooltip>
  )

  return (
    <TableBox
      header={
        <EditableListForm<LotacaoEditableTableModel>
          render={renderForm}
          onSubmit={handleSubmit}
          validate={lotacaoEditableTableValidator(allItems)}
          prefix='new'
        />
      }
    >
      <DataTable<LotacaoEditableTableModel>
        rows={paginatedItems}
        columns={[
          {
            name: 'nome',
            header: 'Nome',
            render: (row) => formatNomeProfissional(row.lotacao.profissional),
            style: styles.tableRowNome,
          },
          {
            name: 'cbo',
            header: 'CBO',
            render: (row) => row.lotacao.cbo.nome.capitalize(),
            style: styles.tableRowCBO,
          },
          {
            name: 'ine',
            header: 'INE',
            render: (row) => row.lotacao.equipe?.nome ?? '-',
          },
          {
            name: 'buttons',
            render: renderButtons,
            style: styles.tableRowButtons,
          },
        ]}
      />
      <TableFooter style={styles.tableFooter} {...tableProps} />
    </TableBox>
  )
}

const styles = {
  tableHeader: css`
    padding-block: 0.5rem;
  `,
  tableFooter: css`
    border-top: 0;
  `,
  tableRowNome: css`
    width: 22rem;
  `,
  tableRowCBO: css`
    width: 20rem;
  `,
  tableRowButtons: css`
    width: 3.5rem;
    text-align: right;
  `,
}
