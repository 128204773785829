import { CidadaoSelectFieldModel } from 'components/form'
import { TipoAtividadeEnum } from 'graphql/types.generated'
import {
  beforeEqualTo,
  cns,
  cpf,
  createValidator,
  empty,
  ErrorObject,
  maxLength,
  nome,
  range,
  required,
} from 'util/validation'
import { CidadaoParticipanteModel, ProfissionalCpfCnsIdModel } from 'view/atividade-coletiva/model-atividadeColetiva'
import { hasDataNascimentoAfterDataAtividade } from 'view/atividade-coletiva/utils-atividadeColetiva'
import { CIDADAO_HAS_DATA_NASCIMENTO_POSTERIOR_ATIVIDADE_MSG } from 'view/atividade-coletiva/validator-atividadeColetivaCommonForm'

import {
  getCidadaoProfCpfCnsExistsMsg,
  getCidadaoProfExistsMsg,
  hasSameCns,
  hasSameCpf,
  hasSameCpfCns,
  hasSameId,
  isCidadaosParticipantesRequired,
} from '../../saude/utils-atividadeColetivaSaude'
import { ParticipanteEditableTableModel } from './ParticipanteEditableTable'

const cidadaoParticipanteValidator = (
  dataAtividade: LocalDate,
  allItems: ParticipanteEditableTableModel[],
  profissionalResponsavel: ProfissionalCpfCnsIdModel,
  profissionaisEnvolvidos: ProfissionalCpfCnsIdModel[]
) =>
  createValidator<CidadaoParticipanteModel>(
    {
      nome: [nome(), maxLength(70)],
      dataNascimento: required,
      sexo: required,
      cns: cns,
      cpf: cpf,
    },
    (model: CidadaoParticipanteModel, errors: ErrorObject<CidadaoParticipanteModel>) => {
      errors.dataNascimento =
        errors.dataNascimento || beforeEqualTo(model?.dataNascimento, dataAtividade, 'data da atividade')

      if (
        allItems.some((participante) => hasSameCpf(participante.cidadao ?? participante.cidadaoParticipante, model))
      ) {
        errors.cpf = 'CPF informado já foi cadastrado.'
      } else if (hasSameCpf(profissionalResponsavel, model)) {
        errors.cpf = getCidadaoProfCpfCnsExistsMsg('CPF', 'responsável')
      } else if (profissionaisEnvolvidos?.some((profissional) => hasSameCpf(profissional, model))) {
        errors.cpf = getCidadaoProfCpfCnsExistsMsg('CPF', 'envolvido')
      }

      if (
        allItems.some((participante) => hasSameCns(participante.cidadao ?? participante.cidadaoParticipante, model))
      ) {
        errors.cns = 'CNS informado já foi cadastrado.'
      } else if (hasSameCns(profissionalResponsavel, model)) {
        errors.cns = getCidadaoProfCpfCnsExistsMsg('CNS', 'responsável')
      } else if (profissionaisEnvolvidos?.some((profissional) => hasSameCns(profissional, model))) {
        errors.cns = getCidadaoProfCpfCnsExistsMsg('CNS', 'envolvido')
      }

      return errors
    }
  )

export const validateCidadao = (
  dataAtividade: LocalDate,
  model: CidadaoSelectFieldModel,
  allItems: ParticipanteEditableTableModel[],
  profissionalResponsavel: ProfissionalCpfCnsIdModel,
  profissionaisEnvolvidos: ProfissionalCpfCnsIdModel[]
) => {
  if (
    allItems?.some(
      (participante) =>
        hasSameId(participante?.cidadao, model) ||
        hasSameCpfCns(participante?.cidadao ?? participante?.cidadaoParticipante, model)
    )
  ) {
    return 'Cidadão informado já foi cadastrado.'
  } else if (profissionaisEnvolvidos?.some((profissional) => hasSameCpfCns(profissional, model))) {
    return getCidadaoProfExistsMsg('envolvido')
  } else if (hasSameCpfCns(profissionalResponsavel, model)) {
    return getCidadaoProfExistsMsg('responsável')
  } else if (hasDataNascimentoAfterDataAtividade(dataAtividade, model?.dataNascimento)) {
    return CIDADAO_HAS_DATA_NASCIMENTO_POSTERIOR_ATIVIDADE_MSG
  }

  return undefined
}

export const validateParticipanteCpfCnsByTipoAtividade = (tipoAtividade: TipoAtividadeEnum) => (model: {
  cpf?: string
  cns?: string
}) =>
  isCidadaosParticipantesRequired(tipoAtividade) &&
  !model?.cns &&
  !model?.cpf &&
  'CPF/CNS do cidadão é obrigatório para o tipo de atividade informado.'

export const participanteEditableTableValidator = (
  tipoAtividade: TipoAtividadeEnum,
  dataAtividade: LocalDate,
  allItems: ParticipanteEditableTableModel[],
  profissionalResponsavel: ProfissionalCpfCnsIdModel,
  profissionaisEnvolvidos: ProfissionalCpfCnsIdModel[]
) => {
  const validateCidadaoParticipante = cidadaoParticipanteValidator(
    dataAtividade,
    allItems,
    profissionalResponsavel,
    profissionaisEnvolvidos
  )
  const validateCpfCnsTipoAtividade = validateParticipanteCpfCnsByTipoAtividade(tipoAtividade)

  return createValidator<ParticipanteEditableTableModel>(
    {
      altura: range(20, 250),
      peso: range(0.5, 500),
    },
    (model: ParticipanteEditableTableModel, errors: ErrorObject<ParticipanteEditableTableModel>) => {
      if (model?.isCidadaoParticipanteFormOpen) {
        errors.cidadaoParticipante =
          validateCpfCnsTipoAtividade(model?.cidadaoParticipante) ||
          validateCidadaoParticipante(model?.cidadaoParticipante)
        errors.cidadao = empty(model?.cidadao)
      } else {
        errors.cidadaoParticipante = empty(model?.cidadaoParticipante)
        errors.cidadao =
          required(model?.cidadao) ||
          validateCpfCnsTipoAtividade(model?.cidadao) ||
          validateCidadao(dataAtividade, model?.cidadao, allItems, profissionalResponsavel, profissionaisEnvolvidos)
      }

      return errors
    }
  )
}
