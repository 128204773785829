export const previewFile = (file: Blob, title: string) => {
  const fileWindow: Window = window.open(
    '',
    '',
    `width=${window.screen.width * 0.8}, height=${window.screen.height * 0.8}`
  )
  const url = URL.createObjectURL(file)
  fileWindow.document.write(`<iframe width='100%' height='100%' frameborder='0' src='${url}'></iframe>`)
  fileWindow.document.title = title
}
