import { TipoPreNatal } from '../pre-natal/model-preNatal'
import { AsideViewAtendimentoStatuses } from './model-aside'

export function convertToStatuses(
  isAtendimentoProcedimentos: boolean,
  isAtendimentoObservacao: boolean,
  isObservacaoAndAuxiliar: boolean,
  tipoPreNatal: TipoPreNatal,
  isRetificacao: boolean
): AsideViewAtendimentoStatuses {
  return {
    isAtendimentoProcedimentos: isAtendimentoProcedimentos ?? false,
    isAtendimentoObservacao: isAtendimentoObservacao ?? false,
    isObservacaoAndAuxiliar: isObservacaoAndAuxiliar ?? false,
    tipoPreNatal,
    isRetificacao: isRetificacao ?? false,
  }
}
