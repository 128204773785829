import { Heading } from 'bold-ui'
import React, { Fragment, ReactNode } from 'react'

import { NewsVersion } from './hooks/useNewsItems'
import { NewsInternalEstabilizacaoV50 as NewsInternalEstabilizacaoVCincoZero } from './NewsInternalEstabilizacaoV_50'
import { NewsInternalEstabilizacaoV51 as NewsInternalEstabilizacaoVCincoUm } from './NewsInternalEstabilizacaoV_51'
import { NewsInternalEstabilizacaoV52 as NewsInternalEstabilizacaoVCincoDois } from './NewsInternalEstabilizacaoV_52'
import { NewsInternalEstabilizacaoV53 as NewsInternalEstabilizacaoVCincoTres } from './NewsInternalEstabilizacaoV_53'
import { NewsInternalPanelCincoZero } from './NewsInternalPanelV5_00'
import { NewsInternalPanelCincoUm } from './NewsInternalPanelV5_10'
import { NewsInternalPanelCincoDois } from './NewsInternalPanelV5_20'
import { NewsInternalPanelCincoTres } from './NewsInternalPanelV5_30'
import { NewsInternalPanelCincoQuatro } from './NewsInternalPanelV5_40'

interface NewsInternalPanelProps {
  version: NewsVersion
}

export function NewsInternalPanel({ version }: NewsInternalPanelProps) {
  const { title, panel } = versionsRecord[version]
  return (
    <Fragment>
      <Heading level={1} id={version}>
        {title}
      </Heading>
      {panel}
    </Fragment>
  )
}

const versionsRecord: Record<NewsVersion, { title: string; panel: ReactNode }> = {
  '5-4': { title: 'Versão 5.4', panel: <NewsInternalPanelCincoQuatro /> },
  '5-3-estabilizacao': {
    title: 'Versões de estabilização 5.3',
    panel: <NewsInternalEstabilizacaoVCincoTres />,
  },
  '5-3': { title: 'Versão 5.3', panel: <NewsInternalPanelCincoTres /> },
  '5-2-estabilizacao': {
    title: 'Versões de estabilização 5.2',
    panel: <NewsInternalEstabilizacaoVCincoDois />,
  },
  '5-2': { title: 'Versão 5.2', panel: <NewsInternalPanelCincoDois /> },
  '5-1-estabilizacao': {
    title: 'Versões de estabilização 5.1',
    panel: <NewsInternalEstabilizacaoVCincoUm />,
  },
  '5-1': { title: 'Versão 5.1', panel: <NewsInternalPanelCincoUm /> },
  '5-0-estabilizacao': {
    title: 'Versões de estabilização 5.0',
    panel: <NewsInternalEstabilizacaoVCincoZero />,
  },
  '5-0': { title: 'Versão 5.0', panel: <NewsInternalPanelCincoZero /> },
}
