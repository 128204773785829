import { TipoAtividadeEnum } from 'graphql/types.generated'
import { MetaRoot } from 'util/metaPath'

import {
  AtividadeColetivaFormModel,
  PRATICAS_SAUDE_ATENDIMENTO_EM_GRUPO,
  PRATICAS_SAUDE_EDUCACAO_PSE,
  TIPOS_ATIVIDADE_EM_SAUDE_EDUCACAO_PSE,
} from '../model-atividadeColetiva'
import { isOnlyEducacaoPse, isPraticaSaudeDisabled, isProcedimentoDisabled } from './utils-atividadeColetivaSaude'

export const createAtividadeColetivaSaudeCalculations = (path: MetaRoot<AtividadeColetivaFormModel>) => [
  {
    field: path.atividadePse.absolutePath(),
    updates: {
      [path.localAtividade.outraLocalidade.absolutePath()]: (_, allValues: AtividadeColetivaFormModel) =>
        isOnlyEducacaoPse(allValues) ? null : allValues.localAtividade.outraLocalidade,
      [path.localAtividade.unidadeSaudeLocalAtividade.absolutePath()]: (_, allValues: AtividadeColetivaFormModel) =>
        isOnlyEducacaoPse(allValues) ? null : allValues.localAtividade.unidadeSaudeLocalAtividade,
      [path.lotacoesEnvolvidas.absolutePath()]: (_, allValues: AtividadeColetivaFormModel) =>
        isOnlyEducacaoPse(allValues) ? null : allValues.lotacoesEnvolvidas,
      [path.praticasSaude.absolutePath()]: (_, allValues: AtividadeColetivaFormModel) =>
        isOnlyEducacaoPse(allValues)
          ? allValues.praticasSaude?.filter((pratica) => PRATICAS_SAUDE_EDUCACAO_PSE.includes(pratica))
          : allValues.praticasSaude,
      [path.tipoAtividade.absolutePath()]: (_, allValues: AtividadeColetivaFormModel) =>
        isOnlyEducacaoPse(allValues) && !TIPOS_ATIVIDADE_EM_SAUDE_EDUCACAO_PSE.includes(allValues.tipoAtividade)
          ? null
          : allValues.tipoAtividade,
    },
  },
  {
    field: path.tipoAtividade.absolutePath(),
    updates: {
      [path.praticasSaude.absolutePath()]: (_, allValues: AtividadeColetivaFormModel) =>
        isPraticaSaudeDisabled(allValues)
          ? null
          : allValues.tipoAtividade === TipoAtividadeEnum.ATENDIMENTO_EM_GRUPO
          ? allValues.praticasSaude?.filter((pratica) => PRATICAS_SAUDE_ATENDIMENTO_EM_GRUPO.includes(pratica))
          : allValues.praticasSaude,
    },
  },
  {
    field: path.praticasSaude.absolutePath(),
    updates: {
      [path.procedimento.absolutePath()]: (_, allValues: AtividadeColetivaFormModel) =>
        isProcedimentoDisabled(allValues) ? null : allValues.procedimento,
    },
  },
]
