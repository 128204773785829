/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Cell, Grid, HeadingSection, Text, Theme, useTheme } from 'bold-ui'
import { Breadcrumb } from 'components/breadcrumb'
import { PageContent } from 'components/layout/PageContent'
import { LinksUteisCard } from 'components/LinksUteisCard'
import { PageHeader } from 'components/PageHeader'
import { ReactComponent as LinksUteisImage } from 'images/links-uteis/ilustracao-links-uteis.svg'
import { Fragment } from 'react'

export const LinksUteisView = () => {
  const theme = useTheme()
  const styles = createStyles(theme)

  return (
    <Fragment>
      <Breadcrumb title='Links úteis' />
      <PageContent type='filled'>
        <Grid>
          <Cell xs={12} sm={6} md={8}>
            <PageHeader style={styles.title} title='Links úteis' />
            <HeadingSection style={styles.subtitle} level={3} title='Descubra mais sobre o e-SUS APS e o PEC' />

            <Text style={styles.sectionTitle} component='p' fontWeight='bold' fontSize={1}>
              Em destaque
            </Text>

            <Grid>
              <Cell xs={12} sm={12} md={6}>
                <LinksUteisCard
                  title='Educa e-sus APS'
                  description='Educação permanente em saúde digital para a atenção primária à saúde.'
                  link='https://educaesusaps.medicina.ufmg.br/'
                />
              </Cell>
              <Cell xs={12} sm={12} md={6}>
                <LinksUteisCard
                  title='Suporte do PEC'
                  description='Portal de suporte para auxiliar usuários do e-SUS APS.'
                  link='https://esusaps.freshdesk.com/support/login'
                />
              </Cell>
            </Grid>
          </Cell>

          <Cell xs={12} sm={6} md={4} style={styles.imageCell}>
            <LinksUteisImage
              title='Ilustração de uma pessoa de braços abertos em frente a uma grande tela com texto, acompanhada de um ícone de link em destaque. Acima da cabeça da pessoa, há uma lâmpada acesa, simbolizando uma ideia ou inspiração.'
              css={styles.image}
            />
          </Cell>
        </Grid>

        <Text style={styles.sectionTitle} component='p' fontWeight='bold' fontSize={1}>
          Sobre o sistema
        </Text>

        <Grid gapVertical={1}>
          <Cell sm={6} md={4}>
            <LinksUteisCard
              title='Manual do e-SUS APS'
              description='Manual de uso do e-SUS APS e dos aplicativos e-SUS Território, Atividade Coletiva, Vacinação e Gestão.'
              link='https://saps-ms.github.io/Manual-eSUS_APS/'
            />
          </Cell>

          <Cell xs={12} sm={6} md={4}>
            <LinksUteisCard
              title='Portal saúde baseada em evidências'
              description='Bases de dados científicas para auxiliar profissionais de saúde na tomada de decisão clínica
                  e de gestão.'
              link='https://psbe.ufrn.br/'
            />
          </Cell>

          <Cell xs={12} sm={6} md={4}>
            <LinksUteisCard
              title='Ministério da Saúde'
              description='Portal do Ministério da Saúde, confira tudo sobre a saúde no Brasil.'
              link='https://www.gov.br/saude/'
            />
          </Cell>

          <Cell xs={12} sm={6} md={4}>
            <LinksUteisCard
              title='Secretaria de Atenção Primária à Saúde (SAPS)'
              description='Saiba mais sobre as atividades da SAPS.'
              link='https://www.gov.br/saude/pt-br/composicao/saps'
            />
          </Cell>

          <Cell xs={12} sm={6} md={4}>
            <LinksUteisCard
              title='Estratégia e-SUS Atenção Primária'
              description='Entenda mais sobre a estratégia e-SUS APS.'
              link='https://sisaps.saude.gov.br/esus/'
            />
          </Cell>
        </Grid>
      </PageContent>
    </Fragment>
  )
}

const createStyles = (theme: Theme) => ({
  title: css`
    padding: 1rem 0;
  `,
  subtitle: css`
    & > :first-child {
      margin-bottom: 0;
    }
  `,
  sectionTitle: css`
    padding-top: 2rem;
    padding-bottom: 0.5rem;
  `,
  imageCell: css`
    display: flex;
    justify-content: center;
    align-items: center;
  `,
  image: css`
    width: 100%;
    height: auto;
  `,
})
