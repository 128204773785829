import { SimNaoEnum } from 'components/form'
import { IvcfEmAtendimentoObservacaoQuery } from 'graphql/types.generated'

import {
  IVCF_SUBGRUPOS,
  IvcfAplicacaoModel,
  IvcfFieldModel,
  IvcfRetificacaoQueryModel,
  IvcfSubGrupoEnum,
  IvcfViewModel,
} from './model-ivcf'

export const convertIvcfQueryToView = (
  value: IvcfEmAtendimentoObservacaoQuery['atendimentoIndividual']['ivcf']
): IvcfViewModel => {
  if (!value) return null
  return {
    resultado: value.resultado,
    riscoVulnerabilidade: value.riscoVulnerabilidade,
    dimensoesAlteradas: value.dimensoesAlteradas,
    aplicacao: Object.fromEntries(
      value.aplicacao.map((item) => [item.pergunta, item.resposta ? SimNaoEnum.SIM : SimNaoEnum.NAO])
    ) as IvcfAplicacaoModel,
  }
}

export const converIvcfFormToView = (value: IvcfFieldModel): IvcfViewModel => {
  if (!value) return null
  return {
    resultado: value.resultado,
    riscoVulnerabilidade: value.riscoVulnerabilidade,
    dimensoesAlteradas: value.dimensoesAlteradas
      .map((dimensaoAlterada) => IVCF_SUBGRUPOS[dimensaoAlterada].label)
      .join('; '),
    aplicacao: value.aplicacao,
  }
}

const labelToSubgrupo: Map<string, IvcfSubGrupoEnum> = new Map(
  Object.entries(IVCF_SUBGRUPOS).map(([key, value]) => [value.label, key as IvcfSubGrupoEnum])
)

export const convertIvcfQueryRetificacaoToForm = (ivcf: IvcfRetificacaoQueryModel): IvcfFieldModel => {
  if (!ivcf) return null

  const aplicacao = ivcf.aplicacao
    ? (Object.fromEntries(
        ivcf.aplicacao.map((item) => [item.pergunta, item.resposta ? SimNaoEnum.SIM : SimNaoEnum.NAO])
      ) as IvcfAplicacaoModel)
    : null

  const dimensoesAlteradas = ivcf.dimensoesAlteradas
    .split('; ')
    .map((label) => labelToSubgrupo.get(label))
    .filterNotNull()

  return {
    resultado: ivcf.resultado,
    riscoVulnerabilidade: ivcf.riscoVulnerabilidade,
    aplicacao: aplicacao,
    dimensoesAlteradas: dimensoesAlteradas,
  }
}
