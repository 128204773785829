import { addWeeks, isBefore, subMonths, toDate } from 'date-fns'
import { maxBy, minBy } from 'lodash'
import { isUndefinedOrNull } from 'util/checks'
import { isAfterOrEqual, isBetween } from 'util/date/compare'
import { TipoPreNatal } from 'view/atendimentos/detail/soap/pre-natal/model-preNatal'
import { isEncerramentoGestacao } from 'view/atendimentos/detail/soap/pre-natal/util-preNatal'
import { HistoricoMedicaoModel } from 'view/atendimentos/types/model-historicoMedicao'

import { MedicaoAntropometricaPreNatal } from './model-medicoesPreNatal'
import { renderMedicaoPreNatal } from './render-medicoesPreNatal'

type MedicoesValidasReduced = {
  preGestacionais: MedicaoAntropometricaPreNatal[]
  gestacionais: MedicaoAntropometricaPreNatal[]
}

export function getMedicaoForImcReferencia(
  dataInicioGestacao: Date,
  medicoes: MedicaoAntropometricaPreNatal[]
): MedicaoAntropometricaPreNatal {
  if (isUndefinedOrNull(dataInicioGestacao) || isUndefinedOrNull(medicoes) || medicoes.isEmpty()) return null

  const { preGestacionais, gestacionais } = getMedicoesValidas(medicoes, dataInicioGestacao)

  if (!preGestacionais.isEmpty()) return maxBy(preGestacionais, (medicao) => medicao.dataMedicao)
  if (!gestacionais.isEmpty()) return minBy(gestacionais, (medicao) => medicao.dataMedicao)

  return null
}

function getMedicoesValidas(medicoes: MedicaoAntropometricaPreNatal[], dataInicioGestacao: Date) {
  return medicoes.reduce<MedicoesValidasReduced>(
    (acc, medicao) => {
      if (!hasPesoAndAltura(medicao)) return acc

      const dataMedicaoAsDate = toDate(medicao.dataMedicao)

      if (isMedicaoPreGestacionalValida(dataMedicaoAsDate, dataInicioGestacao)) acc.preGestacionais.push(medicao)
      if (isMedicaoGestacionalValida(dataMedicaoAsDate, dataInicioGestacao)) acc.gestacionais.push(medicao)

      return acc
    },
    { preGestacionais: [], gestacionais: [] }
  )
}

const hasPesoAndAltura = (medicao: MedicaoAntropometricaPreNatal): boolean =>
  !isUndefinedOrNull(medicao.altura) && !isUndefinedOrNull(medicao.peso)

const isMedicaoPreGestacionalValida = (dataMedicao: Date, dataInicioGestacao: Date): boolean =>
  isAfterOrEqual(dataMedicao, subMonths(dataInicioGestacao, 2)) && isBefore(dataMedicao, dataInicioGestacao)

const isMedicaoGestacionalValida = (dataMedicao: Date, dataInicioGestacao: Date): boolean =>
  isBetween(dataMedicao, dataInicioGestacao, addWeeks(dataInicioGestacao, 8))

export function getMedicoesPeso10SemanasAposInicioGestacao(
  dataInicioGestacao: Date,
  medicoesPeso: MedicaoAntropometricaPreNatal[]
): MedicaoAntropometricaPreNatal[] {
  if (isUndefinedOrNull(dataInicioGestacao) || isUndefinedOrNull(medicoesPeso) || medicoesPeso.isEmpty()) return null

  const dezSemanasAposInicioGestacao = addWeeks(dataInicioGestacao, 10)

  const medicoesValidas = medicoesPeso.filter(
    (medicao) =>
      !isUndefinedOrNull(medicao.peso) && !isBefore(toDate(medicao.dataMedicao), dezSemanasAposInicioGestacao)
  )

  return medicoesValidas
}

export function computeGanhoPesoGestacional(pesoReferencia: number, pesoConsulta: number): number {
  if (isUndefinedOrNull(pesoReferencia) || isUndefinedOrNull(pesoConsulta)) return null
  return pesoConsulta - pesoReferencia
}

export function renderGanhoPeso(pesoReferencia: number, pesoConsulta: number): String {
  const ganhoPeso = computeGanhoPesoGestacional(pesoReferencia, pesoConsulta)
  return `${ganhoPeso < -0.005 ? '-' : ''}${renderMedicaoPreNatal(Math.abs(ganhoPeso))}`
}

export function getPesoAtendimentoAtual(medicoesPeso: MedicaoAntropometricaPreNatal[]): number {
  return medicoesPeso.find((medicao) => medicao.origem === 'atendimentoAtual')?.peso
}

function hasAlturaOuPeso(medicao: HistoricoMedicaoModel): boolean {
  return !!medicao.valorPeso || !!medicao.valorAltura
}

function isMedicaoValida(medicao: HistoricoMedicaoModel, isEncerramentoGestacao: boolean): boolean {
  if (hasAlturaOuPeso(medicao)) {
    if (medicao.origem === 'atendimentoAtual' || medicao.origem === 'medicoesAnteriores') {
      return !isEncerramentoGestacao
    }

    return true
  }

  return false
}

export function getMedicoesAntropometricasPreNatal(
  medicoes: HistoricoMedicaoModel[],
  tipoPreNatal?: TipoPreNatal
): MedicaoAntropometricaPreNatal[] {
  const isEncerramento = isEncerramentoGestacao(tipoPreNatal)

  return medicoes
    .filter((medicao) => isMedicaoValida(medicao, isEncerramento))
    .map((medicao) => ({
      dataMedicao: medicao.dataMedicao,
      peso: medicao.valorPeso,
      altura: medicao.valorAltura,
      origem: medicao.origem,
    }))
}
