import { Button, DataTable, Icon, TableFooter, Tooltip } from 'bold-ui'
import { CidadaoSelectFieldModel, SimNaoEnum, simNaoEnumRecord } from 'components/form'
import { TableBox } from 'components/table/TableBox'
import { usePagination } from 'components/table/usePagination'
import { css } from 'emotion'
import { TipoAtividadeEnum } from 'graphql/types.generated'
import React from 'react'
import { MetaArray } from 'util/metaPath'
import { useEditableListField } from 'view/atendimentos/detail/components/EditableList'
import { CidadaoParticipanteModel, ProfissionalCpfCnsIdModel } from 'view/atividade-coletiva/model-atividadeColetiva'

import { ParticipanteCidadaoInfo } from './ParticipanteCidadaoInfo'
import { ParticipanteEditableTableForm } from './ParticipanteEditableTableForm'
import { ParticipanteImc } from './ParticipanteImc'

export interface ParticipanteEditableTableModel {
  _id: ID
  isCidadaoParticipanteFormOpen: boolean
  cidadao: CidadaoSelectFieldModel
  cidadaoParticipante: CidadaoParticipanteModel
  peso: string
  altura: string
  avaliacaoAlterada: boolean
  pnctAbandonouGrupo: SimNaoEnum
  pnctCessouHabitoFumar: SimNaoEnum
}

interface ParticipanteEditableTableProps {
  now: Date
  dataAtividade: LocalDate
  tipoAtividade: TipoAtividadeEnum
  required: boolean
  profissionalResponsavel: ProfissionalCpfCnsIdModel
  profissionaisEnvolvidos: ProfissionalCpfCnsIdModel[]
  isPraticaSaudePnct: boolean
  name: MetaArray<ParticipanteEditableTableModel>
}

export function ParticipanteEditableTable(props: ParticipanteEditableTableProps) {
  const { name, isPraticaSaudePnct, now, dataAtividade, tipoAtividade, ...formProps } = props

  const {
    handleSubmit,
    removeItem,
    input: { value },
  } = useEditableListField({ name })

  const allItems: ParticipanteEditableTableModel[] = (value || []).slice().reverse()
  const { paginatedItems, tableProps } = usePagination({ items: allItems, initialPageSize: 5 })

  const renderButtons = (row: ParticipanteEditableTableModel) => (
    <Tooltip text='Excluir'>
      <Button type='button' kind='normal' skin='ghost' size='small' onClick={() => removeItem(row)}>
        <Icon icon='trashOutline' />
      </Button>
    </Tooltip>
  )

  return (
    <TableBox
      header={
        <ParticipanteEditableTableForm
          now={now}
          allItems={allItems}
          dataAtividade={dataAtividade}
          tipoAtividade={tipoAtividade}
          isPraticaSaudePnct={isPraticaSaudePnct}
          onSubmit={handleSubmit}
          {...formProps}
        />
      }
    >
      <DataTable<ParticipanteEditableTableModel>
        rows={paginatedItems}
        columns={[
          {
            name: 'nome',
            header: 'Nome',
            render: (row) => <ParticipanteCidadaoInfo participante={row} dataAtividade={dataAtividade} />,
            style: styles.tableRowNome,
          },
          {
            name: 'avaliacaoAlterada',
            header: 'Avaliação alterada',
            render: (row) => (row.avaliacaoAlterada ? 'Sim' : 'Não'),
          },
          {
            name: 'peso',
            header: 'Peso (Kg)',
            render: (row) => (row.peso ? row.peso : '-'),
          },
          {
            name: 'altura',
            header: 'Altura (cm)',
            render: (row) => (row.altura ? row.altura : '-'),
          },
          {
            name: 'imc',
            header: 'IMC',
            style: styles.tableRowIMC,
            render: (row) => <ParticipanteImc participante={row} dataAtividade={dataAtividade ?? now.getTime()} />,
          },
          isPraticaSaudePnct && {
            name: 'pnctCessouFumar',
            header: 'Cessou o hábito de fumar?',
            render: (row) => simNaoEnumRecord[row.pnctCessouHabitoFumar] ?? 'Não informado',
            style: styles.tableRowPnct,
          },
          isPraticaSaudePnct && {
            name: 'pnctAbandonouGrupo',
            header: 'Abandonou o grupo?',
            render: (row) => simNaoEnumRecord[row.pnctAbandonouGrupo] ?? 'Não informado',
            style: styles.tableRowPnct,
          },
          {
            name: 'buttons',
            render: renderButtons,
            style: styles.tableRowButtons,
          },
        ].filterNotFalsy()}
      />
      <TableFooter style={styles.tableFooter} {...tableProps} />
    </TableBox>
  )
}

const styles = {
  tableFooter: css`
    border-top: 0;
  `,
  tableRowNome: css`
    width: 15rem;
  `,
  tableRowIMC: css`
    width: 20rem;
  `,
  tableRowPnct: css`
    width: 9rem;
  `,
  tableRowButtons: css`
    width: 3.5rem;
    text-align: right;
  `,
}
