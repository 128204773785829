/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Button, ExternalStyles, HFlow, Icon, Omit, Radio, RadioProps, Theme, useTheme } from 'bold-ui'
import { confirm } from 'components/modals/confirm'
import { useMemo } from 'react'
import { CypressProps } from 'types/cypress'

import { UseFieldProps, usePecField } from '../hooks/useField'

export interface RadioFieldProps
  extends UseFieldProps<any>,
    Omit<RadioProps, 'name' | 'value' | 'defaultValue' | 'onChange' | 'onFocus' | 'onBlur'>,
    CypressProps {
  clearable?: boolean
  wrapperStyle?: ExternalStyles
  showClearConfirm?: boolean
  clearFieldLabel?: string
}

export function RadioField(props: RadioFieldProps) {
  const { clearable, wrapperStyle, showClearConfirm, clearFieldLabel, ...restProps } = props
  const { input, meta, ...rest } = usePecField({ type: 'radio', ...restProps })

  const theme = useTheme()
  const classes = useMemo(() => styles(theme), [theme])

  const handleClear = async () => {
    const confirmado =
      !showClearConfirm ||
      (await confirm({
        title: `Deseja excluir o campo ${clearFieldLabel}?`,
        type: 'danger',
        confirmLabel: 'Excluir',
      })())
    if (confirmado) input.onChange(undefined)
  }

  return (
    <HFlow style={css(classes.wrapper, wrapperStyle)}>
      <Radio {...input} {...rest} />

      {clearable && input.checked && (
        <Button size='small' skin='ghost' style={classes.closeButton} onClick={handleClear}>
          <Icon icon='timesDefault' fill='secondary' />
        </Button>
      )}
    </HFlow>
  )
}

const styles = (theme: Theme) => ({
  closeButton: css`
    padding: 0;
    margin: -1px 0;
    &:hover {
      svg {
        fill: ${theme.pallete.status.danger.main};
      }
    }
  `,
  wrapper: css`
    ${theme.breakpoints.down('xl')} {
      grid-gap: 0.5rem;
    }
  `,
})
