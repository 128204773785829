import { ColorScale, green, orange, red, yellow } from 'bold-ui/lib/styles/colors'
import { SimNaoEnum } from 'components/form'
import {
  AtendimentoIndividualRetificacaoViewQuery,
  IvcfPerguntaEnum,
  RiscoVulnerabilidadeIvcfEnum,
} from 'graphql/types.generated'

export enum IvcfGrupoEnum {
  IDADE = 'IDADE',
  PERCEPCAO_SAUDE = 'PERCEPCAO_SAUDE',
  AVD = 'AVD',
  COGNICAO = 'COGNICAO',
  HUMOR = 'HUMOR',
  MOBILIDADE = 'MOBILIDADE',
  COMUNICACAO = 'COMUNICACAO',
  COMORBIDADE_MULTIPLA = 'COMORBIDADE_MULTIPLA',
}

export enum IvcfSubGrupoEnum {
  IDADE = 'IDADE',
  PERCEPCAO = 'PERCEPCAO',
  AVD_INSTRUMENTAL = 'AVD_INSTRUMENTAL',
  AVD_BASICA = 'AVD_BASICA',
  COGNICAO = 'COGNICAO',
  HUMOR = 'HUMOR',
  ALCANCE_PREENSAO_PINCA = 'ALCANCE_PREENSAO_PINCA',
  CAPACIDADE = 'CAPACIDADE',
  MARCHA = 'MARCHA',
  CONTINENCIA = 'CONTINENCIA',
  VISAO = 'VISAO',
  AUDICAO = 'AUDICAO',
  COMORBIDADE = 'COMORBIDADE',
}

interface IvcfGrupoModel {
  title: string
  titleComplement?: string
  subtitle?: string
  index: number
}

interface IvcfSubGrupoModel {
  label: string
  subtitle?: string
  perguntas?: IvcfPerguntaEnum[]
  grupo: IvcfGrupoEnum
}

interface IvcfPerguntaModel {
  label: string
  layout: 'horizontal' | 'vertical'
  options: Record<SimNaoEnum, string>
}

interface IvcfResultadoModel {
  textColor: string
  lineBgColor: string
  colorScale: ColorScale
  shortTitle: string
  longTitle: string
}

export type IvcfAplicacaoModel = Record<IvcfPerguntaEnum, SimNaoEnum>

export interface IvcfViewModel {
  aplicacao: IvcfAplicacaoModel
  resultado: number
  riscoVulnerabilidade: RiscoVulnerabilidadeIvcfEnum
  dimensoesAlteradas: string
}

export interface IvcfFieldModel {
  aplicacao: IvcfAplicacaoModel
  resultado: number
  riscoVulnerabilidade: RiscoVulnerabilidadeIvcfEnum
  dimensoesAlteradas: IvcfSubGrupoEnum[]
  isEditing?: boolean
}

export type IvcfRetificacaoQueryModel = AtendimentoIndividualRetificacaoViewQuery['atendimento']['atendimentoProfissional']['ivcf']

export const IVCF_SUBGRUPOS: Record<IvcfSubGrupoEnum, IvcfSubGrupoModel> = {
  IDADE: { label: 'Idade', grupo: IvcfGrupoEnum.IDADE },
  PERCEPCAO: {
    label: 'Percepção da saúde',
    perguntas: [IvcfPerguntaEnum.PERCEPCAO_SAUDE_PERGUNTA_1],
    grupo: IvcfGrupoEnum.PERCEPCAO_SAUDE,
  },
  AVD_INSTRUMENTAL: {
    label: 'AVD Instrumental',
    subtitle: 'Por causa de sua saúde ou condição física,',
    perguntas: [
      IvcfPerguntaEnum.AVD_INSTRUMENTAL_PERGUNTA_1,
      IvcfPerguntaEnum.AVD_INSTRUMENTAL_PERGUNTA_2,
      IvcfPerguntaEnum.AVD_INSTRUMENTAL_PERGUNTA_3,
    ],
    grupo: IvcfGrupoEnum.AVD,
  },
  AVD_BASICA: {
    label: 'AVD Básica',
    perguntas: [IvcfPerguntaEnum.AVD_BASICA_PERGUNTA_1],
    grupo: IvcfGrupoEnum.AVD,
  },
  COGNICAO: {
    label: 'Cognição',
    perguntas: [
      IvcfPerguntaEnum.COGNICAO_PERGUNTA_1,
      IvcfPerguntaEnum.COGNICAO_PERGUNTA_2,
      IvcfPerguntaEnum.COGNICAO_PERGUNTA_3,
    ],
    grupo: IvcfGrupoEnum.COGNICAO,
  },
  HUMOR: {
    label: 'Humor',
    perguntas: [IvcfPerguntaEnum.HUMOR_PERGUNTA_1, IvcfPerguntaEnum.HUMOR_PERGUNTA_2],
    grupo: IvcfGrupoEnum.HUMOR,
  },
  ALCANCE_PREENSAO_PINCA: {
    label: 'Alcance, preensão e pinça',
    perguntas: [IvcfPerguntaEnum.ALCANCE_PREENSAO_PINCA_PERGUNTA_1, IvcfPerguntaEnum.ALCANCE_PREENSAO_PINCA_PERGUNTA_2],
    grupo: IvcfGrupoEnum.MOBILIDADE,
  },
  CAPACIDADE: {
    label: 'Capacidade aeróbica e/ou muscular',
    perguntas: [
      IvcfPerguntaEnum.CAPACIDADE_PERGUNTA_1,
      IvcfPerguntaEnum.CAPACIDADE_PERGUNTA_2,
      IvcfPerguntaEnum.CAPACIDADE_PERGUNTA_3,
      IvcfPerguntaEnum.CAPACIDADE_PERGUNTA_4,
      IvcfPerguntaEnum.CAPACIDADE_PERGUNTA_5,
    ],
    grupo: IvcfGrupoEnum.MOBILIDADE,
  },
  MARCHA: {
    label: 'Marcha',
    perguntas: [IvcfPerguntaEnum.MARCHA_PERGUNTA_1, IvcfPerguntaEnum.MARCHA_PERGUNTA_2],
    grupo: IvcfGrupoEnum.MOBILIDADE,
  },
  CONTINENCIA: {
    label: 'Continência esfincteriana',
    perguntas: [IvcfPerguntaEnum.CONTINENCIA_PERGUNTA_1],
    grupo: IvcfGrupoEnum.MOBILIDADE,
  },
  VISAO: {
    label: 'Visão',
    perguntas: [IvcfPerguntaEnum.VISAO_PERGUNTA_1],
    grupo: IvcfGrupoEnum.COMUNICACAO,
  },
  AUDICAO: {
    label: 'Audição',
    perguntas: [IvcfPerguntaEnum.AUDICAO_PERGUNTA_1],
    grupo: IvcfGrupoEnum.COMUNICACAO,
  },
  COMORBIDADE: {
    label: 'Comorbidade múltipla',
    perguntas: [
      IvcfPerguntaEnum.COMORBIDADE_PERGUNTA_1,
      IvcfPerguntaEnum.COMORBIDADE_PERGUNTA_2,
      IvcfPerguntaEnum.COMORBIDADE_PERGUNTA_3,
    ],
    grupo: IvcfGrupoEnum.COMORBIDADE_MULTIPLA,
  },
}

export const IVCF_PERGUNTAS: Record<IvcfPerguntaEnum, IvcfPerguntaModel> = {
  PERCEPCAO_SAUDE_PERGUNTA_1: {
    label: 'Em geral, comparando com outras pessoas de sua idade, você diria que sua saúde é?',
    layout: 'vertical',
    options: {
      SIM: 'Excelente, muito boa ou boa',
      NAO: 'Regular ou ruim',
    },
  },
  AVD_INSTRUMENTAL_PERGUNTA_1: {
    label: 'Você deixou de fazer compras?',
    layout: 'vertical',
    options: {
      SIM: 'Sim',
      NAO: 'Não ou não faz compras por outros motivos que não a saúde',
    },
  },
  AVD_INSTRUMENTAL_PERGUNTA_2: {
    label: 'Você deixou de controlar seu dinheiro, gastos ou pagar as contas de sua casa?',
    layout: 'vertical',
    options: {
      SIM: 'Sim',
      NAO: 'Não ou não controla o dinheiro por outros motivos que não a saúde',
    },
  },
  AVD_INSTRUMENTAL_PERGUNTA_3: {
    label:
      'Você deixou de realizar pequenos trabalhos domésticos, como lavar a louça, arrumar a casa ou fazer limpeza leve?',
    layout: 'vertical',
    options: {
      SIM: 'Sim',
      NAO: 'Não ou não faz mais pequenos trabalhos domésticos por outros motivos que não a saúde',
    },
  },
  AVD_BASICA_PERGUNTA_1: {
    label: 'Por causa de sua saúde ou condição física, você deixou de tomar banho sozinho?',
    layout: 'horizontal',
    options: {
      SIM: 'Sim',
      NAO: 'Não',
    },
  },
  COGNICAO_PERGUNTA_1: {
    label: 'Algum familiar ou amigo falou que você está ficando esquecido?',
    layout: 'horizontal',
    options: {
      SIM: 'Sim',
      NAO: 'Não',
    },
  },
  COGNICAO_PERGUNTA_2: {
    label: 'Se sim, este esquecimento está piorando nos últimos meses?',
    layout: 'horizontal',
    options: {
      SIM: 'Sim',
      NAO: 'Não',
    },
  },
  COGNICAO_PERGUNTA_3: {
    label: 'Este esquecimento está impedindo a realização de alguma atividade do cotidiano?',
    layout: 'horizontal',
    options: {
      SIM: 'Sim',
      NAO: 'Não',
    },
  },
  HUMOR_PERGUNTA_1: {
    label: 'Você ficou com desânimo, tristeza ou desesperança?',
    layout: 'horizontal',
    options: {
      SIM: 'Sim',
      NAO: 'Não',
    },
  },
  HUMOR_PERGUNTA_2: {
    label: 'Você perdeu o interesse ou prazer em atividades anteriormente prazerosas?',
    layout: 'horizontal',
    options: {
      SIM: 'Sim',
      NAO: 'Não',
    },
  },
  ALCANCE_PREENSAO_PINCA_PERGUNTA_1: {
    label: 'Você é incapaz de elevar os braços acima do nível do ombro?',
    layout: 'horizontal',
    options: {
      SIM: 'Sim',
      NAO: 'Não',
    },
  },
  ALCANCE_PREENSAO_PINCA_PERGUNTA_2: {
    label: 'Você é incapaz de manusear ou segurar pequenos objetos?',
    layout: 'horizontal',
    options: {
      SIM: 'Sim',
      NAO: 'Não',
    },
  },
  CAPACIDADE_PERGUNTA_1: {
    label: 'Você teve perda de peso não intencional de 4,5 kg ou 5% do peso corporal no último ano?',
    layout: 'horizontal',
    options: {
      SIM: 'Sim',
      NAO: 'Não',
    },
  },
  CAPACIDADE_PERGUNTA_2: {
    label: 'Você teve perda de peso não intencional de 6 kg nos últimos 6 meses ou 3 kg no último mês?',
    layout: 'horizontal',
    options: {
      SIM: 'Sim',
      NAO: 'Não',
    },
  },
  CAPACIDADE_PERGUNTA_3: {
    label: 'Você tem Índice de Massa Corporal (IMC) menor que 22 kg/m²?',
    layout: 'horizontal',
    options: {
      SIM: 'Sim',
      NAO: 'Não',
    },
  },
  CAPACIDADE_PERGUNTA_4: {
    label: 'Você tem a circunferência (perímetro) da panturrilha menor que 31 centímetros?',
    layout: 'horizontal',
    options: {
      SIM: 'Sim',
      NAO: 'Não',
    },
  },
  CAPACIDADE_PERGUNTA_5: {
    label:
      'Em um teste de velocidade da marcha, o tempo gasto pelo idoso para percorrer 4 metros é maior do que 5 segundos?',
    layout: 'horizontal',
    options: {
      SIM: 'Sim',
      NAO: 'Não',
    },
  },
  MARCHA_PERGUNTA_1: {
    label: 'Você tem dificuldade para caminhar que possa impedir a realização de alguma atividade do cotidiano?',
    layout: 'horizontal',
    options: {
      SIM: 'Sim',
      NAO: 'Não',
    },
  },
  MARCHA_PERGUNTA_2: {
    label: 'Você teve duas ou mais quedas no último ano?',
    layout: 'horizontal',
    options: {
      SIM: 'Sim',
      NAO: 'Não',
    },
  },
  CONTINENCIA_PERGUNTA_1: {
    label: 'Você perde urina ou fezes, sem querer, em algum momento?',
    layout: 'horizontal',
    options: {
      SIM: 'Sim',
      NAO: 'Não',
    },
  },
  VISAO_PERGUNTA_1: {
    label:
      'Você tem problemas de visão capazes de impedir a realização de alguma atividade do cotidiano? (É permitido o uso de óculos ou lentes de contato).',
    layout: 'horizontal',
    options: {
      SIM: 'Sim',
      NAO: 'Não',
    },
  },
  AUDICAO_PERGUNTA_1: {
    label:
      'Você tem problemas de audição capazes de impedir a realização de alguma atividade do cotidiano? (É permitido o uso de aparelhos de audição).',
    layout: 'horizontal',
    options: {
      SIM: 'Sim',
      NAO: 'Não',
    },
  },
  COMORBIDADE_PERGUNTA_1: {
    label: 'Você tem cinco ou mais doenças crônicas?',
    layout: 'horizontal',
    options: {
      SIM: 'Sim',
      NAO: 'Não',
    },
  },
  COMORBIDADE_PERGUNTA_2: {
    label: 'Você faz uso regular de cinco ou mais medicamentos diferentes todos os dias?',
    layout: 'horizontal',
    options: {
      SIM: 'Sim',
      NAO: 'Não',
    },
  },
  COMORBIDADE_PERGUNTA_3: {
    label: 'Você teve alguma internação nos últimos 6 meses?',
    layout: 'horizontal',
    options: {
      SIM: 'Sim',
      NAO: 'Não',
    },
  },
}

export const IVCF_RESULTADOS: Record<RiscoVulnerabilidadeIvcfEnum, IvcfResultadoModel> = {
  BAIXO: {
    textColor: green.c40,
    lineBgColor: green.c60,
    colorScale: green,
    shortTitle: 'Baixo',
    longTitle: 'Baixo risco de vulnerabilidade clínico-funcional',
  },
  MODERADO: {
    textColor: yellow.c40,
    lineBgColor: yellow.c60,
    colorScale: orange,
    shortTitle: 'Moderado',
    longTitle: 'Moderado risco de vulnerabilidade clínico-funcional',
  },
  ALTO: {
    textColor: red.c20,
    lineBgColor: `linear-gradient(90deg, ${red.c60} 0%, ${red.c30} 100%)`,
    colorScale: red,
    shortTitle: 'Alto',
    longTitle: 'Alto risco de vulnerabilidade clínico-funcional',
  },
}

export const IVCF_GRUPOS: Record<IvcfGrupoEnum, IvcfGrupoModel> = {
  IDADE: {
    title: 'Idade',
    titleComplement: ' do cidadão',
    index: 1,
  },
  PERCEPCAO_SAUDE: {
    title: 'Percepção da saúde',
    index: 2,
  },
  AVD: {
    title: 'AVD',
    index: 3,
  },
  COGNICAO: {
    title: 'Cognição',
    index: 4,
  },
  HUMOR: {
    title: 'Humor',
    subtitle: 'No último mês,',
    index: 5,
  },
  MOBILIDADE: {
    title: 'Mobilidade',
    index: 6,
  },
  COMUNICACAO: {
    title: 'Comunicação',
    index: 7,
  },
  COMORBIDADE_MULTIPLA: {
    title: 'Comorbidade múltipla',
    index: 8,
  },
}
