import { Cell, Grid } from 'bold-ui'
import { FormSectionAccordion } from 'components/accordion/FormSectionAccordion'
import { CboSelectField, SimNaoEnum, SimNaoRadioGroupField } from 'components/form'
import { EscolaridadeSelectField } from 'components/form/field/select/EscolaridadeSelectField'
import { RecordStringSelectField } from 'components/form/final-form/RecordStringSelectField/RecordStringSelectField'
import theme from 'config/theme'
import { css } from 'emotion'
import React from 'react'
import { MetaPath } from 'util/metaPath'

import {
  criancas0a9anosComQuemFicaRecord,
  deficienciaRecord,
  InformacoesSociodemograficasFormModel,
  povoComunidadeTradicionalRecord,
  situacaoMercadoTrabalhoRecord,
} from '../model-cidadaoFci'
import { SimNaoAccordionItem } from './SimNaoAccordionItem'

interface InformacoesSociodemograficasSectionProps {
  meta: MetaPath<InformacoesSociodemograficasFormModel>
  values: InformacoesSociodemograficasFormModel
}

export const InformacoesSociodemograficasSection = ({ meta, values }: InformacoesSociodemograficasSectionProps) => {
  return (
    <FormSectionAccordion
      name={meta.openedAccordion}
      title='Informações sociodemográficas'
      uuid='informacoesSociodemograficas'
      textStyle={css`
        font-size: 1rem;
      `}
    >
      <Grid
        style={css`
          padding: 1rem;
          padding-bottom: 0.5rem;
        `}
      >
        <Cell size={6}>
          <EscolaridadeSelectField name={meta.escolaridade} title='Escolaridade' label='Escolaridade' />
        </Cell>
        <Cell size={6}>
          <SimNaoRadioGroupField name={meta.frequentaEscolaOuCreche} label='Frequenta escola ou creche?' clearable />
        </Cell>
        <Cell size={6}>
          <RecordStringSelectField
            name={meta.criancas0a9anosComQuemFica}
            record={criancas0a9anosComQuemFicaRecord}
            label='Com quem fica a criança? (Menores de 9 anos)'
            title='Com quem fica a criança? (Menores de 9 anos)'
            multiple
          />
        </Cell>
        <Cell size={6} />
        <Cell size={6} style={styles.cell}>
          <RecordStringSelectField
            name={meta.situacaoMercadoTrabalho}
            record={situacaoMercadoTrabalhoRecord}
            title='Situação no mercado de trabalho'
            label='Situação no mercado de trabalho'
          />
        </Cell>
        <Cell size={6} style={styles.cell}>
          <CboSelectField name={meta.ocupacao} label='Ocupação' mostrarIndisponivelLotacao />
        </Cell>
      </Grid>

      <SimNaoAccordionItem
        name={meta.possuiPlanoSaudePrivado}
        title='Possui plano de saúde privado?'
        borderBottom
        clearable
      />

      <SimNaoAccordionItem
        name={meta.frequentaCuidadorTradicional}
        title='Frequente cuidador tradicional?'
        clearable
        borderBottom
      />
      <SimNaoAccordionItem
        name={meta.participaAlgumGrupoComunitario}
        title='Participa de algum grupo comunitário?'
        clearable
        borderBottom
      />
      <SimNaoAccordionItem
        name={meta.isMembroComunidadeTradicional}
        title='É membro de Povo ou Comunidade Tradicional ou População de Campo, Floresta e Águas?'
        clearable
        borderBottom
        children={
          <RecordStringSelectField
            name={meta.povoComunidadeTradicional}
            record={povoComunidadeTradicionalRecord}
            label='Indique qual'
            title='Indique qual'
            disabled={values?.isMembroComunidadeTradicional !== SimNaoEnum.SIM}
            style={css`
              width: 416px;
              margin-right: 1rem;
            `}
          />
        }
      />
      <SimNaoAccordionItem
        name={meta.possuiDeficiencia}
        title='Possui alguma deficiência?'
        clearable
        children={
          <RecordStringSelectField
            name={meta.deficiencias}
            record={deficienciaRecord}
            label='Indique qual(is)'
            title='Indique qual(is)'
            disabled={values?.possuiDeficiencia !== SimNaoEnum.SIM}
            multiple
          />
        }
      />
    </FormSectionAccordion>
  )
}

const styles = {
  cell: css`
    border-bottom: 1px solid ${theme.pallete.divider};
  `,
}
