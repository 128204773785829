import { DeepPartial } from 'bold-ui'
import { TimeInterval, TimeOfDay, Weekday } from 'components/agenda'
import { parseISO, set } from 'date-fns'
import {
  AtualizarContatoCidadaoInput,
  ConfiguracaoHorariosAgendaLotacaoQuery,
  ConfiguracaoPeriodo,
  CriarAgendamentoConsultaCompartilhadaInput,
  CriarAgendamentoConsultaInput,
  CriarAgendamentoReservaInput,
  DiaSemanaEnum,
  EditarAgendadoQuery,
  EditarAgendamentoConsultaCompartilhadaInput,
  EditarAgendamentoConsultaInput,
  EditarAgendamentoReservaInput,
  FechamentoAgenda,
  Lotacao,
  MotivoAgendamentoReservaEnum,
  TipoAgendamentoEnum,
} from 'graphql/types.generated'

import {
  AgendamentoConsultaCompartilhadaModel,
  AgendamentoConsultaModel,
  AgendamentoEdicaoModel,
  AgendamentoQueryModel,
  AgendamentoReservaModel,
  ContatoCidadaoAgendamento,
  EventoAgendaLotacao,
  LotacaoAgendamentoModel,
  LotacaoAgendaModel,
  motivoReservaRecord,
  TipoAgendamento,
} from './model-agenda'

const SEPARADOR_HORA_MINUTO = ':'

type AtendimentoAgendado = AgendamentoQueryModel['atendimento']
type ContatoCidadao = AgendamentoQueryModel['cidadao']['contato']
type ConfiguracaoDiaSemana = ConfiguracaoHorariosAgendaLotacaoQuery['lotacao']['configuracaoAgenda']['configuracaoHorarioAgenda']['configuracaoDiasSemana'][number]
type ConfiguracaoDiaSemanaAgendaOnline = ConfiguracaoHorariosAgendaLotacaoQuery['lotacao']['gradeConfiguracaoAgendaOnline']['configuracoesSemana'][number]

export const convertAgendamentos = (
  agendamento: AgendamentoQueryModel,
  lotacao: LotacaoAgendaModel
): EventoAgendaLotacao => ({
  id: agendamento.id,
  start: new Date(agendamento.horarioInicial),
  end: new Date(agendamento.horarioFinal),
  title:
    agendamento.cidadao?.nome ||
    agendamento?.outroMotivoReserva ||
    (agendamento.motivoReserva && motivoReservaRecord[agendamento.motivoReserva].nome),
  origem: agendamento.origem,
  tipo:
    agendamento.tipoAgendamento === TipoAgendamentoEnum.RESERVA ? TipoAgendamento.RESERVA : TipoAgendamento.CONSULTA,
  isForaUbs: agendamento.isForaUbs,
  localAtendimento: agendamento.localAtendimento?.localAtendimentoExibicao,
  isOutroMotivoReserva: !!agendamento.outroMotivoReserva,
  motivoReserva:
    agendamento.outroMotivoReserva ||
    (agendamento.motivoReserva && motivoReservaRecord[agendamento.motivoReserva].nome),
  nomeCidadao: agendamento.cidadao?.nomeSocial ?? agendamento.cidadao?.nome,
  observacoes: agendamento.observacao,
  situacao: agendamento.situacao,
  situacaoSincronizacao: agendamento.situacaoSincronizacao,
  telefoneCidadao: getTelefoneCidadao(agendamento.cidadao?.contato),
  cidadaoId: agendamento.cidadao?.id,
  prontuarioId: agendamento.prontuarioId,
  lotacao: lotacao,
  nomeProfissionalAnfitriao: agendamento.agendamentoPrincipal?.lotacaoAgendada.profissional.nome,
  emailProfissionalAnfitriao: agendamento.agendamentoPrincipal?.lotacaoAgendada.profissional.email,
  lotacaoProfissionalAnfitriaoId: agendamento.agendamentoPrincipal?.lotacaoAgendada.id,
  isAtencaoDomiciliar: agendamento.isAtencaoDomiciliar,
  atendimento: agendamento.atendimento ? convertAtendimento(agendamento.atendimento) : undefined,
  dataCriacao: agendamento.dataCriacao,
  emailProfissionalParticipante: agendamento.lotacaoParticipante?.profissional?.email,
  nomeProfissionalParticipante: agendamento.lotacaoParticipante?.profissional?.nome,
  videochamadaUuid: agendamento.videochamada?.uuid,
})

export const convertAtendimento = (atendimento: AtendimentoAgendado) => ({
  id: atendimento.id,
  situacao: atendimento.statusAtendimento,
  possuiAtendimentosProfissionais: atendimento.possuiAtendimentosProfissionais,
})

export const convertFechamento = (fechamento: FechamentoAgenda, date: number): EventoAgendaLotacao => ({
  id: fechamento.id,
  tipo: TipoAgendamento.FECHAMENTO,
  title: `Agenda fechada [${fechamento.especifique ?? fechamento.motivo}]`,
  start: set(date, { hours: 7, minutes: 0, seconds: 0, milliseconds: 0 }),
  end: set(date, { hours: 19, minutes: 0, seconds: 0, milliseconds: 0 }),
  dataInicioFechamento: parseISO(fechamento.dataInicial),
  dataFimFechamento: parseISO(fechamento.dataFinal),
})

export const convertReservaModelToInput = (
  model: AgendamentoReservaModel,
  lotacaoId: ID
): CriarAgendamentoReservaInput => ({
  lotacaoAgendada: lotacaoId,
  horarioInicial: Number(model.horario.inicial),
  horarioFinal: Number(model.horario.final),
  motivoReserva: model.motivoReserva,
  especificacaoMotivoReserva:
    model.motivoReserva === MotivoAgendamentoReservaEnum.OUTRO ? model.especificacaoMotivoReserva : null,
  observacoes: model.observacoes,
})

export const convertReservaModelToEditarInput = (
  model: AgendamentoReservaModel,
  agendamentoId: ID
): EditarAgendamentoReservaInput => ({
  agendamentoId,
  horarioInicial: Number(model.horario.inicial),
  horarioFinal: Number(model.horario.final),
  motivoReserva: model.motivoReserva,
  especificacaoMotivoReserva:
    model.motivoReserva === MotivoAgendamentoReservaEnum.OUTRO ? model.especificacaoMotivoReserva : null,
  observacoes: model.observacoes,
})

export const convertConsultaModelToInput = (
  model: AgendamentoConsultaModel,
  lotacaoId: ID
): CriarAgendamentoConsultaInput => ({
  lotacao: lotacaoId,
  cidadao: model.cidadao.id,
  horario: Number(model.horario.inicial),
  isForaUbs: !!model.localAtendimento,
  localAtendimento: model.localAtendimento?.id,
  observacoes: model.observacoes,
})

export const convertConsultaModelToEditarInput = (
  model: AgendamentoConsultaModel,
  agendamentoId: ID,
  enviarEmailCidadao: boolean
): EditarAgendamentoConsultaInput => ({
  agendamentoId,
  horario: Number(model.horario.inicial),
  isForaUbs: !!model.localAtendimento,
  localAtendimento: model.localAtendimento?.id,
  observacoes: model.observacoes,
  enviarEmailCidadao,
})

export const convertConsultaCompartilhadaModelToInput = (
  model: AgendamentoConsultaCompartilhadaModel,
  lotacaoId: ID,
  videochamadaUuid: string
): CriarAgendamentoConsultaCompartilhadaInput => ({
  lotacaoId: lotacaoId,
  horarioInicial: Number(model.horario.inicial),
  horarioFinal: Number(model.horario.final),
  lotacaoParticipanteId: model.lotacaoConvidada.id,
  observacoes: model.observacoes,
  cidadao: model.cidadaoParticipante?.cidadao?.id,
  videochamadaUuid: videochamadaUuid,
})

export const convertConsultaCompartilhadaModelToEditarInput = (
  model: AgendamentoConsultaCompartilhadaModel,
  agendamentoId: ID,
  videochamadaUuid: string,
  enviarEmailCidadao: boolean
): EditarAgendamentoConsultaCompartilhadaInput => ({
  agendamentoId,
  horarioInicial: Number(model.horario.inicial),
  horarioFinal: Number(model.horario.final),
  observacoes: model.observacoes,
  videochamadaUuid,
  enviarEmailCidadao,
})

export const convertConsultaModelToUpdateContatoCidadaoInput = (
  cidadaoId: ID,
  model: ContatoCidadaoAgendamento
): AtualizarContatoCidadaoInput => ({
  cidadaoId,
  telefoneCelular: model.telefoneCelular,
  email: model.email,
})

export const convertConfiguracaoAgendaOnline = (
  configs: ConfiguracaoDiaSemanaAgendaOnline[]
): Map<Weekday, Map<string, boolean>> =>
  configs?.reduce(
    (rec, config) =>
      rec.set(asWeekday[config.diaSemana], new Map((config.configuracoes ?? []).map((c) => [c.horario, c.ativo]))),
    new Map<Weekday, Map<string, boolean>>()
  )

export const convertConfiguracaoDiasSemana = (configs: ConfiguracaoDiaSemana[]): Record<Weekday, TimeInterval[]> =>
  configs?.reduce((rec, config) => {
    rec[asWeekday[config.diaSemana]] = (config.periodos ?? [])
      .filter((c) => !!c.horarioInicial)
      .map(convertConfiguracaoPeriodo)
    return rec
  }, {} as Record<Weekday, TimeInterval[]>)

export const convertLotacaoAgendamento = (lotacao: DeepPartial<Lotacao>): LotacaoAgendamentoModel =>
  lotacao
    ? {
        nome: lotacao.profissional.nome,
        email: lotacao.profissional.email,
        nomeCbo: lotacao.cbo?.nome,
        nomeEquipe: lotacao.equipe?.nome,
        ineEquipe: lotacao.equipe?.ine,
      }
    : null

export const convertToAgendamentoEdicaoModel = (
  agendado: EditarAgendadoQuery['agendado'],
  tipo: TipoAgendamento
): AgendamentoEdicaoModel =>
  agendado
    ? {
        id: agendado.id,
        horario: { inicial: new Date(agendado.horarioInicial), final: new Date(agendado.horarioFinal) },
        tipo,
        lotacao: agendado.lotacaoAgendada,
        especificacaoMotivoReserva: agendado.outroMotivoReserva,
        observacoes: agendado.observacao,
        motivoReserva: agendado.motivoReserva,
        cidadao: agendado.cidadao,
        localAtendimento: agendado.localAtendimento,
        lotacaoConvidada: agendado.agendamentoParticipante?.lotacaoAgendada,
        agendamentoParticipanteId: agendado.agendamentoParticipante?.id,
        videochamadaUuid: agendado.videochamada?.uuid,
      }
    : null

const convertConfiguracaoPeriodo = (config: ConfiguracaoPeriodo): TimeInterval => ({
  start: convertHorario(config.horarioInicial.split(SEPARADOR_HORA_MINUTO)),
  end: convertHorario(config.horarioFinal.split(SEPARADOR_HORA_MINUTO)),
})

const convertHorario = (horaMinuto: string[]): TimeOfDay =>
  horaMinuto && {
    hours: Number(horaMinuto[0]),
    minutes: Number(horaMinuto[1]),
  }

const asWeekday: Record<DiaSemanaEnum, Weekday> = {
  DOMINGO: Weekday.SUNDAY,
  SEGUNDA: Weekday.MONDAY,
  TERCA: Weekday.TUESDAY,
  QUARTA: Weekday.WEDNESDAY,
  QUINTA: Weekday.THRUSDAY,
  SEXTA: Weekday.FRIDAY,
  SABADO: Weekday.SATURDAY,
}

const getTelefoneCidadao = (contato: ContatoCidadao) =>
  contato ? contato.telefoneCelular || contato.telefoneResidencial || contato.telefoneContato : undefined
