import { css } from '@emotion/core'
import { Button, Icon, VFlow } from 'bold-ui'
import { resolveName } from 'components/form/final-form/hooks/useField'
import { useArquivosAnexadosNoProntuarioQuery } from 'graphql/hooks.generated'
import { useAtendimentoContext } from 'hooks/atendimento-context/useAtendimentoContext'
import { useConfiguracoes } from 'hooks/configuracoes'
import React from 'react'
import { FieldArray, FieldArrayRenderProps } from 'react-final-form-arrays'
import { Route, useHistory, useRouteMatch } from 'react-router-dom'
import { MetaArray } from 'util/metaPath'

import { AnexoArquivosModal } from './components/AnexoArquivosModal'
import { AnexoArquivoTable } from './components/table/AnexoArquivosTable'
import { AnexoArquivoFormModel } from './model-anexoArquivos'

interface AnexoArquivosViewProps {
  name: MetaArray<AnexoArquivoFormModel>
  isAtendimentoProcedimentos: boolean
}

const ANEXO_ARQUIVOS_MODAL_URL = '/anexo-arquivo'

export function AnexoArquivosView(props: AnexoArquivosViewProps) {
  const { name, isAtendimentoProcedimentos } = props

  const history = useHistory()
  const match = useRouteMatch()

  const openModal = () => {
    history.push(`${match.path}${ANEXO_ARQUIVOS_MODAL_URL}`)
  }

  const closeModal = () => {
    history.push(match.url.replace(`${ANEXO_ARQUIVOS_MODAL_URL}`, ''))
  }

  const {
    configuracoes: { anexoArquivosEnabled },
  } = useConfiguracoes()

  const {
    atendimentoProfissional: {
      id: atendimentoProfissionalId,
      lotacao: { id: lotacaoId, profissional, cbo },
      iniciadoEm,
    },
    prontuario: { id: prontuarioId },
  } = useAtendimentoContext()

  const { data, loading } = useArquivosAnexadosNoProntuarioQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      prontuarioId,
    },
  })

  const renderFieldArray = (fieldArrayRenderProps: FieldArrayRenderProps<AnexoArquivoFormModel, any>) => {
    const {
      fields: { push, value, remove },
    } = fieldArrayRenderProps
    // TODO (Legacy) #23102: Editar

    return (
      <VFlow>
        {anexoArquivosEnabled && (
          <>
            <Button kind='primary' size='small' data-cy='AnexoArquivos.AnexarArquivo' onClick={openModal}>
              <Icon
                icon='upload'
                style={css`
                  margin-right: 0.5rem;
                `}
              />
              Anexar arquivo
            </Button>
            <Route
              path={`${match.path}${ANEXO_ARQUIVOS_MODAL_URL}`}
              render={() => (
                <AnexoArquivosModal
                  onClose={closeModal}
                  onConfirm={push}
                  isAtendimentoProcedimentos={isAtendimentoProcedimentos}
                />
              )}
            />
          </>
        )}
        {(anexoArquivosEnabled || !data.arquivosAnexadosNoProntuario?.isEmpty()) && (
          <AnexoArquivoTable
            atendimentoProfissionalId={atendimentoProfissionalId}
            dataAtendimento={iniciadoEm.getTime()}
            lotacaoId={lotacaoId}
            profissional={profissional}
            cbo={cbo}
            arquivosAnexados={value}
            anexoArquivosLoading={loading}
            arquivosAnexadosDatabase={data.arquivosAnexadosNoProntuario}
            onRemove={remove}
          />
        )}
      </VFlow>
    )
  }

  return <FieldArray<AnexoArquivoFormModel> name={resolveName(name)} render={renderFieldArray} />
}
