import api from 'api'
import { TipoExameEnum } from 'graphql/types.generated'
import { defaultHandleError } from 'util/downloadFile'
import { previewFile } from 'util/previewFile'

export interface SolicitacaoExamesProcedimento {
  idExame: ID
  observacoes?: string
}

export interface SolicitacaoExameReport {
  examesRequisitados: SolicitacaoExamesProcedimento[]
  justificativa: string
  observacoes?: string
  cid10?: ID
  tipoExame: TipoExameEnum
}

export interface ImpressaoSolicitacaoExamesInput {
  id?: ID
  idAtendimentoProfissional: ID
  prontuarioId: ID
  atendimentoId?: ID
  idCidadao: ID
  solicitacao?: SolicitacaoExameReport
}

export const downloadSolicitacaoExames = async (value: ImpressaoSolicitacaoExamesInput[], onSuccess?: () => void) => {
  return await api.solicitacaoExames
    .imprimirSolicitacaoExames({ solicitacoes: value })
    .then((response) => {
      onSuccess?.()
      previewFile(new Blob([response.data], { type: 'application/pdf' }), 'Imprimir solicitação de exames')
      return response
    })
    .catch(defaultHandleError)
}
