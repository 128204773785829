import { ModalProps } from 'bold-ui'
import { useRetificacaoAtendimentoModalQuery } from 'graphql/hooks.generated'
import React, { Fragment, useState } from 'react'
import { useHistory } from 'react-router'

import { JustificativaRetificacaoAtendimentoModal } from './components/JustificativaRetificacaoAtendimentoModal'
import { RemoveRegistroConfirmModal } from './components/RemoveRegistroConfirmModal'
import { RetificacaoAtendimentoModel, RetificacaoAtendimentoOpcaoEnum } from './model-retificacaoAtendimento'

interface RetificacaoAtendimentoModalProps extends Omit<ModalProps, 'onSubmit'> {
  cidadaoId: ID
  dataAtendimento: Instant
  atendProfId: ID
  open: boolean
  onClose(): void
  onRemoveSuccess?(): void
  nomeCidadao: string
}

export function RetificacaoAtendimentoModal(props: RetificacaoAtendimentoModalProps) {
  const { cidadaoId, dataAtendimento, atendProfId, open, onClose, onRemoveSuccess } = props
  const history = useHistory()

  const [modalRemoveRegistroOpen, setModalRemoveRegistroOpen] = useState(false)
  const [justificativa, setJustificativa] = useState(null)
  const {
    data: { atendimentoIndividual },
    loading: loadingAtendimentoId,
  } = useRetificacaoAtendimentoModalQuery({
    skip: !atendProfId,
    variables: { atendimentoProfissionalId: atendProfId },
  })
  const { id: atendimentoId, isRegistroTardio } = atendimentoIndividual?.atendimento || {}

  const handleModalRemoveRegistroOpen = () => setModalRemoveRegistroOpen(true)

  const handleModalRemoveRegistroClose = () => setModalRemoveRegistroOpen(false)

  const handleModalRemoveRegistroSubmit = () => {
    handleModalRemoveRegistroClose()
    onClose()
    onRemoveSuccess?.()
  }

  const handleRetificacaoAtendimentoModalSubmit = (values: RetificacaoAtendimentoModel) => {
    if (values.tipo === RetificacaoAtendimentoOpcaoEnum.REMOVER_REGISTRO) {
      setJustificativa(values.justificativa)
      handleModalRemoveRegistroOpen()
    } else {
      const baseRoute = isRegistroTardio ? '/registroTardio' : '/lista-atendimento/atendimento'
      history.push(`${baseRoute}/${atendimentoId}/retificar`)
    }
  }

  return (
    <Fragment>
      <RemoveRegistroConfirmModal
        open={modalRemoveRegistroOpen}
        atendimentoProfissionalId={atendProfId}
        justificativa={justificativa}
        onCancelar={handleModalRemoveRegistroClose}
        onConfirmar={handleModalRemoveRegistroSubmit}
        nomeCidadao={props.nomeCidadao.titleCase()}
      />
      <JustificativaRetificacaoAtendimentoModal
        cidadaoId={cidadaoId}
        dataAtendimento={dataAtendimento}
        open={open}
        onCancelar={onClose}
        onSubmit={handleRetificacaoAtendimentoModalSubmit}
        loading={loadingAtendimentoId}
      />
    </Fragment>
  )
}
