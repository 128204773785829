import { useAcessoLotacaoOrEstagio } from 'components/auth/useAcessoLotacao'
import { useLocalStorage } from 'hooks/useStorage'
import { useRouteMatch } from 'react-router'

import { AtividadeColetivaFormModel } from '../model-atividadeColetiva'

export const useAtividadeColetivaCache = () => {
  const { acesso } = useAcessoLotacaoOrEstagio()
  const match = useRouteMatch()

  const [getCachedValue, setCachedValue, clearCachedValue] = useLocalStorage<AtividadeColetivaFormModel>(
    `${acesso.id}/${match.url}`
  )

  return { cachedValue: getCachedValue(), setCachedValue, clearCachedValue }
}
