import { HFlow, Icon, Text, Tooltip } from 'bold-ui'
import { blue } from 'bold-ui/lib/styles/colors'
import { DateTime } from 'components/date'
import { parseISO } from 'date-fns'
import { isNil } from 'lodash'
import React from 'react'
import { calculateIdadeGestacional } from 'util/atendimento/gestante/calculateIdadeGestacional'
import { formatNumber } from 'util/number'

import { HistoricoPreNatalModel } from '../../types/model-historicoPreNatal'

export function renderNumeroConsulta(valor: number) {
  return <Text>{valor}ª</Text>
}

export function renderDataMedicao(valor: LocalDate) {
  return <Text>{valor ? <DateTime value={valor} format={`DD/MM/YYYY`}></DateTime> : '-'}</Text>
}

export function renderIdadeGestacional(dataInicioGestacao: Date, dataConsulta: LocalDate) {
  const {
    emSemanas: { semanas, dias },
    hasIdadeGestacional,
  } = calculateIdadeGestacional(dataInicioGestacao, parseISO(dataConsulta))
  return <Text>{hasIdadeGestacional ? `${semanas}s e ${dias}d` : '-'}</Text>
}

export function renderEdema(valor: string) {
  return <Text>{valor ? `(${valor})` : '-'}</Text>
}

export function renderMovimentacaoFetal(valor: boolean, isRegistradoAgora: boolean) {
  return (
    <HFlow alignItems='center' justifyContent='space-between'>
      <Text>{isNil(valor) ? '-' : `${valor ? 'Sim' : 'Não'}`}</Text>
      {isRegistradoAgora && (
        <Tooltip text='Registrado agora'>
          <Icon color={blue.c40} size={1} icon='clockOutline' />
        </Tooltip>
      )}
    </HFlow>
  )
}

export function renderMedicaoPreNatal(valor: string | number) {
  return valor ? formatNumber(valor) : '-'
}

export function renderLabelIG(dataConverted: HistoricoPreNatalModel) {
  return (
    <HFlow alignItems='center' justifyContent='space-between' hSpacing={0.3}>
      <Text>{dataConverted?.isDataInicioGestacaoEcografica ? 'IG eco.' : 'IG cron.'}</Text>
      {dataConverted?.hasExamesPreNatalAtendimento && (
        <Tooltip text='Registrado agora'>
          <Icon color={blue.c40} size={1} icon='clockOutline' />
        </Tooltip>
      )}
    </HFlow>
  )
}
export function renderOutros(valor: string) {
  return <Text>{valor ? formatNumber(valor) : '-'}</Text>
}
