/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Tabs, Theme, Tooltip, useTheme } from 'bold-ui'
import CheckPermission from 'components/auth/CheckPermission'
import { useAcessoLotacaoOrEstagio } from 'components/auth/useAcessoLotacao'
import { PageContainer } from 'components/layout/PageContainer'
import { PageContent } from 'components/layout/PageContent'
import { PageLoading } from 'components/loading'
import { PecSwitch, PrivateRoute, TabLink } from 'components/route'
import { useFlags } from 'config/useFlagsContext'
import { useIdadeGestacionalQuery } from 'graphql/hooks.generated'
import { Lotacao } from 'graphql/types.generated'
import { useFirebase } from 'hooks/firebase/useFirebase'
import { useServerTime } from 'hooks/useServerTime'
import { Fragment, useMemo } from 'react'
import { Redirect, useLocation } from 'react-router'
import { useRouteMatch } from 'react-router-dom'
import Permissions from 'types/Permissions'
import { emptyArray } from 'util/array'
import { calculateAge } from 'util/date/calculateAge'
import { dateAsYyyyMmDd } from 'util/date/formatDate'
import { isEmpty } from 'util/validation/Util'
import { AgendamentosCidadaoView } from 'view/agenda/cidadao/AgendamentosCidadaoView'
import { FolhaRostoView } from 'view/atendimentos/detail/folha-rosto/FolhaRostoView'
import { CidadaoFolhaRosto } from 'view/atendimentos/detail/folha-rosto/model'
import EncaminhamentoExternoTable from 'view/atendimentos/detail/soap/plano/encaminhamento-externo/list/EncaminhamentoExternoTable'
import { grupoCboAcompanhamentoPreNatal } from 'view/atendimentos/detail/soap/pre-natal/acessos'
import { VacinacaoReadOnlyView } from 'view/atendimentos/detail/vacinacao/VacinacaoView'
import { LotacaoAtendimento } from 'view/atendimentos/types/AtendimentoProfissionalModel'

import { HistoricoAtendimentoView } from '../../atendimentos/detail/historico/HistoricoAtendimentoView'
import {
  grupoCboVisualizarApenasEncaminhamentos,
  grupoCboVisualizarProntuario,
  grupoCboVisualizarVacinacao,
} from '../acessos'
import {
  CidadaoTabSectionModel,
  visualizarAgendaAndProntuarioPermissions,
  visualizarCidadaoAndProntuarioPermissions,
} from '../types/model-cidadaoTabSection'
import { CidadaoFciInformacoesView } from '../visualizacaofci/CidadaoFciInformacoesView'
import { CidadaoAldeadoInformacoesView } from './CidadaoAldeadoInformacoesView'
import { CidadaoInformacoesView } from './CidadaoInformacoesView'
import { CidadaoUnificacoesView } from './CidadaoUnificacoesView'

export interface CidadaoTabSectionProps {
  cidadao: CidadaoTabSectionModel
}

export const CidadaoTabSection = (props: CidadaoTabSectionProps) => {
  const { cidadao } = props
  const { analytics } = useFirebase()
  const match = useRouteMatch()
  const location = useLocation()
  const theme = useTheme()
  const { CIDADAO_FCI_ENABLED } = useFlags()
  const classes = createStyles(theme)

  const { acesso, profissional, hasCboAuth } = useAcessoLotacaoOrEstagio({ fetchPolicy: 'cache-only' })

  const { getServerTimeNow } = useServerTime()

  const now = getServerTimeNow()
  const dataReferencia = dateAsYyyyMmDd(now)

  const idadeEmAnos = cidadao?.dataNascimento && calculateAge(cidadao.dataNascimento, now.valueOf()).years

  const cidadaoFolhaRosto: CidadaoFolhaRosto = { ...cidadao, idadeEmAnos }

  const lotacao: LotacaoAtendimento = useMemo(() => ({ ...(acesso as Lotacao), profissional }), [acesso, profissional])

  const isGestante = !isEmpty(cidadao.prontuario?.preNatalAtivo)
  const hasProntuario = !!cidadao.prontuario
  const tooltip = !hasProntuario && 'Cidadão não possui prontuário'
  const prontuarioId = cidadao?.prontuario?.id
  const preNatalAtivo = cidadao?.prontuario?.preNatalAtivo
  const ultimaDumPreNatalAtivo = preNatalAtivo?.ultimaDum

  const { loading: loadingIdadeGestacional, data: dataIdadeGestacional } = useIdadeGestacionalQuery({
    variables: { input: { prontuarioId, dataAtendimento: dataReferencia, dum: ultimaDumPreNatalAtivo } },
    skip: !prontuarioId || !ultimaDumPreNatalAtivo || !hasCboAuth(grupoCboAcompanhamentoPreNatal),
  })
  const idadeGestacional = dataIdadeGestacional?.idadeGestacional

  if (loadingIdadeGestacional) {
    return <PageLoading message='Carregando atendimento...' />
  }

  const renderInformacoes = () => (
    <PageContent fluid type='filled'>
      {cidadao?.cidadaoAldeado ? (
        <CidadaoAldeadoInformacoesView cidadao={cidadao} mostrarProntuario />
      ) : (
        <CidadaoInformacoesView cidadao={cidadao} mostrarProntuario />
      )}
    </PageContent>
  )

  const renderNovasInformacoes = () => (
    <PageContent fluid type='filled'>
      <CidadaoFciInformacoesView cidadaoId={cidadao.id} />
    </PageContent>
  )

  const renderUnificacoes = () => (
    <PageContent fluid type='filled'>
      <CidadaoUnificacoesView idCidadao={cidadao.id} temUnificacoes={cidadao.unificado} />
    </PageContent>
  )

  const renderFolhaRosto = () => (
    <FolhaRostoView
      prontuarioId={prontuarioId}
      cidadao={cidadaoFolhaRosto}
      lotacao={lotacao}
      isGestante={isGestante}
      gestacoes={cidadao.prontuario?.gestacoes || emptyArray}
      idadeGestacional={idadeGestacional}
    />
  )

  const renderEncaminhamentos = () => (
    <PageContent fluid type='filled'>
      <EncaminhamentoExternoTable
        prontuarioId={cidadao.prontuario.id}
        dataReferencia={Number(dataReferencia)}
        lotacao={lotacao}
        readOnly
      />
    </PageContent>
  )

  const renderHistorico = () => (
    <HistoricoAtendimentoView
      prontuarioId={prontuarioId}
      cidadao={cidadao}
      unidadeSaudeCnes={acesso?.unidadeSaude.cnes}
      isAtendimentoObservacao={false}
      isObservacaoAndResponsavel={false}
    />
  )

  const renderAgendamentos = () => (
    <PageContent fluid type='filled'>
      <AgendamentosCidadaoView />
    </PageContent>
  )
  const renderVacinacao = () => <VacinacaoReadOnlyView dataReferencia={Number(getServerTimeNow())} cidadao={cidadao} />

  return (
    <Fragment>
      <div css={classes.container}>
        <PageContainer fluid>
          <Tabs style={classes.tabs}>
            <TabLink to={`${match.url}/informacoes${location.search}`}>Informações</TabLink>
            {CIDADAO_FCI_ENABLED && (
              <TabLink to={`${match.url}/novasinformacoes${location.search}`}>FCI Informações</TabLink>
            )}
            <CheckPermission
              hasAnyCbo={[grupoCboVisualizarProntuario]}
              hasAnyPermission={[Permissions.visualizarProntuario]}
            >
              <Tooltip text={tooltip}>
                <TabLink to={`${match.url}/folha-rosto${location.search}`} disabled={!hasProntuario}>
                  Folha de rosto
                </TabLink>
              </Tooltip>
            </CheckPermission>

            <CheckPermission
              hasAnyCbo={[grupoCboVisualizarApenasEncaminhamentos]}
              hasAnyPermission={[Permissions.visualizarProntuario]}
            >
              <Tooltip text={tooltip}>
                <TabLink to={`${match.url}/encaminhamentos${location.search}`} disabled={!hasProntuario}>
                  Encaminhamentos
                </TabLink>
              </Tooltip>
            </CheckPermission>

            <CheckPermission
              hasAnyCbo={[grupoCboVisualizarProntuario]}
              hasAnyPermission={[Permissions.visualizarProntuario]}
            >
              <Tooltip text={tooltip}>
                <TabLink
                  to={`${match.url}/historico${location.search}`}
                  onClick={() => analytics.logEvent('click_tab_cidadao_historico')}
                  disabled={!hasProntuario}
                >
                  Histórico
                </TabLink>
              </Tooltip>
            </CheckPermission>

            <CheckPermission
              hasAnyCbo={[grupoCboVisualizarVacinacao]}
              hasAnyPermission={[Permissions.visualizarProntuario]}
            >
              <Tooltip text={tooltip}>
                <TabLink to={`${match.url}/vacinacao${location.search}`} disabled={!hasProntuario}>
                  Vacinação
                </TabLink>
              </Tooltip>
            </CheckPermission>

            <TabLink to={`${match.url}/unificacoes${location.search}`}>Unificações</TabLink>
            <CheckPermission permission={visualizarAgendaAndProntuarioPermissions}>
              <TabLink to={`${match.url}/agendamentos${location.search}`}>Agendamentos</TabLink>
            </CheckPermission>
          </Tabs>
        </PageContainer>
      </div>

      <PecSwitch>
        {<Redirect exact path={`${match.url}`} to={`${match.url}/informacoes${location.search}`} />}
        {hasProntuario && (
          <PrivateRoute
            path={`${match.url}/folha-rosto`}
            render={renderFolhaRosto}
            permission={Permissions.visualizarProntuario}
            cbos={grupoCboVisualizarProntuario}
          />
        )}
        {hasProntuario && (
          <PrivateRoute
            path={`${match.url}/encaminhamentos`}
            render={renderEncaminhamentos}
            permission={Permissions.visualizarProntuario}
            cbos={grupoCboVisualizarApenasEncaminhamentos}
          />
        )}
        {hasProntuario && (
          <PrivateRoute
            path={`${match.url}/encaminhamentos`}
            render={renderEncaminhamentos}
            permission={Permissions.visualizarProntuario}
            cbos={grupoCboVisualizarApenasEncaminhamentos}
          />
        )}
        {hasProntuario && (
          <PrivateRoute
            exact
            path={`${match.url}/historico`}
            render={renderHistorico}
            permission={grupoCboVisualizarProntuario}
            cbos={grupoCboVisualizarProntuario}
          />
        )}
        {hasProntuario && (
          <PrivateRoute
            exact
            path={`${match.path}/vacinacao`}
            render={renderVacinacao}
            permission={Permissions.visualizarProntuario}
            cbos={grupoCboVisualizarVacinacao}
          />
        )}
        <PrivateRoute
          exact
          path={`${match.url}/informacoes`}
          render={renderInformacoes}
          permission={visualizarCidadaoAndProntuarioPermissions}
        />
        <PrivateRoute
          path={`${match.url}/informacoes${location.search}`}
          permission={visualizarCidadaoAndProntuarioPermissions}
        />
        {CIDADAO_FCI_ENABLED && (
          <PrivateRoute
            exact
            path={`${match.url}/novasinformacoes`}
            render={renderNovasInformacoes}
            permission={visualizarCidadaoAndProntuarioPermissions}
          />
        )}
        <PrivateRoute
          exact
          path={`${match.url}/unificacoes`}
          render={renderUnificacoes}
          permission={visualizarCidadaoAndProntuarioPermissions}
        />
        <PrivateRoute
          exact
          path={`${match.path}/agendamentos`}
          render={renderAgendamentos}
          permission={visualizarAgendaAndProntuarioPermissions}
        />
      </PecSwitch>
    </Fragment>
  )
}

const createStyles = (theme: Theme) => ({
  container: css`
    border-bottom: 1px solid ${theme.pallete.divider};
  `,
  tabs: css`
    margin-top: 0.5rem;
    border-bottom-width: 0;
  `,
})
