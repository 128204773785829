/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Text, Theme, useTheme } from 'bold-ui'
import { FieldStatusEnum } from 'components/form/final-form/components/FieldStatus/model-fieldStatus'
import React from 'react'

interface TextStatusProps {
  status?: FieldStatusEnum
  children: React.ReactNode
}

export const TextStatus = (props: TextStatusProps) => {
  const { status, children } = props

  const theme = useTheme()
  const styles = createStyles(theme)

  return <Text style={status === FieldStatusEnum.REMOVIDO && styles.textRemovido}>{children}</Text>
}

const createStyles = (theme: Theme) => ({
  textRemovido: css`
    text-decoration: line-through;
    color: ${theme.pallete.gray.c40};
  `,
})
