/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Cell, Grid, HFlow, Icon } from 'bold-ui'
import { Form, FormDebouncedValueSpy, SwitchField, TextField } from 'components/form'
import { PopperButton, PopperControls } from 'components/popper'
import { FormState } from 'final-form'
import { useFilterCounter } from 'hooks/useFilterCounter'
import { Dispatch, SetStateAction } from 'react'
import { metaPath } from 'util/metaPath'
import { AtividadeColetivaFilterFormModel } from 'view/atividade-coletiva/model-atividadeColetiva'

import { AtividadeColetivaFilterPopperForm } from './AtividadeColetivaFilterPopperForm'
import { AtividadeColetivaSortDropdown } from './AtividadeColetivaSortDropdown'

const path = metaPath<AtividadeColetivaFilterFormModel>()

interface AtividadeColetivaFilterFormProps {
  filter: AtividadeColetivaFilterFormModel
  onChangeFilter: Dispatch<SetStateAction<AtividadeColetivaFilterFormModel>>
}

export const AtividadeColetivaFilterForm = (props: AtividadeColetivaFilterFormProps) => {
  const { filter, onChangeFilter } = props
  const countAppliedFilter = useFilterCounter(filter, {
    isReuniao: false,
    isSomenteRegistrosProprios: false,
    nomeCnsCpfProfissionalResponsavel: false,
    pageParams: false,
  })

  const renderPopper = (controls: PopperControls) => (
    <AtividadeColetivaFilterPopperForm filter={filter} onChangeFilter={onChangeFilter} onClose={controls.close} />
  )

  const handleChange = (formState: FormState<AtividadeColetivaFilterFormModel>) => {
    onChangeFilter({ ...formState.values })
  }

  const renderForm = () => {
    return (
      <Grid gapVertical={0.1}>
        <FormDebouncedValueSpy onChange={handleChange} />

        <Cell size={4}>
          <TextField
            name={path.nomeCnsCpfProfissionalResponsavel}
            placeholder='Pesquise por nome, CPF ou CNS do responsável'
            icon='zoomOutline'
            style={styles.ellipsis}
          />
        </Cell>

        <Cell size={3}>
          <SwitchField
            style={styles.switch}
            name={path.isSomenteRegistrosProprios}
            label='Ver somente os meus registros'
          />
        </Cell>

        <Cell size={5}>
          <HFlow justifyContent='flex-end'>
            <AtividadeColetivaSortDropdown filter={filter} onChangeFilter={onChangeFilter} />
            <PopperButton
              kind='primary'
              size='small'
              skin='default'
              placement='bottom-end'
              renderPopper={renderPopper}
              focusOnOpen={false}
            >
              <Icon icon='filterOutline' />
              {'Filtros' + (countAppliedFilter > 0 ? ` (${countAppliedFilter})` : '')}
            </PopperButton>
          </HFlow>
        </Cell>
      </Grid>
    )
  }

  return <Form<AtividadeColetivaFilterFormModel> initialValues={filter} render={renderForm} />
}

const styles = {
  ellipsis: css`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `,
  switch: css`
    padding-top: 0.25rem;
  `,
}
