import { Button, Cell, FormControl, Grid, Tooltip } from 'bold-ui'
import { Form, FormRenderProps } from 'components/form'
import {
  FichaNotificacaoCasoSuspeitoSelectField,
  FichaNotificacaoSelectModel,
} from 'components/form/field/select/FichasNotificacaoCasoSuspeitoSelectField'
import { useListFieldStatus } from 'components/form/final-form/hooks'
import { SexoEnum } from 'graphql/types.generated'
import { useAtendimentoContext } from 'hooks/atendimento-context/useAtendimentoContext'
import React, { useCallback } from 'react'
import { MetaArray, metaPath } from 'util/metaPath'
import { isEmpty } from 'util/validation/Util'
import { SoapEditableItem } from 'view/atendimentos/atendimento-individual/model'
import { EditableList, RowType, useEditableListField } from 'view/atendimentos/detail/components/EditableList'

import { downloadFichaSinan } from './downloadFichaSinan'
import { NotificacaoRow } from './NotificacaoCasoSuspeitoRow'

export interface NotificacaoCasoSuspeitoFieldModel extends SoapEditableItem, RowType {
  fichaNotificacao: FichaNotificacaoSelectModel
}

export interface CidadaoNotificacaoCasoSuspeito {
  id: ID
  dataNascimento: LocalDate
  sexo: SexoEnum
  isIdentidadeGeneroInformada: boolean
}

export interface NotificacaoCasoSuspeitoFieldProps {
  name: MetaArray<NotificacaoCasoSuspeitoFieldModel>
  dataAtendimento: Instant
  cidadaoData: CidadaoNotificacaoCasoSuspeito
  showModifiedStatus: boolean
}

const path = metaPath<NotificacaoCasoSuspeitoFieldModel>()

export function NotificacaoCasoSuspeitoField(props: NotificacaoCasoSuspeitoFieldProps) {
  const { name, cidadaoData, dataAtendimento, showModifiedStatus, ...rest } = props

  const {
    handleSubmit,
    removeItem,
    resetItemToInitialValue,
    input: { value },
  } = useEditableListField({
    name,
  })

  const { id: atendimentoId } = useAtendimentoContext()

  const { fieldStatus, disabledValues: notificacoesDisabled } = useListFieldStatus({
    name,
    showModifiedStatus,
  })

  const fichaIsEqual = (ficha: FichaNotificacaoSelectModel) => {
    return (value || []).find((o2) => ficha.id === o2.fichaNotificacao.id)
  }

  const itemIsDisabled = useCallback(
    (item: FichaNotificacaoSelectModel) =>
      notificacoesDisabled?.map((itemDisabled) => itemDisabled?.fichaNotificacao.id).includes(item.id),
    [notificacoesDisabled]
  )

  const printItem = (itemToPrint: NotificacaoCasoSuspeitoFieldModel) => {
    downloadFichaSinan({
      idNotificacao: itemToPrint.fichaNotificacao.id,
      cidadaoId: cidadaoData.id,
      dataAtendimento: dataAtendimento,
      atendimentoId: atendimentoId,
    })
  }

  const renderForm = (props: FormRenderProps<NotificacaoCasoSuspeitoFieldModel>) => {
    const handleImprimirClick = () => {
      printItem(props.values)
      return props.form.submit()
    }

    return (
      <Grid>
        <Cell size={8}>
          <FichaNotificacaoCasoSuspeitoSelectField
            name={path.fichaNotificacao}
            label='Ficha de notificação de caso suspeito'
            dataAtendimento={dataAtendimento}
            cidadaoData={cidadaoData}
            itemIsEqual={fichaIsEqual}
            itemIsDisabled={!isEmpty(notificacoesDisabled) ? itemIsDisabled : undefined}
            {...rest}
          />
        </Cell>

        <Cell>
          <FormControl label={<span>&nbsp;</span>}>
            <Tooltip text={!props.values.fichaNotificacao && 'Selecione uma ficha.'} placement='auto'>
              <Button
                size='small'
                kind='primary'
                onClick={handleImprimirClick}
                disabled={!props.values.fichaNotificacao}
              >
                Imprimir
              </Button>
            </Tooltip>
          </FormControl>
        </Cell>
      </Grid>
    )
  }

  return (
    <Grid gapVertical={0.5}>
      <Cell size={12}>
        <Form<NotificacaoCasoSuspeitoFieldModel> render={renderForm} onSubmit={handleSubmit} {...props} />
      </Cell>

      {!showModifiedStatus && value?.length > 0 && (
        <Cell size={12}>
          <EditableList>
            {value.map((item) => (
              <NotificacaoRow key={item._id} model={item} onRemove={removeItem} onPrint={printItem} />
            ))}
          </EditableList>
        </Cell>
      )}

      {showModifiedStatus && fieldStatus.size > 0 && (
        <Cell size={12}>
          <EditableList>
            {Array.from(fieldStatus, ([item, status]) => (
              <NotificacaoRow
                key={item._id}
                model={item}
                onRemove={removeItem}
                onPrint={printItem}
                onReset={resetItemToInitialValue}
                status={status}
                showModifiedStatus
              />
            ))}
          </EditableList>
        </Cell>
      )}
    </Grid>
  )
}
