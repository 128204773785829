import { Cell, Grid, HeadingSection, InfoLabel, Text } from 'bold-ui'
import { booleanToSimNaoEnum, simNaoEnumRecord } from 'components/form'
import { Cbo, Escolaridade, InformacoesSociodemograficas } from 'graphql/types.generated'
import React from 'react'
import {
  criancas0a9anosComQuemFicaRecord,
  deficienciaRecord,
  povoComunidadeTradicionalRecord,
  situacaoMercadoTrabalhoRecord,
} from 'view/cidadao/createfci/model-cidadaoFci'
import { formatSimNaoJoinRespostas } from 'view/cidadao/util-cidadao'

interface CidadaoFciInformacoesSociodemograficasSectionProps {
  informacoesSociodemograficas: InformacoesSociodemograficas
  escolaridade: Escolaridade
  cbo: Cbo
}

//TODO (@NP): Poderá ser removido depois que os models nao persistam mais no banco quando vazios
const hasRegisteredInformation = (informacoesSociodemograficas: any) => {
  return (
    informacoesSociodemograficas != null &&
    Object.entries(informacoesSociodemograficas).some(
      ([key, value]) =>
        key !== 'id' && key !== '__typename' && value !== null && (!Array.isArray(value) || value.length > 0)
    )
  )
}

export const CidadaoFciInformacoesSociodemograficasSection = (
  props: CidadaoFciInformacoesSociodemograficasSectionProps
) => {
  const { informacoesSociodemograficas, escolaridade, cbo } = props

  if (!hasRegisteredInformation(informacoesSociodemograficas))
    return (
      <HeadingSection level={2} title='Informações sociodemográficas'>
        <Text fontStyle='italic'>Sem informações registradas</Text>
      </HeadingSection>
    )

  return (
    <HeadingSection level={2} title='Informações sociodemográficas'>
      <Grid wrap>
        <Cell size={4}>
          <InfoLabel title='Escolaridade'>{escolaridade?.nome}</InfoLabel>
        </Cell>
        <Cell size={4}>
          <InfoLabel title='Frequenta escola ou creche'>
            {simNaoEnumRecord[booleanToSimNaoEnum(informacoesSociodemograficas?.frequentaEscolaOuCreche)]}
          </InfoLabel>
        </Cell>
        <Cell size={4}>
          <InfoLabel title='Com quem fica a criança (Menores de 9 anos)'>
            {informacoesSociodemograficas?.criancas0a9anosComQuemFica
              ?.map((item) => criancas0a9anosComQuemFicaRecord[item])
              .sort()
              .join(', ')}
          </InfoLabel>
        </Cell>
        <Cell size={4}>
          <InfoLabel title='Situação no mercado de trabalho'>
            {situacaoMercadoTrabalhoRecord[informacoesSociodemograficas?.situacaoMercadoTrabalho]}
          </InfoLabel>
        </Cell>
        <Cell size={4}>
          <InfoLabel title='Ocupação'>{cbo?.nome.titleCase()}</InfoLabel>
        </Cell>
        <Cell size={4}>
          <InfoLabel title='Plano de saúde privado'>
            {simNaoEnumRecord[booleanToSimNaoEnum(informacoesSociodemograficas?.possuiPlanoSaudePrivado)]}
          </InfoLabel>
        </Cell>
        <Cell size={4}>
          <InfoLabel title='Frequenta cuidador tradicional'>
            {simNaoEnumRecord[booleanToSimNaoEnum(informacoesSociodemograficas?.frequentaCuidadorTradicional)]}
          </InfoLabel>
        </Cell>
        <Cell size={4}>
          <InfoLabel title='Participa de grupo comunitário'>
            {simNaoEnumRecord[booleanToSimNaoEnum(informacoesSociodemograficas?.participaAlgumGrupoComunitario)]}
          </InfoLabel>
        </Cell>
        <Cell size={4}>
          <InfoLabel title='Membro de Povo ou Comunidade Tradicional ou Campo, Floresta e Água'>
            {formatSimNaoJoinRespostas(
              informacoesSociodemograficas?.isMembroComunidadeTradicional,
              povoComunidadeTradicionalRecord[informacoesSociodemograficas?.povoComunidadeTradicional]
            )}
          </InfoLabel>
        </Cell>
        <Cell size={4}>
          <InfoLabel title='Possui alguma deficiência'>
            {formatSimNaoJoinRespostas(
              informacoesSociodemograficas?.possuiDeficiencia,
              informacoesSociodemograficas?.deficiencias.map((item) => deficienciaRecord[item])
            )}
          </InfoLabel>
        </Cell>
      </Grid>
    </HeadingSection>
  )
}
