import { Alert } from 'bold-ui'
import { Chart, ChartBody, ChartContainer, ChartFooter } from 'bold-ui/lib/components/Chart'
import { gray } from 'bold-ui/lib/styles/colors'
import { differenceInDays } from 'date-fns'
import { groupBy, round } from 'lodash'
import React, { Fragment } from 'react'
import { calcularIMC } from 'util/atendimento'
import { isUndefinedOrNull } from 'util/checks'
import { dateAsDdMmYyyy, dateAsYyyyMmDd, toDate } from 'util/date/formatDate'

import { HistoricoMedicoesPreNatalTabRoutesProps } from '../../../pre-natal/HistoricoMedicoesPreNatalTabRoutes'
import { MedicaoAntropometricaPreNatal } from '../../../pre-natal/model-medicoesPreNatal'
import { computeGanhoPesoGestacional } from '../../../pre-natal/util-medicoesPreNatal'
import { reduceMedicoesIguais } from '../../../util-medicoes'
import { LinhaTooltipGrafico } from '../../components/LinhaTooltipGrafico'
import { MedicoesReferenciaGanhoPeso } from './MedicoesReferenciaGanhoPeso'
import {
  categoriaImcReferenciaRecord,
  GANHO_PESO_EIXO_X,
  GANHO_PESO_EIXO_Y,
  GANHO_PESO_RANGE_AREAS,
} from './model-graficoGanhoPeso'
import { TagIMCReferencia } from './TagIMCReferencia'
import { TickSemanaGestacional } from './TickSemanaGestacional'
import { getCategoriaImcReferencia } from './util-graficoGanhoPeso'

interface GraficoGanhoPesoViewProps
  extends Pick<
    HistoricoMedicoesPreNatalTabRoutesProps,
    'medicaoForImcReferencia' | 'medicoesPeso10SemanasAposInicioGestacao'
  > {
  dataInicioGestacao: Date
}

function generateSeriesData(
  medicoesPeso10SemanasAposInicioGestacao: MedicaoAntropometricaPreNatal[],
  dataInicioGestacao: Date,
  pesoReferencia: number
) {
  return medicoesPeso10SemanasAposInicioGestacao.map((medicao) => {
    const dataMedicaoAsDate = toDate(medicao.dataMedicao)
    const diffInDays = differenceInDays(dataMedicaoAsDate, dataInicioGestacao)
    const ganhoPesoGestacional = computeGanhoPesoGestacional(pesoReferencia, medicao.peso)
    return {
      x: round(diffInDays / 7, 2),
      y: round(ganhoPesoGestacional, 2),
      dataMedicao: dataMedicaoAsDate,
    }
  })
}

export function GraficoGanhoPesoView(props: GraficoGanhoPesoViewProps) {
  const { medicaoForImcReferencia, medicoesPeso10SemanasAposInicioGestacao, dataInicioGestacao } = props

  const hasMedicaoForImcReferencia =
    !isUndefinedOrNull(medicaoForImcReferencia?.peso) && !isUndefinedOrNull(medicaoForImcReferencia?.altura)

  if (!hasMedicaoForImcReferencia)
    return (
      <Alert type='info'>
        É preciso ter um <b>peso e altura registrado até dois meses antes da gestação ou até a 8ª semana de gestação</b>{' '}
        para visualizar este gráfico. O registro pode ser feito na aba de antropometria.
      </Alert>
    )

  const imcReferencia = calcularIMC(medicaoForImcReferencia.peso, medicaoForImcReferencia.altura)
  const categoriaImcReferencia = getCategoriaImcReferencia(imcReferencia)

  const medicoesUnicasPesoGestacional = medicoesPeso10SemanasAposInicioGestacao
    ? reduceMedicoesIguais(medicoesPeso10SemanasAposInicioGestacao, 'peso')
    : []

  const medicoesAgrupadasPorDia = groupBy(medicoesUnicasPesoGestacional, (medicao) =>
    dateAsYyyyMmDd(new Date(medicao.dataMedicao))
  )

  const medicoesUnicasPorDiaPesoGestacional = Object.values(medicoesAgrupadasPorDia).map(
    (medicoesPorDia) => medicoesPorDia[0]
  )

  const seriesData = generateSeriesData(
    medicoesUnicasPorDiaPesoGestacional,
    dataInicioGestacao,
    medicaoForImcReferencia.peso
  )

  return (
    <>
      <Alert type='info'>
        Para o cálculo do gráfico é priorizado o dado de peso e altura com data anterior ao início da gestação, quando
        existir.
      </Alert>
      <MedicoesReferenciaGanhoPeso medicaoReferencia={medicaoForImcReferencia} imcReferencia={imcReferencia} />
      <ChartContainer>
        <TagIMCReferencia categoriaImcReferencia={categoriaImcReferencia} />
        <ChartBody>
          <Chart<number>
            outliers='auto'
            series={[{ name: 'Ganho de peso (kg)', color: gray.c20, data: seriesData }]}
            rangeAreas={GANHO_PESO_RANGE_AREAS}
            referenceAreas={categoriaImcReferenciaRecord[categoriaImcReferencia].referenceAreas}
            xAxis={{
              title: 'Idade gestacional (Semanas)',
              domain: GANHO_PESO_EIXO_X,
              tickRenderer: (props) => <TickSemanaGestacional {...props} />,
            }}
            yAxis={{
              title: '',
              unit: '',
              domain: GANHO_PESO_EIXO_Y,
            }}
            showLegend={false}
            tooltip={{
              type: 'point',
              render: (points) => (
                <>
                  {points?.map((gridPoint, index) => {
                    const correspondingMedicao = seriesData.find(
                      (dataPoint) => dataPoint.x === gridPoint.x && dataPoint.y === gridPoint.y
                    )

                    return (
                      <Fragment key={index}>
                        <LinhaTooltipGrafico>{`Ganho de peso: ${gridPoint.y} kg`}</LinhaTooltipGrafico>
                        <LinhaTooltipGrafico>{`IG: ${Math.floor(gridPoint.x)} semanas`}</LinhaTooltipGrafico>
                        <LinhaTooltipGrafico>{`Data: ${dateAsDdMmYyyy(
                          correspondingMedicao?.dataMedicao
                        )}`}</LinhaTooltipGrafico>
                      </Fragment>
                    )
                  })}
                </>
              ),
            }}
          />
        </ChartBody>
        <ChartFooter>Fonte: Caderneta da Gestante - 2022</ChartFooter>
      </ChartContainer>
    </>
  )
}
