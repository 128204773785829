import { AxiosResponse } from 'axios'
import { usePecField } from 'components/form/final-form/hooks/useField'
import React from 'react'
import { MetaPath } from 'util/metaPath'

import { FileUploaderComponent, FileUploaderProps } from './FileUploaderComponent'

interface FileUploaderFieldProps<T> extends FileUploaderProps {
  name: MetaPath<T>
}

export function FileUploaderField<T>(props: FileUploaderFieldProps<T>) {
  const { name, ...rest } = props

  const {
    input: { onChange: updateField },
  } = usePecField({ name: name })

  const handleOnUploadSuccessful = (response: AxiosResponse<T>) => {
    updateField(response.data)
  }

  return <FileUploaderComponent onUploadSuccessful={handleOnUploadSuccessful} {...rest} />
}
