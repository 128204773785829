import api from 'api'
import { defaultHandleError } from 'util/downloadFile'
import { previewFile } from 'util/previewFile'

import { AtestadoImpressaoInput, AtestadoImpressaoWrapperModel, AtestadoRecenteImpressaoInput } from '../model-atestado'

export const downloadAtestado = (inputWrapper: AtestadoImpressaoWrapperModel, isRecente: boolean) => {
  if (isRecente) {
    const input: AtestadoRecenteImpressaoInput = {
      descricao: inputWrapper.atestadoModel.descricao,
      tipo: inputWrapper.atestadoModel.tipo,
      prontuarioId: inputWrapper.prontuarioId,
      atendimentoId: inputWrapper.atendimentoId,
      atendimentoProfissionalIniciadoEm: inputWrapper.atendimentoProfissionalIniciadoEm,
    }
    return api.atestado.imprimirAtestadoRecente(input).then(generatePdfFromResponse).catch(defaultHandleError)
  } else {
    const input: AtestadoImpressaoInput = {
      id: inputWrapper.id,
      prontuarioId: inputWrapper.prontuarioId,
      atendimentoId: inputWrapper.atendimentoId,
    }
    return api.atestado.imprimirAtestado(input).then(generatePdfFromResponse).catch(defaultHandleError)
  }
}

function generatePdfFromResponse(response) {
  previewFile(new Blob([response.data], { type: 'application/pdf' }), 'Imprimir atestado')
  return response
}
