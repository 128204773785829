import { Heading, HFlow, VFlow } from 'bold-ui'
import { HLabel } from 'components/HLabel'
import { LoadingIndicator } from 'components/loading'
import { useHistoricoCuidadoCompartilhadoDwDetalhesQuery } from 'graphql/hooks.generated'
import {
  Ciap,
  Cid10,
  DimensaoCbo,
  DimensaoCiap,
  DimensaoCid10,
  DimensaoEquipe,
  DimensaoProfissional,
  DimensaoUnidadeSaude,
  HistoricoCuidadoCompartilhadoDwDetalhesQuery,
  Lotacao,
} from 'graphql/types.generated'
import React from 'react'
import { isUndefinedOrNull } from 'util/checks'
import {
  HistoricoDwPanelProps,
  IdadeOrigemAtendimento,
  InformacoesAdministrativas,
} from 'view/atendimentos/detail/historico/model/model-historico'
import { lotacaoToInformacoesAdministrativasProfissional } from 'view/atendimentos/detail/historico/utils/utils-historico'
import { getValorInformadoOrNull, isCodigoNaoInformado } from 'view/atendimentos/detail/utils-cuidadoCompartilhado'
import {
  ClassificacaoPrioridadeCuidadoRecord,
  IdentificadorCondutaCuidadoCompartilhadoDwRecord,
  LabelPrioridadeCuidadoCompartilhadoDwRecord,
} from 'view/cuidados-compartilhados/model-cuidadoCompartilhado'
import { lotacaoCuidadoCompartilhadoDwFormatter } from 'view/cuidados-compartilhados/util-cuidadoCompartilhado'

import StatusSquare from '../../../StatusSquare'
import CiapCidLabel from '../../header/components/CiapCidLabel'
import HistoricoGapPanel from '../HistoricoGapPanel'
import HistoricoNoDetailAvailable from '../HistoricoNoDetailAvailable'
import { HistoricoCuidadoCompartilhadoConduta } from './HistoricoCuidadoCompartilhadoConduta'

interface HistoricoCuidadoCompartilhadoDwProps extends HistoricoDwPanelProps {
  showClassificacaoPrioridade: boolean
  showSolicitante: boolean
  showExecutante: boolean
}

type CuidadoCompartilhadoDwDetalhes = HistoricoCuidadoCompartilhadoDwDetalhesQuery['historicoCuidadoCompartilhadoDw']

export const HistoricoCuidadoCompartilhadoDwPanel = ({
  uuidRegistro,
  cpfOuCnsCidadao,
  idadeOrigemAtendimento,
  showClassificacaoPrioridade,
  showSolicitante,
  showExecutante,
}: HistoricoCuidadoCompartilhadoDwProps) => {
  const {
    data: { historicoCuidadoCompartilhadoDw: detalhesDw },
    loading,
  } = useHistoricoCuidadoCompartilhadoDwDetalhesQuery({
    variables: {
      input: {
        uuidRegistro,
        cpfOuCnsCidadao,
        isOrigemPec: false,
      },
    },
  })

  if (loading) return <LoadingIndicator />
  if (!detalhesDw) return <HistoricoNoDetailAvailable />

  const executante = convertToLotacao(
    detalhesDw.dimProfissionalExecutante,
    detalhesDw.dimCboExecutante,
    detalhesDw.dimUnidadeSaudeExecutante,
    detalhesDw.dimEquipeExecutante
  )
  const solicitante = convertToLotacao(
    detalhesDw.dimProfissionalSolicitante,
    detalhesDw.dimCboSolicitante,
    detalhesDw.dimUnidadeSaudeSolicitante,
    detalhesDw.dimEquipeSolicitante
  )

  const informacoesAdministrativas = getInformacoesAdministrativas(
    executante,
    solicitante,
    idadeOrigemAtendimento,
    detalhesDw.isExecutante
  )

  const classificacaoPrioridade = !isCodigoNaoInformado(detalhesDw.dimensaoPrioridadeCuidado?.descricao)
    ? LabelPrioridadeCuidadoCompartilhadoDwRecord[detalhesDw.dimensaoPrioridadeCuidado.descricao]
    : null
  const classificacao = ClassificacaoPrioridadeCuidadoRecord[classificacaoPrioridade]

  const reclassificacaoPrioridade = !isCodigoNaoInformado(detalhesDw.dimReclassificacaoPrioridade?.descricao)
    ? LabelPrioridadeCuidadoCompartilhadoDwRecord[detalhesDw.dimReclassificacaoPrioridade.descricao]
    : null
  const hasReclassificacaoPrioridade = !isUndefinedOrNull(reclassificacaoPrioridade)
  const reclassificacao = ClassificacaoPrioridadeCuidadoRecord[reclassificacaoPrioridade]

  const ciap = convertToCiap(detalhesDw.dimensaoCiap)
  const cid10 = convertToCid10(detalhesDw.dimensaoCid10)
  const conduta = !isCodigoNaoInformado(detalhesDw.dimensaoCondutaCuidado?.nuIdentificador)
    ? IdentificadorCondutaCuidadoCompartilhadoDwRecord[detalhesDw.dimensaoCondutaCuidado.nuIdentificador]
    : null

  return (
    <HistoricoGapPanel
      idadeOrigemAtendimento={idadeOrigemAtendimento}
      informacoesAdministrativas={informacoesAdministrativas}
      dataInicioCompartilhamento={detalhesDw.dataCriacaoCuidado}
    >
      {(showClassificacaoPrioridade || showSolicitante || showExecutante) && (
        <VFlow vSpacing={0.5}>
          {showClassificacaoPrioridade && (
            <HFlow hSpacing={0.5}>
              <Heading level={5}>Classificação de prioridade:</Heading>
              <StatusSquare color={classificacao.cor} description={classificacao.descricao} />
            </HFlow>
          )}

          <VFlow vSpacing={0}>
            {showSolicitante && (
              <HLabel title='Solicitante:'>
                {lotacaoCuidadoCompartilhadoDwFormatter(
                  solicitante.profissional.nome,
                  solicitante.cbo.nome,
                  solicitante.equipe.ine,
                  solicitante.equipe.nome,
                  solicitante.unidadeSaude.nome
                )}
              </HLabel>
            )}
            {showExecutante && (
              <HLabel title='Executante:'>
                {lotacaoCuidadoCompartilhadoDwFormatter(
                  executante.profissional.nome,
                  executante.cbo.nome,
                  executante.equipe.ine,
                  executante.equipe.nome,
                  executante.unidadeSaude.nome
                )}
              </HLabel>
            )}
          </VFlow>
        </VFlow>
      )}
      <VFlow vSpacing={0.5}>
        <VFlow vSpacing={0}>
          <Heading level={5}>Problemas e condições</Heading>
          <CiapCidLabel ciap={ciap} cid10={cid10} isCapitalized />
        </VFlow>
      </VFlow>
      <VFlow vSpacing={0.5}>
        <Heading level={4}>Conduta</Heading>

        {hasReclassificacaoPrioridade && (
          <HFlow hSpacing={0.5}>
            <Heading level={5}>Reclassificação de prioridade para</Heading>
            <StatusSquare color={reclassificacao.cor} description={reclassificacao.descricao} />
          </HFlow>
        )}

        <VFlow vSpacing={0}>
          <HistoricoCuidadoCompartilhadoConduta
            cuidadoCompartilhadoEvolucao={{
              classificacaoPrioridade: classificacaoPrioridade,
              reclassificacaoPrioridade: reclassificacaoPrioridade,
              codigoSequencialEvolucao: detalhesDw.codigoSequencialEvolucao,
              conduta,
              cuidadoCompartilhado: null,
              lotacao: null,
              dataEvolucao: idadeOrigemAtendimento.dataAtendimento,
              id: null,
            }}
          />
        </VFlow>
      </VFlow>
    </HistoricoGapPanel>
  )
}

const getInformacoesAdministrativas = (
  executante: Lotacao,
  solicitante: Lotacao,
  idadeOrigemAtendimento: IdadeOrigemAtendimento,
  isExecutante: boolean
): InformacoesAdministrativas => ({
  localAtendimento: idadeOrigemAtendimento.origemAtendimento,
  stCuidadoCompartilhado: true,
  profissionalPrincipal: lotacaoToInformacoesAdministrativasProfissional(isExecutante ? executante : solicitante),
  profissionalCompartilhado: lotacaoToInformacoesAdministrativasProfissional(isExecutante ? solicitante : executante),
})

const convertToLotacao = (
  dimProfissional: DimensaoProfissional,
  dimCbo: Partial<DimensaoCbo>,
  dimUnidadeSaude: DimensaoUnidadeSaude,
  dimEquipe: DimensaoEquipe
): Lotacao => {
  const nome = getValorInformadoOrNull(dimProfissional?.nome)
  const cbo = getValorInformadoOrNull(dimCbo?.nome)
  const ubs = getValorInformadoOrNull(dimUnidadeSaude?.nome)
  const cnes = getValorInformadoOrNull(dimUnidadeSaude?.cnes)
  const equipe = getValorInformadoOrNull(dimEquipe?.nome)
  const ine = getValorInformadoOrNull(dimEquipe?.ine)

  return {
    profissional: { nome: nome },
    cbo: { nome: cbo },
    unidadeSaude: { nome: ubs, cnes: cnes },
    equipe: { nome: equipe, ine: ine },
  } as Lotacao
}

export const convertToCiap = (dimensaoCiap: DimensaoCiap): Ciap =>
  !isUndefinedOrNull(dimensaoCiap)
    ? ({
        codigo: dimensaoCiap.codigoCiap,
        descricao: dimensaoCiap.nomeCiap,
      } as Ciap)
    : null

export const convertToCid10 = (dimensaoCid10: DimensaoCid10): Cid10 =>
  !isUndefinedOrNull(dimensaoCid10)
    ? ({
        codigo: dimensaoCid10.codigoCid10,
        nome: dimensaoCid10.nomeCid10,
      } as Cid10)
    : null
