import { ReferenceArea, ReferenceAreaRange } from 'bold-ui/lib/components/Chart/model'
import { range } from 'lodash'

import { CategoriaImcColors } from './model-graficoGanhoPeso'

const GANHO_PESO_VALORES_EIXO_X = range(10, 41)

export function getNotExpectedReferenceArea(
  percentilEixoYValues: ReadonlyArray<number>,
  name: string,
  colors: CategoriaImcColors
): ReferenceArea<number> {
  return {
    area: convertToReferenceAreaRange(percentilEixoYValues),
    label: { name, alignment: 'central' },
    color: colors.unexpectedSurface,
    stroke: { color: colors.strokeAndTick },
    tick: { color: colors.strokeAndTick, kind: 'horizontal' },
  }
}

export function getUnderExpectedReferenceArea(
  percentilEixoYValues: ReadonlyArray<number>,
  name: string,
  colors: CategoriaImcColors
): ReferenceArea<number> {
  return {
    area: convertToReferenceAreaRange(percentilEixoYValues),
    label: { name, alignment: 'central' },
    color: colors.unexpectedSurface,
    stroke: { color: colors.strokeAndTick, kind: 'line' },
    tick: { color: colors.strokeAndTick, kind: 'horizontal' },
  }
}

export function getExpectedReferenceArea(
  percentilEixoYValues: ReadonlyArray<number>,
  name: string,
  colors: CategoriaImcColors
): ReferenceArea<number> {
  return {
    area: convertToReferenceAreaRange(percentilEixoYValues),
    label: { name, alignment: 'central' },
    color: colors.expectedSurface,
    stroke: { color: colors.strokeAndTick, kind: 'line' },
    tick: { color: colors.strokeAndTick, kind: 'horizontal' },
  }
}

export function getEmptyReferenceArea(percentilEixoYValues: ReadonlyArray<number>): ReferenceArea<number> {
  return {
    area: convertToReferenceAreaRange(percentilEixoYValues),
    label: { name: 'Empty' },
    color: 'none',
    stroke: { color: 'none' },
    tick: { color: 'none' },
  }
}

function convertToReferenceAreaRange(percentilEixoYValues: ReadonlyArray<number>): ReferenceAreaRange<number>[] {
  return percentilEixoYValues.map((y, index) => ({
    x: GANHO_PESO_VALORES_EIXO_X[index],
    upperLimit: y,
  }))
}
