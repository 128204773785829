import { Cidadao } from 'graphql/types.generated'

import { ParticipanteModel } from './model-atividadeColetiva'

type CidadaoNome = Pick<Cidadao, 'nome' | 'nomeSocial'>

export const getNomeCidadao = (model?: Partial<CidadaoNome>) => model?.nomeSocial ?? model?.nome

export const sortByNomeCidadao = (a: { cidadao: CidadaoNome }, b: { cidadao: CidadaoNome }) =>
  getNomeCidadao(a.cidadao).localeCompare(getNomeCidadao(b.cidadao))

const getNomeParticipante = (model: ParticipanteModel) =>
  getNomeCidadao(model.cidadao) ?? model.cidadaoParticipante.nome ?? 'ZZZ'

const getDataNascimentoParticipante = (model: ParticipanteModel) =>
  new Date(model.cidadao?.dataNascimento ?? model.cidadaoParticipante?.dataNascimento).getTime()

const compareDataNascimento = (a: ParticipanteModel, b: ParticipanteModel) => {
  const dataA = getDataNascimentoParticipante(a)
  const dataB = getDataNascimentoParticipante(b)
  return dataA < dataB ? 1 : dataA > dataB ? -1 : 0
}

export const sortByNomeAndIdadeParticipante = (a: ParticipanteModel, b: ParticipanteModel) =>
  10 * getNomeParticipante(a).localeCompare(getNomeParticipante(b)) + compareDataNascimento(a, b)

export const hasDataNascimentoAfterDataAtividade = (dataAtividade: LocalDate, dataNascimento: LocalDate) =>
  (dataNascimento && dataAtividade && dataNascimento > dataAtividade) ?? false
