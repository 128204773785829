import { TipoAtividadeEnum } from 'graphql/types.generated'
import { createValidator, empty, ErrorObject, oneDeepTruthyValueRequired, required } from 'util/validation'
import { isEmpty } from 'util/validation/Util'

import { LocalAtividadeFieldModel } from '../componentes/LocalAtividadeField'
import { ParticipanteEditableTableModel } from '../componentes/participantes/ParticipanteEditableTable'
import { AtividadeColetivaFormModel } from '../model-atividadeColetiva'
import { hasDataNascimentoAfterDataAtividade } from '../utils-atividadeColetiva'
import {
  CIDADAO_HAS_DATA_NASCIMENTO_POSTERIOR_ATIVIDADE_MSG,
  getAtividadeColetivaCommonValidationRules,
} from '../validator-atividadeColetivaCommonForm'
import {
  isAtividadePseRequired,
  isCidadaosParticipantesRequired,
  isNotInPraticasSaudeAtendimentoEmGrupo,
  isPraticaSaudeDisabled,
  isPraticaSaudeRequired,
  isProcedimentoDisabled,
  isPse,
  isTemasSaudeRequired,
} from './utils-atividadeColetivaSaude'

export const atividadeColetivaSaudeFormValidator = (now: Date) =>
  createValidator<AtividadeColetivaFormModel>(
    {
      ...getAtividadeColetivaCommonValidationRules(now),
      temasReuniao: empty,
      publicoAlvo: required,
    },
    (values: AtividadeColetivaFormModel, errors: ErrorObject<AtividadeColetivaFormModel>) => {
      if (isAtividadePseRequired(values)) {
        errors.atividadePse = oneDeepTruthyValueRequired(values.atividadePse)
      }

      if (isPse(values)) {
        errors.localAtividade = localAtividadePseValidator(values.localAtividade) ?? errors.localAtividade
      }

      if (isPraticaSaudeDisabled(values)) {
        errors.praticasSaude = empty(values?.praticasSaude)
      } else if (isPraticaSaudeRequired(values)) {
        errors.praticasSaude = required(values?.praticasSaude)
      } else if (
        values?.tipoAtividade === TipoAtividadeEnum.ATENDIMENTO_EM_GRUPO &&
        values?.praticasSaude?.some(isNotInPraticasSaudeAtendimentoEmGrupo)
      ) {
        errors.praticasSaude = `Existem valores selecionados que não podem ser informados no Atendimento em grupo.`
      }

      if (isProcedimentoDisabled(values)) {
        errors.procedimento = empty(values?.procedimento)
      }

      if (isTemasSaudeRequired(values)) {
        errors.temasSaude = required(values?.temasSaude)
      }

      errors.numeroParticipantes = errors.numeroParticipantes ?? validateNumeroParticipantes(values)

      return errors
    }
  )

const localAtividadePseValidator = createValidator<LocalAtividadeFieldModel>({
  inep: required,
  unidadeSaudeLocalAtividade: empty,
  outraLocalidade: empty,
})

export const validateNumeroParticipantes = (model: {
  numeroParticipantes: number
  participantes?: ParticipanteEditableTableModel[]
}) =>
  !isEmpty(model?.participantes) &&
  model.numeroParticipantes < model.participantes.length &&
  'Nº de participantes deve ser maior ou igual ao número de participantes da lista.'

export const validateExisteParticipantes = (model: {
  tipoAtividade: TipoAtividadeEnum
  participantes?: ParticipanteEditableTableModel[]
}) =>
  isCidadaosParticipantesRequired(model.tipoAtividade) &&
  isEmpty(model.participantes) &&
  'O tipo de atividade informado requer a adição de participantes.'

export const validateDataNascimentoParticipantes = (model: {
  dataAtividade: LocalDate
  participantes?: ParticipanteEditableTableModel[]
}) =>
  model.participantes?.some((participante) =>
    hasDataNascimentoAfterDataAtividade(
      model.dataAtividade,
      (participante.cidadao ?? participante.cidadaoParticipante).dataNascimento
    )
  ) && CIDADAO_HAS_DATA_NASCIMENTO_POSTERIOR_ATIVIDADE_MSG
