import { Omit } from 'bold-ui'
import { SelectField, SelectFieldProps } from 'components/form'
import { GrupoAlvoBuscaAtivaVacinacaoEnum } from 'graphql/types.generated'
import React from 'react'

export interface GrupoAlvoVacinacaoSelectModel {
  id: ID
  enum: GrupoAlvoBuscaAtivaVacinacaoEnum
  descricao: string
}

export interface GrupoAlvoVacinacaoSelectFieldProps
  extends Omit<SelectFieldProps<GrupoAlvoVacinacaoSelectModel>, 'items' | 'itemToString'> {
  isTipoBuscaAtivaCovid: boolean
}

export function GrupoAlvoVacinacaoSelectField(props: GrupoAlvoVacinacaoSelectFieldProps) {
  const { isTipoBuscaAtivaCovid, ...rest } = props
  const itemToString = (item: GrupoAlvoVacinacaoSelectModel) => item?.descricao

  return (
    <SelectField<GrupoAlvoVacinacaoSelectModel>
      items={items.sort((a, b) => a.id - b.id)}
      itemToString={itemToString}
      {...rest}
    />
  )
}

export const items = [
  { id: 1, enum: GrupoAlvoBuscaAtivaVacinacaoEnum.CRIANCAS, descricao: 'Crianças (0 a 9 anos)' },
  { id: 2, enum: GrupoAlvoBuscaAtivaVacinacaoEnum.ADOLESCENTES, descricao: 'Adolescentes (10 a 19 anos)' },
  { id: 3, enum: GrupoAlvoBuscaAtivaVacinacaoEnum.ADULTOS, descricao: 'Adultos (20 a 59 anos)' },
  { id: 4, enum: GrupoAlvoBuscaAtivaVacinacaoEnum.IDOSOS, descricao: 'Pessoas idosas (60 anos ou mais)' },
  { id: 5, enum: GrupoAlvoBuscaAtivaVacinacaoEnum.GESTANTES_E_PUERPERAS, descricao: 'Gestantes/Puérperas' },
]
