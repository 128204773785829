import { VFlow } from 'bold-ui'
import {
  AccordionDataTableRowDefault,
  AccordionDataTableRowDefaultProps,
} from 'components/accordion/accordion-data-table/AccordionDataTableRowDefault'
import React from 'react'

import { AnexoArquivoRowModel } from '../../model-anexoArquivos'

export function AnexoArquivoTableRow(props: AccordionDataTableRowDefaultProps<AnexoArquivoRowModel>) {
  // TODO (Legacy) #23102

  return (
    <VFlow vSpacing={0}>
      <AccordionDataTableRowDefault {...props} />
    </VFlow>
  )
}
