/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { ExternalStyles, FormControl, FormControlProps, HFlow, useStyles, VFlow } from 'bold-ui'
import { CSSProperties, useMemo } from 'react'

import { ErrorField, RadioField, RadioFieldProps } from '../final-form'
import { FieldStatusContainer } from '../final-form/components/FieldStatus/FieldStatusContainer'

export type RadioOption<T> = { value: T; label: string }

export interface RadioGroupFieldProps<T extends string | number | string[]>
  extends Omit<FormControlProps, 'clearable'> {
  name: RadioFieldProps['name']
  options: RadioOption<T>[]
  lines?: number
  vSpacing?: number
  hSpacing?: number
  clearable?: boolean
  onChange?: RadioFieldProps['onChange']
  style?: {
    hFlow?: ExternalStyles
    vFlow?: ExternalStyles
    radioField?: ExternalStyles
  }
  disabled?: boolean
  showErrorField?: boolean
  showModifiedStatus?: boolean
  clearFieldLabel?: string
}

export function RadioGroupField<T extends string | number | string[]>(props: RadioGroupFieldProps<T>) {
  const {
    name,
    options,
    lines = 1,
    vSpacing = 0.8,
    hSpacing,
    clearable,
    onChange,
    style,
    disabled,
    showErrorField = true,
    showModifiedStatus = false,
    clearFieldLabel,
    ...rest
  } = props

  const cols = useRadioGroupColumns(options, lines)

  const { classes } = useStyles(createStyles)

  return (
    <FieldStatusContainer showModifiedStatus={showModifiedStatus} name={name}>
      <FormControl {...rest}>
        <VFlow vSpacing={0.5}>
          <HFlow hSpacing={hSpacing} style={css(classes.row, style?.hFlow)}>
            {cols.map((col, index) => (
              <VFlow vSpacing={vSpacing} key={index} style={style?.vFlow}>
                {col.map(({ value, label }) => (
                  <RadioField
                    key={value.toString()}
                    name={name}
                    value={value}
                    label={label}
                    clearable={clearable}
                    showClearConfirm={showModifiedStatus}
                    clearFieldLabel={clearFieldLabel}
                    onChange={onChange}
                    style={style?.radioField}
                    disabled={disabled}
                  />
                ))}
              </VFlow>
            ))}
          </HFlow>
          {showErrorField && <ErrorField name={name} />}
        </VFlow>
      </FormControl>
    </FieldStatusContainer>
  )
}

export function useRadioGroupColumns<T, U = RadioOption<T>>(options: U[], lines?: number) {
  return useMemo(() => {
    const nCols = Math.ceil(options.length / lines)

    return options.reduce((acc, curr, i) => {
      const colIdx = i % nCols
      if (!acc[colIdx]) acc[colIdx] = []
      acc[colIdx][Math.floor(i / nCols)] = curr

      return acc
    }, [])
  }, [lines, options])
}

const createStyles = () => ({
  row: {
    marginLeft: `-0.25rem`,
    paddingTop: `0.3rem`,
  } as CSSProperties,
})
