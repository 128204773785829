import { CboSelectModel, MunicipioSelectModel, SimNaoEnum } from 'components/form'
import { EscolaridadeSelectModel } from 'components/form/field/select/EscolaridadeSelectField'
import { EstadoCivilSelectModel } from 'components/form/field/select/EstadoCivilSelectField'
import { EtniaSelectFieldModel } from 'components/form/field/select/EtniaSelectField'
import { PaisSelectFieldModel } from 'components/form/field/select/PaisSelectField'
import { RacaCorSelectModel } from 'components/form/field/select/RacaCorSelectField'
import { TipoSanguineoSelectModel } from 'components/form/field/select/TipoSanguineoSelectField'
import {
  CidadaoFciAtualizacaoQuery,
  Criancas0a9AnosComQuemFicaEnum,
  DeficienciaEnum,
  EscolaridadeEnum,
  EstadoCivilEnum,
  NacionalidadeEnum,
  PovoComunidadeTradicionalEnum,
  SexoEnum,
  SituacaoMercadoTrabalhoEnum,
} from 'graphql/types.generated'

export interface DadosPessoaisFormModel {
  cpf?: string
  cns?: string
  nisPisPasep?: string
  nome: string
  nomeSocial?: string
  dataNascimento: string
  sexo: SexoEnum
  estadoCivil?: EstadoCivilSelectModel
  tipoSanguineo?: TipoSanguineoSelectModel
  racaCor: RacaCorSelectModel
  etnia?: EtniaSelectFieldModel
  nomeMae?: string
  nomePai?: string
  desconheceNomeMae: boolean
  desconheceNomePai: boolean
  nacionalidadeCidadao: NacionalidadeFormModel
  faleceu?: boolean
  dataObito?: LocalDate
  numeroDeclaracaoObito?: string
}

export interface InformacoesSocioEconomicasFormModel {
  openedAccordion?: (string | number)[]
  alimentosAcabaramAntesTerDinheiroComprarMais: SimNaoEnum
  comeuAlgunsAlimentosDinheiroAcabou: SimNaoEnum
}

export interface InformacoesSociodemograficasFormModel {
  openedAccordion?: (string | number)[]
  escolaridade?: EscolaridadeSelectModel
  frequentaEscolaOuCreche?: SimNaoEnum
  criancas0a9anosComQuemFica?: Criancas0a9AnosComQuemFicaEnum[]
  situacaoMercadoTrabalho?: SituacaoMercadoTrabalhoEnum
  ocupacao?: CboSelectModel
  possuiPlanoSaudePrivado?: SimNaoEnum
  frequentaCuidadorTradicional?: SimNaoEnum
  participaAlgumGrupoComunitario?: SimNaoEnum
  isMembroComunidadeTradicional?: SimNaoEnum
  povoComunidadeTradicional?: PovoComunidadeTradicionalEnum
  possuiDeficiencia?: SimNaoEnum
  deficiencias?: DeficienciaEnum[]
}

export interface CidadaoFciFormModel {
  id?: ID
  dadosPessoais: DadosPessoaisFormModel
  informacoesSocioEconomicas: InformacoesSocioEconomicasFormModel
  informacoesSociodemograficas: InformacoesSociodemograficasFormModel
}

export interface NacionalidadeFormModel {
  nacionalidade: NacionalidadeEnum
  municipioNascimento?: MunicipioSelectModel
  portariaNaturalizacao?: string
  dataNaturalizacao?: LocalDate
  paisNascimento?: PaisSelectFieldModel
  dataEntradaPais?: LocalDate
}

export type CidadaoFci = CidadaoFciAtualizacaoQuery['cidadao']

export const estadoCivilRecord: Record<ID, EstadoCivilEnum> = {
  '1': EstadoCivilEnum.SOLTEIRO,
  '2': EstadoCivilEnum.CASADO,
  '3': EstadoCivilEnum.VIUVO,
  '4': EstadoCivilEnum.SEPARADO_JUDICIALMENTE,
  '5': EstadoCivilEnum.UNIAO_CONSENSUAL,
}

export const criancas0a9anosComQuemFicaRecord: Record<Criancas0a9AnosComQuemFicaEnum, string> = {
  ADULTO_RESPONSAVEL: 'Adulto responsável',
  OUTRAS_CRIANCAS: 'Outra(s) criança(s)',
  ADOLESCENTE: 'Adolescente',
  SOZINHA: 'Sozinha',
  CRECHE: 'Creche',
  OUTRO: 'Outro',
}

export const deficienciaRecord: Record<DeficienciaEnum, string> = {
  AUDITIVA: 'Auditiva',
  INTELECTUAL_COGNITIVA: 'Intelectual / Cognitiva',
  VISUAL: 'Visual',
  FISICA: 'Fisica',
  OUTRA: 'Outra',
}

export const situacaoMercadoTrabalhoRecord: Record<SituacaoMercadoTrabalhoEnum, string> = {
  EMPREGADOR: 'Empregador',
  ASSALARIADO_COM_CARTEIRA_TRABALHO: 'Assalariado com carteira de trabalho',
  ASSALARIADO_SEM_CARTEIRA_TRABALHO: 'Assalariado sem carteira de trabalho',
  AUTONOMO_COM_PREVIDENCIA_SOCIAL: 'Autônomo com previdência social',
  AUTONOMO_SEM_PREVIDENCIA_SOCIAL: 'Autônomo sem previdência social',
  APOSENTADO_PENSIONISTA: 'Aposentado / Pensionista',
  DESEMPREGADO: 'Desempregado',
  NAO_TRABALHA: 'Não trabalha',
  SERVIDOR_PUBLICO_MILITAR: 'Servidor público / Militar',
  OUTRO: 'Outro',
}

export const povoComunidadeTradicionalRecord: Record<PovoComunidadeTradicionalEnum, string> = {
  AGROEXTRATIVISTAS: 'Agroextrativistas - Povos ou Comunidades Tradicionais',
  CAATINGUEIROS: 'Caatingueiros - Povos ou Comunidades Tradicionais',
  CAICARAS: 'Caiçaras - Povos ou Comunidades Tradicionais',
  CERRADO: 'Cerrado - Povos ou Comunidades Tradicionais',
  CIGANOS: 'Ciganos - Povos ou Comunidades Tradicionais',
  COMUNIDADES_FUNDO_FECHO_PASTO: 'Comunidades de fundo e fecho de pasto - Povos ou Comunidades Tradicionais',
  EXTRATIVISTAS: 'Extrativistas - Povos ou Comunidades Tradicionais',
  FAXINALENSES: 'Faxinalenses - Povos ou Comunidades Tradicionais',
  GERAIZEIROS: 'Geraizeiros - Povos ou Comunidades Tradicionais',
  MARISQUEIROS: 'Marisqueiros - Povos ou Comunidades Tradicionais',
  PANTANEIROS: 'Pantaneiros - Povos ou Comunidades Tradicionais',
  PESCADORES_ARTESANAIS: 'Pescadores artesanais - Povos ou Comunidades Tradicionais',
  POMERANOS: 'Pomeranos - Povos ou Comunidades Tradicionais',
  POVOS_INDIGENAS: 'Povos indígenas - Povos ou Comunidades Tradicionais',
  POVOS_QUILOMBOLAS: 'Povos quilombolas - Povos ou Comunidades Tradicionais',
  QUEBRADEIRAS_COCO_BABACU: 'Quebradeiras de coco babaçu - Povos ou Comunidades Tradicionais',
  RETIREIROS: 'Retireiros - Povos ou Comunidades Tradicionais',
  RIBEIRINHOS: 'Ribeirinhos - Povos ou Comunidades Tradicionais',
  SERINGUEIROS: 'Seringueiros - Povos ou Comunidades Tradicionais',
  POVOS_DE_TERREIRO_MATRIZ_AFRICANA: 'Povos de terreiro / Matriz africana - Povos ou Comunidades Tradicionais',
  VAZANTEIROS: 'Vazanteiros - Povos ou Comunidades Tradicionais',
  OUTROS: 'Outros - Povos ou Comunidades Tradicionais',
  ACAMPADA: 'Acampada - População Campo, Floresta e Águas',
  ANDIROBEIRAS: 'Andirobeiras - População Campo, Floresta e Águas',
  ASSENTADA: 'Assentada - População Campo, Floresta e Águas',
  CAMPONESES: 'Camponeses - População Campo, Floresta e Águas',
  CASTANHEIRAS: 'Castanheiras - População Campo, Floresta e Águas',
  CATADORES_DE_MANGABA: 'Catadores de mangaba - População Campo, Floresta e Águas',
  ISQUEIROS: 'Isqueiros - População Campo, Floresta e Águas',
  JANGADEIROS: 'Jangadeiros - População Campo, Floresta e Águas',
  MORROQUIANOS: 'Morroquianos - População Campo, Floresta e Águas',
  POPULACOES_ATINGIDAS_POR_BARRAGENS: 'Populações atingidas por barragens - População Campo, Floresta e Águas',
  TRABALHADORES_RURAIS_ASSALARIADOS: 'Trabalhadores rurais assalariados - População Campo, Floresta e Águas',
  TRABALHADORES_RURAIS_TEMPORARIOS: 'Trabalhadores rurais temporários - População Campo, Floresta e Águas',
  VARJEIROS: 'Varjeiros - População Campo, Floresta e Águas',
}

export const escolaridadeRecord: Record<ID, EscolaridadeEnum> = {
  '1': EscolaridadeEnum.CRECHE,
  '2': EscolaridadeEnum.PRE_ESCOLA_EXCETO_CA,
  '3': EscolaridadeEnum.CLASSE_ALFABETIZADA_CA,
  '4': EscolaridadeEnum.ENSINO_FUNDAMENTAL_1_A_4_SERIES,
  '5': EscolaridadeEnum.ENSINO_FUNDAMENTAL_5_A_8_SERIES,
  '6': EscolaridadeEnum.ENSINO_FUNDAMENTAL_COMPLETO,
  '7': EscolaridadeEnum.ENSINO_FUNDAMENTAL_ESPECIAL,
  '8': EscolaridadeEnum.ENSINO_FUNDAMENTAL_EJA_SERIES_INICIAIS_SUPLETIVO_1_A_4,
  '9': EscolaridadeEnum.ENSINO_FUNDAMENTAL_EJA_SERIES_INICIAIS_SUPLETIVO_5_A_8,
  '10': EscolaridadeEnum.ENSINO_MEDIO_MEDIO_2_CICLO,
  '11': EscolaridadeEnum.ENSINO_MEDIO_ESPECIAL,
  '12': EscolaridadeEnum.ENSINO_MEDIO_EJA_SUPLETIVO,
  '13': EscolaridadeEnum.SUPERIOR_APERFEICOAMENTO_ESPECIALIZACAO_MESTRADO_DOUTORADO,
  '14': EscolaridadeEnum.ALFABETIZACAO_PARA_ADULTOS_MOBRAL_ETC,
  '15': EscolaridadeEnum.NENHUM,
}
