import api from 'api'
import { defaultHandleError, downloadFile } from 'util/downloadFile'

import { RelatorioRndsInput } from './model-rndsRelatorio'

export const downloadRelatorioRndsCsv = (input: RelatorioRndsInput) => {
  return api.rnds
    .gerarRelatorioRnds(input)
    .then((responseBody) => {
      downloadFile(new Blob([responseBody.data]), `relatorio-rnds.csv`)
    })
    .catch(defaultHandleError)
}
