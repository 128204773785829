import { Breadcrumb } from 'components/breadcrumb'
import { PrivateRoute } from 'components/route'
import { useFlags } from 'config/useFlagsContext'
import React from 'react'
import { useRouteMatch } from 'react-router'
import { Switch } from 'react-router-dom'
import Permissions from 'types/Permissions'
import { grupoCboAtendimento } from 'view/cidadao/acessos'

import { AtendimentoView } from '../atendimentos/detail/AtendimentoView'
import { ListaRegistroTardioView } from './ListaRegistroTardioView'

export const REGISTRO_TARDIO_PATH = '/registroTardio'

export function RegistroTardioRootView() {
  const { RETIFICACAO_ATENDIMENTO_PROCEDIMENTOS_ENABLED } = useFlags()
  const match = useRouteMatch()

  return (
    <>
      <Breadcrumb title='Registro tardio de atendimento' />
      <Switch>
        {RETIFICACAO_ATENDIMENTO_PROCEDIMENTOS_ENABLED && (
          <PrivateRoute
            path={`${match.url}/:atendimentoId(\\d+)/retificar`}
            render={() => <AtendimentoView isRetificacao />}
            permission={Permissions.visualizarListaDeAtendimento.registrarAtendimento}
            cbos={grupoCboAtendimento}
          />
        )}
        <PrivateRoute
          path={`${match.url}/:atendimentoId(\\d+)`}
          component={AtendimentoView}
          permission={Permissions.visualizarListaDeAtendimento.registrarAtendimento}
          cbos={grupoCboAtendimento}
        />
        <PrivateRoute
          component={ListaRegistroTardioView}
          permission={Permissions.visualizarListaDeAtendimento}
          acceptEstagio={false}
        />
      </Switch>
    </>
  )
}

export const urlFromRegistroTardio = (url: string): boolean => url.includes(REGISTRO_TARDIO_PATH)
