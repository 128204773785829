/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Cell, Grid, PagedTable, Tabs } from 'bold-ui'
import { PageLoading } from 'components/loading'
import { TabLink } from 'components/route'
import { TableBox } from 'components/table/TableBox'
import { usePagination } from 'components/table/usePagination'
import { useAcompanhamentoPreNatalQuery, useResultadosExamePreNatalQuery } from 'graphql/hooks.generated'
import { ResultadoExame } from 'graphql/types.generated'
import { useMemo } from 'react'
import { useRouteMatch } from 'react-router'
import {
  HistoricoPreNatalModel,
  MedicoesPreNatal,
  MedicoesPreNatalAtendimentoModel,
} from 'view/atendimentos/detail/components/modals/types/model-historicoPreNatal'
import { TipoPreNatal } from 'view/atendimentos/detail/soap/pre-natal/model-preNatal'

import { convertDadosPreNatal } from '../../../../soap/aside/pre-natal/util/util-acompanhamentoPreNatal'
import { HistoricoMedicoesPreNatalTabRoutes } from './HistoricoMedicoesPreNatalTabRoutes'
import { MedicaoAntropometricaPreNatal } from './model-medicoesPreNatal'
import {
  renderDataMedicao,
  renderEdema,
  renderIdadeGestacional,
  renderLabelIG,
  renderMedicaoPreNatal,
  renderMovimentacaoFetal,
  renderNumeroConsulta,
} from './render-medicoesPreNatal'
import {
  getMedicaoForImcReferencia,
  getMedicoesPeso10SemanasAposInicioGestacao,
  getPesoAtendimentoAtual,
  renderGanhoPeso,
} from './util-medicoesPreNatal'

interface HistoricoMedicoesPreNatalViewProps extends MedicoesPreNatalAtendimentoModel {
  prontuarioId: ID
  medicoesAntropometricasPreNatal: MedicaoAntropometricaPreNatal[]
  tipoPreNatal?: TipoPreNatal
}

const getColumnsTableItens = (dataConverted: HistoricoPreNatalModel, pesoReferencia: number) => [
  {
    header: 'Consulta',
    name: 'numeroConsulta',
    render: (preNatal: MedicoesPreNatal) => renderNumeroConsulta(preNatal.numeroConsulta),
    style,
  },
  {
    header: 'Data da medição',
    name: 'dataMedicao',
    render: (preNatal: MedicoesPreNatal) => renderDataMedicao(preNatal.dataDaMedicao),
    style,
  },
  {
    header: renderLabelIG(dataConverted),
    name: 'idadeGestacional',
    render: (preNatal: MedicoesPreNatal) =>
      renderIdadeGestacional(dataConverted?.dataInicioGestacao, preNatal.dataDaMedicao),
    style,
  },
  {
    header: 'Edema',
    name: 'edema',
    render: (preNatal: MedicoesPreNatal) => renderEdema(preNatal?.edema),
    style,
  },
  {
    header: 'Altura uterina (cm)',
    name: 'alturaUterina',
    render: (preNatal: MedicoesPreNatal) => renderMedicaoPreNatal(preNatal.alturaUterina),
    style,
  },
  {
    header: 'Ganho de peso (kg)',
    name: 'ganhoPeso',
    render: (preNatal: MedicoesPreNatal) => renderGanhoPeso(pesoReferencia, preNatal.peso),
    style,
  },
  {
    header: 'BCF (bpm)',
    name: 'batimentoCardiacoFetal',
    render: (preNatal: MedicoesPreNatal) => renderMedicaoPreNatal(preNatal.batimentoCardiacoFetal),
    style,
  },
  {
    header: 'Movimentação fetal',
    name: 'movimentacaoFetal',
    render: (preNatal: MedicoesPreNatal) =>
      renderMovimentacaoFetal(preNatal?.movimentacaoFetal, preNatal.isRegistradoAgora),
    style,
  },
]

export function HistoricoMedicoesPreNatalView(props: HistoricoMedicoesPreNatalViewProps) {
  const {
    prontuarioId,
    preNatalAtendimentoAtual,
    dataAtendimento,
    resultadosExamesAtendimentoAtual,
    dumAtendimentoAtual,
    medicoesAntropometricasPreNatal,
    tipoPreNatal,
  } = props

  const { data, loading } = useAcompanhamentoPreNatalQuery({
    variables: { input: prontuarioId },
  })

  const { data: resultadosExame } = useResultadosExamePreNatalQuery({
    variables: {
      input: {
        prontuarioId,
      },
    },
  })

  const resultadosExamesAnteriores = resultadosExame?.resultadosExame?.content as ResultadoExame[]

  const pesoAtendimentoAtual = getPesoAtendimentoAtual(medicoesAntropometricasPreNatal)

  const dataConverted = useMemo(
    () =>
      convertDadosPreNatal({
        data,
        preNatalAtendimentoAtual,
        resultadosExamesAtendimentoAtual,
        dumAtendimentoAtual,
        dataAtendimento,
        pesoAtendimentoAtual,
        resultadosExamesAnteriores,
        tipoPreNatal,
      }),
    [
      data,
      preNatalAtendimentoAtual,
      resultadosExamesAtendimentoAtual,
      dumAtendimentoAtual,
      dataAtendimento,
      pesoAtendimentoAtual,
      resultadosExamesAnteriores,
      tipoPreNatal,
    ]
  )

  const match = useRouteMatch()

  const { paginatedItems, tableProps: propsPagination } = usePagination({
    items: dataConverted?.medicoesPreNatal,
  })
  const { pageSize, ...propsPaginationToPagedTable } = propsPagination

  const tableProps = {
    ...propsPaginationToPagedTable,
    size: pageSize,
    rows: paginatedItems,
  }

  const dataInicioGestacao = dataConverted.dataInicioGestacao

  const medicaoForImcReferencia = getMedicaoForImcReferencia(dataInicioGestacao, medicoesAntropometricasPreNatal)

  if (loading) return <PageLoading message='Carregando dados' />

  return (
    <Grid>
      <Cell size={12}>
        <TableBox>
          <PagedTable<MedicoesPreNatal>
            {...tableProps}
            columns={getColumnsTableItens(dataConverted, medicaoForImcReferencia?.peso)}
          />
        </TableBox>
      </Cell>
      <Cell size={12}>
        <Tabs>
          <TabLink to={`${match.url}/ganho-de-peso`}>Ganho de peso</TabLink>
          <TabLink to={`${match.url}/altura-uterina`}>Altura uterina</TabLink>
        </Tabs>
      </Cell>
      <Cell size={12}>
        <HistoricoMedicoesPreNatalTabRoutes
          match={match}
          dataConvertedPreNatal={dataConverted}
          medicaoForImcReferencia={medicaoForImcReferencia}
          medicoesPeso10SemanasAposInicioGestacao={getMedicoesPeso10SemanasAposInicioGestacao(
            dataInicioGestacao,
            medicoesAntropometricasPreNatal
          )}
        />
      </Cell>
    </Grid>
  )
}

const style = css`
  width: '5.5rem';
`
