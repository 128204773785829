import { HFlow, Text } from 'bold-ui'
import React from 'react'
import { calcularIMC } from 'util/atendimento'
import { toDate } from 'util/date/formatDate'
import { formatNumber } from 'util/number'
import { AlertImc } from 'view/atendimentos/detail/soap/aside/medicoes/components/AlertImc'
import { ParticipanteModel } from 'view/atividade-coletiva/model-atividadeColetiva'

interface ParticipanteImcProps {
  participante: ParticipanteModel
  dataAtividade: LocalDate | number
}

export const ParticipanteImc = (props: ParticipanteImcProps) => {
  const { participante, dataAtividade } = props

  if (participante.peso && participante.altura) {
    const imc = calcularIMC(participante.peso, participante.altura)
    const cidadao = participante.cidadao ?? participante.cidadaoParticipante

    return (
      <HFlow alignItems='center'>
        <Text>{formatNumber(imc)}</Text>
        <AlertImc
          dataMedicao={toDate(dataAtividade).getTime()}
          imc={formatNumber(imc)}
          dataNascimento={cidadao.dataNascimento}
          sexo={cidadao.sexo}
        />
      </HFlow>
    )
  } else {
    return <>-</>
  }
}
