import {
  CategoriaImcReferenciaEnum,
  IMC_MAX_BAIXOPESO_MIN_EUTROFIA,
  IMC_MAX_EUTROFIA_MIN_SOBREPESO,
  IMC_MAX_SOBREPESO_MIN_OBESIDADE,
} from './model-graficoGanhoPeso'

export function getCategoriaImcReferencia(imc: number): CategoriaImcReferenciaEnum {
  if (imc >= IMC_MAX_SOBREPESO_MIN_OBESIDADE) return CategoriaImcReferenciaEnum.OBESIDADE
  else if (imc >= IMC_MAX_EUTROFIA_MIN_SOBREPESO) return CategoriaImcReferenciaEnum.SOBREPESO
  else if (imc >= IMC_MAX_BAIXOPESO_MIN_EUTROFIA) return CategoriaImcReferenciaEnum.EUTROFIA
  else return CategoriaImcReferenciaEnum.BAIXO_PESO
}
