import { TipoPreNatal } from '../pre-natal/model-preNatal'

export interface AsideViewAtendimentoStatuses {
  isAtendimentoProcedimentos: boolean
  isAtendimentoObservacao: boolean
  isObservacaoAndAuxiliar: boolean
  tipoPreNatal: TipoPreNatal
  isRetificacao: boolean
}

export const ASIDE_ATENDIMENTO_STATUSES_ALL_FALSE: AsideViewAtendimentoStatuses = {
  isAtendimentoProcedimentos: false,
  isAtendimentoObservacao: false,
  isObservacaoAndAuxiliar: false,
  tipoPreNatal: undefined,
  isRetificacao: false,
}
