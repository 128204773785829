import api from 'api'
import { defaultHandleError } from 'util/downloadFile'
import { previewFile } from 'util/previewFile'

import { ImpressaoGuiaEncaminhamentoDto } from './model-impressaoGuiaEncaminhamento'

export const downloadGuiaEncaminhamentoCuidado = async (value: ImpressaoGuiaEncaminhamentoDto) => {
  return await api.guiaEncaminhamentoCuidado
    .imprimirGuiaEncaminhamentoCuidado(value)
    .then((response) => {
      previewFile(new Blob([response.data], { type: 'application/pdf' }), 'Imprimir guia encaminhamento cuidado')
      return response
    })
    .catch(defaultHandleError)
}
