import { Breadcrumb } from 'components/breadcrumb'
import { ATIVIDADE_COLETIVA_REUNIOES_PATH } from 'components/layout/SideMenu/SideMenuItems'
import { PecSwitch, PrivateRoute } from 'components/route'
import React, { Fragment } from 'react'
import Permissions from 'types/Permissions'

import { AtividadeColetivaEditarReuniaoView } from './AtividadeColetivaEditarReuniaoView'
import { AtividadeColetivaRegistrarReuniaoView } from './AtividadeColetivaRegistrarReuniaoView'
import { AtividadeColetivaReuniaoListaView } from './AtividadeColetivaReuniaoListaView'
import { AtividadeColetivaVisualizarReuniaoView } from './AtividadeColetivaVisualizarReuniaoView'

export function AtividadeColetivaReuniaoRootView() {
  return (
    <Fragment>
      <Breadcrumb title='Atividade em reunião' />
      <PecSwitch>
        <PrivateRoute
          path={`${ATIVIDADE_COLETIVA_REUNIOES_PATH}/registrar`}
          component={AtividadeColetivaRegistrarReuniaoView}
          permission={Permissions.cds.fichaDeAtividadeColetiva.cadastrarEditarEExcluir}
        />
        <PrivateRoute
          path={`${ATIVIDADE_COLETIVA_REUNIOES_PATH}/editar/:atividadeColetivaId`}
          component={AtividadeColetivaEditarReuniaoView}
          permission={Permissions.cds.fichaDeAtividadeColetiva.cadastrarEditarEExcluir}
        />
        <PrivateRoute
          path={`${ATIVIDADE_COLETIVA_REUNIOES_PATH}/visualizar/:atividadeColetivaId`}
          component={AtividadeColetivaVisualizarReuniaoView}
          permission={Permissions.cds.fichaDeAtividadeColetiva}
        />
        <PrivateRoute
          exact
          path={ATIVIDADE_COLETIVA_REUNIOES_PATH}
          component={AtividadeColetivaReuniaoListaView}
          permission={Permissions.cds.fichaDeAtividadeColetiva}
        />
      </PecSwitch>
    </Fragment>
  )
}
